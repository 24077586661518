import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import "./App.scss";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import { Store, persistor } from "./redux/store";

function App() {
  useEffect(() => {
    document.title = "Blackleaf Connect Master";
  });

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `"Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif`,
              borderRadius: "2px",
            },
          }}
        >
          <div className="App">
            <AppRouter />
          </div>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
