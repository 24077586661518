export const DISTRIBUTOR_VENDOR_ID_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Distributor Vendor Id",
  },
  {
    min: 1,
    message: "Distributor Vendor Id must contain at least 1 characters",
  },
  {
    max: 20,
    message: "Distributor Vendor Id must be less than 20 characters",
  },
];

export const DISTRIBUTOR_VENDOR_NAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Distributor Vendor Name",
  },
  {
    min: 1,
    message: "Distributor Vendor Name must contain at least 1 characters",
  },
  {
    max: 50,
    message: "Distributor Vendor Name must be less than 50 characters",
  },
];

export const DISTRIBUTOR_MANUFACTURER_VALIDATIONS = [
  {
    required: true,
    message: "Please Select Manufacturer",
  },
];
