import {EyeOutlined} from '@ant-design/icons/lib/icons';
import {Button, Space, Avatar} from 'antd';
import {addDollar, formatedDate} from '../../../utilities/helper';
import get from "lodash/get";
import isNull from 'lodash/isNull'

export const columns = ({ onClickEdit, currencyCode, list }) => [
  {
    title: 'Actions',
    dataIndex: '',
    key: 2,
    width: 80,
    render: (text, record) => (
        <Button
            size={"small"}
            type="text"
            onClick={() => {
              onClickEdit(record);
            }}
        >
          <EyeOutlined style={{ fontSize: 20, color: '#b1b1b1' }} />
        </Button>
    )
  },
  {
    title: 'Record No.',
    dataIndex: 'batch_record_no',
    key: 'batch_record_no',
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(list, 'sortBy') === "batch_record_no" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    width: 110
  },
  {
    title: 'Item No.',
    dataIndex: 'item_num',
    key: 'item_num',
    ellipsis: true,
    align:'right',
    width: 180
  },
  {
    title: 'Vendor No.',
    dataIndex: 'vendor_number',
    key: 'vendor_number',
    sorter:true,
    sortOrder : get(list, 'sortBy') === "vendor_number" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    width: 120
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    // sorter:true,
    // sortOrder : get(list, 'sortBy') === "vendor_name" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value, record)=> (
      <Space>
        {!isNull(get(record, 'item_image_url')) ? <Avatar size="large" src={get(record, 'item_image_url')} /> : 
        <Avatar style={{ backgroundColor: '#FAA500'}} size="large" > {value.charAt(0)}</Avatar> }
        {value || '-'}
      </Space>
    ),
    width: 160
  },
  {
    title: 'Vendor Part No.',
    dataIndex: 'vendor_part_num',
    key: 'vendor_number',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    width: 280
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    align:'right',
    render: (text) => addDollar(text, currencyCode),
    width: 90
  },
  {
    title: 'Category',
    dataIndex: 'category_name',
    key: 'category_name',
    render: (text) => (text ? text : '-'),
    width: 200
  },
  {
    title: 'Sub Category',
    dataIndex: 'subcategory_name',
    key: 'subcategory_name',
    render: (text) => (text ? text : '-'),
    width: 200
  },
  {
    title: 'Publish Date',
    dataIndex: 'create_date',
    key: 'create_date',
    sorter:true,
    sortOrder : get(list, 'sortBy') === "create_date" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 120
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    sorter:true,
    sortOrder : get(list, 'sortBy') === "effective_date" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 140
  },

];
