import get from "lodash/get";
import assign from "lodash/assign";
import * as TYPES from "../../constants/actionTypes";
import { organizationFilter } from "../../constants/filterTypes";

const initialState = assign({}, { ...organizationFilter });

const organizationFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ORGANIZATION_FILTER:
      return {
        ...state,
        ...get(action, "payload", {}),
      };

    case TYPES.CLEAR_ORGANIZATION_FILTER:
      return assign({}, { ...organizationFilter });

    default:
      return state;
  }
};

export default organizationFilterReducer;
