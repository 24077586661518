import {Button, DatePicker, Form, Input, Layout, Select, Space} from "antd";
import get from 'lodash/get';
import map from 'lodash/map';
import {useDispatch} from "react-redux";
import {getBatchFilterPayload} from "../../../../utilities/batchManagement";
import moment from "moment";

const { Option } = Select;

function EditPendingBatchFilterForm({
  form,setActiveFilters,tablePaginationParams,activeFilters,setTablePaginationParams, id, vendorList
}) {
  return (
    <Layout className="filter-form" style={{ marginTop: -16 }}>
      <Form form={form} layout="inline"
            onFinish= {() => setTablePaginationParams({
              ...tablePaginationParams,
              offset: 0,
              filters: { ...activeFilters },
            })}>
        <Space>
          <div>
            <p className="form-label">Batch Record No.</p>
            <Input value={activeFilters.batch_record_no}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, batch_record_no: e.target.value ? e.target.value : undefined};
            })}/>
          </div>
          <div>
          <p className="form-label">Vendor Name</p>
            <Select
              className="filter-select"
              size="medium"
              showSearch={true}
              optionFilterProp="children"
              allowClear
              value={activeFilters.vendor_name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, vendor_name: e ? e : undefined};
            })}
            >
              {map(vendorList, (at, index) => (
                <Option key={index} value={at}>
                  {at}
                </Option>
              ))}
            </Select>
          </div>
          {/* <div>
            <p className="form-label">Vendor Name</p>
            <Input value={activeFilters.vendor_name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, vendor_name: e.target.value ? e.target.value : undefined};
            })}/>
          </div> */}
          <div>
            <p className="form-label">Vendor No.</p>
            <Input value={activeFilters.vendor_number}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, vendor_number: e.target.value ? e.target.value : undefined};
            })}/>
          </div>
          <div className="datepicker">
            <p className="form-label">Publish Date</p>
            <DatePicker placeholder={false} size="medium" value={activeFilters.create_date ? moment(activeFilters.create_date,'YYYY-MM-DD') : null}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, create_date: e ? moment(e).format('YYYY-MM-DD') : undefined};
            })}/>
          </div>
          <div className="datepicker">
            <p className="form-label">Effective Date</p>
            <DatePicker size="medium" placeholder={false} value={activeFilters.effective_date ? moment(activeFilters.effective_date,'YYYY-MM-DD') : null}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, effective_date: e ? moment(e).format('YYYY-MM-DD') : undefined};
            })}/>
          </div>
          <div>
            <p className="form-label">Status</p>
            <Select
                className="filter-select"
                size="medium"
                showSearch={true}
                optionFilterProp="children"
                allowClear
                value={activeFilters.status}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, status: e ? e : undefined};
            })}
            >
              <Select.Option value={"NV"}>Valid</Select.Option>
              <Select.Option value={"V"}>Invalid</Select.Option>
              <Select.Option value={null}>All</Select.Option>
            </Select>
          </div>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
                className="cancel-btn"
                onClick={() => {
                  setActiveFilters({});
                  setTablePaginationParams({ ...tablePaginationParams, filters: {} });
                }}
            >
              Clear
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Layout>
  );
}

export default EditPendingBatchFilterForm;
