import React, { useEffect, useState } from 'react';
import { Button, Layout, Table, message } from 'antd';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { deleteReleseNote, fetchReleaseNotes, fetchSingleReleaseNote } from '../redux/actions/syncConnectorAction';
import { releaseNoteTable } from '../constants/data/syncConnectorTable';
import { PlusOutlined } from '@ant-design/icons';
import AddEditReleaseNote from './forms/AddEditReleaseNote';
import { DELETE_RELEASE_NOTE_SUCCESS } from '../constants/messages';

const ReleaseNotes = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [addEditWindow, setAddEditWindow] = useState({
        visible: false,
        data: null,
    });

    const [releaseNotes, setReleaseNotes] = useState(null);

    const getAllReleaseNotes = () => {
        setLoading(true);

        dispatch(fetchReleaseNotes())
        .then((result) => {
            setLoading(false);
            setReleaseNotes(get(result, 'data'));
        })
        .catch((error) => {
            setLoading(false);
        })
    };

    const getSingleReleaseNote = (id) => {
        setLoading(true);
        const payload = { id, };

        dispatch(fetchSingleReleaseNote(payload))
        .then((result) => {
            setLoading(false);
            setAddEditWindow({
                visible: true,
                data: get(result, 'data'),
            });
        })
        .catch((error) => {
            setLoading(false);
        })
    }

    const onSuccess = () => {
        getAllReleaseNotes();
        setAddEditWindow({
            visible: false,
            data: null,
        });
    }

    const removeReleaseNote = (id) => {
        setLoading(true);
        dispatch(deleteReleseNote(id))
        .then((result) => {
            setLoading(false);
            if(get(result, 'success')) {
                message.success(DELETE_RELEASE_NOTE_SUCCESS);
                getAllReleaseNotes();
            }
        })
        .catch((error) => {
            setLoading(false);
        })

    }

    useEffect(() => {
        getAllReleaseNotes();
    }, [])

    return (
        <>
            {
                get(addEditWindow, 'visible')
                ? (
                    <AddEditReleaseNote
                        addEditWindow={addEditWindow}
                        onSuccess={onSuccess}
                        setAddEditWindow={setAddEditWindow}
                    />
                )
                : (
                    <>
                        <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
                            <div>
                                <h3 className="sub-title">Release Notes</h3>
                            </div>

                            <div className="add-connector-button-container">
                                <Button
                                    className="add-connector-btn"
                                    onClick={() => {
                                        setAddEditWindow({
                                            ...addEditWindow,
                                            visible: true,
                                        });
                                    }}
                                    size="large"
                                >
                                    ADD NEW RELEASE NOTE
                                    <PlusOutlined />
                                </Button>
                            </div>
                        </Layout>

                        <div className="user-table">
                            <Table
                                size="small"
                                rowKey="id"
                                scroll={{ y: 'calc(100vh - 430px)'}}
                                loading={loading}
                                dataSource={releaseNotes || []}
                                columns={releaseNoteTable({
                                    onClickEdit: getSingleReleaseNote,
                                    onClickDelete: removeReleaseNote,
                                })}
                                pagination={{}}
                            />
                        </div>
                    </>
                )
            }   
        </>
    );
};

export default ReleaseNotes;