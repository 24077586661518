import * as APIS from "../../../constants/api";
import { FETCH_ERRORED_VENDOR_NAMES_LIST } from "../../../constants/api";

export const updateBatchDistributorCustomer =
  (pendingBatchId, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}`;
    return api
      .patch(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchErrorrdVendorList =
  (pendingBatchId, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}${APIS.FETCH_ERRORED_VENDOR_LIST}`;
    return api
      .post(url, { ...payload, limit: 10 })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updatePendingBatchErroredItems =
  (pendingBatchId, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}${APIS.UPDATE_PENDING_BATCH_ERRORED}`;
    return api
      .patch(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchErrorrdVendorNamesList =
  (pendingBatchId) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}${APIS.FETCH_ERRORED_VENDOR_NAMES_LIST}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const updatePendingVendorNameErroredItems =
  (pendingBatchId, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}/s2`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchErrorrdVendorPartList =
  (pendingBatchId, { payload }) =>
  (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}${APIS.FETCH_ERRORED_VENDOR_PART_LIST}`;
    return api
      .post(url, { ...payload, limit: 10 })
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const updatePendingVendorPartErroredItems =
  (pendingBatchId, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}${APIS.UPDATE_PENDING_VENDOR_PART_ERRORED}`;
    return api
      .patch(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
