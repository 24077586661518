export const SERVER_ENVIRONMENT_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Environment",
  },
  {
    min: 2,
    message: "Environment must contain at least 2 characters",
  },
  {
    max: 20,
    message: "Environment must be less than 20 characters",
  },
];

export const SERVER_NAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Name",
  },
  {
    min: 3,
    message: "Name must contain at least 3 characters",
  },
  {
    max: 60,
    message: "Name must be less than 60 characters",
  },
];

export const SERVER_CONNECTION_TYPE = [
  {
    required: true,
    message: "Please Input Connection Type",
  },
];

export const SERVER_IP_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Server IP Address",
  },
  {
    whitespace: true,
    message: "Please Input Server IP Address",
  },
  {
    max: 16,
    message: "Server IP Address must be less than 16 characters",
  },
];

export const SERVER_DB_NAME_VALIDATIONS = [
  {
    whitespace: true,
    required: true,
    message: "Please Input Database Name",
  },

  {
    max: 50,
    message: "Database Name must be less than 50 characters",
  },
];

export const API_SERVER_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input API Server",
  },

  {
    max: 50,
    message: "API Server must be less than 50 characters",
  },
];

export const AUTH_SERVER_VALIDATIONS = [
  {
    whitespace: true,
    message: "Please Input Auth Server",
  },

  {
    max: 50,
    message: "Auth Server must be less than 50 characters",
  },
];
