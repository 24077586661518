import {Col, Form, Row, Spin} from 'antd';
import {get, sortBy} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {fetchActiveDistributorCustomer} from '../redux/actions/allBatchActions/batchPendingActions';
import {
    fetchErrorrdVendorList,
    fetchErrorrdVendorNamesList,
    fetchErrorrdVendorPartList,
    updateBatchDistributorCustomer
} from '../redux/actions/allBatchActions/dataRectificationActions';
import SecondCarousel from './batchDataRectificationComponents/SecondCarousel';
import FirstCarousel from './batchDataRectificationComponents/FirstCarousel';
import {fetchActiveManufacturerList} from '../redux/actions/allDistributorsAction/distributorsManufacturerActions';
import VendorNameMissingCorousal from "./batchDataRectificationComponents/VendorNameMissingCorousal";
import VendorPartMissingCorousal from "./batchDataRectificationComponents/VendorPartMissingCorousal";

const PendingBatchCarouselContainer = ({pendingBatchDetails, onClose, ...props}) => {
    const pendingBatchId = get(pendingBatchDetails, 'data.id');
    const [distributorCustomer, setDistributorCustomer] = useState([]);
    const [loading, setLoading] = useState(false);
    const [erroredVendorList, setErroredVendorList] = useState({
        data: [],
        hidden: false
    });
    const [erroredVendorNamesList, setErroredVendorNamesList] = useState({
        data: [],
        hidden: false
    });
    const [erroredVendorPartList, setErroredVendorPartList] = useState({
        data: [],
        hidden: false
    });
    const [distributorManufacturer, setDistributorManufacturer] = useState([]);
    const carousel = useRef();
    const dispatch = useDispatch();
    const [rectificationCurrentStep, setRectificationCurrentStep] = useState(0);

    const getActiveDistributorCustomer = (payload) => {
        setLoading(true);
        dispatch(fetchActiveDistributorCustomer(payload))
            .then((result) => {
                setLoading(false);
                const organizationList = get(result, 'data', []);
                setDistributorCustomer(sortBy(organizationList, ['name']));
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getErroredVendorList = (pendingBatchId, payload = {}, redirect = false) => {
        setLoading(true);
        dispatch(fetchErrorrdVendorList(pendingBatchId, payload))
            .then((result) => {
                setLoading(false);
                if (get(result, 'data', []).length === 0) {
                    props.getExistingPendingBatchDetails(pendingBatchId)
                    return;
                }
                setErroredVendorList({
                    ...result,
                    hidden: false
                });

                if (redirect) carousel.current.next();
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getErroredVendorNamesList = (pendingBatchId, redirect = false) => {
        setLoading(true);
        dispatch(fetchErrorrdVendorNamesList(pendingBatchId))
            .then((result) => {
                setLoading(false);
                if (get(result, 'data', []).length === 0) {
                    props.getExistingPendingBatchDetails(pendingBatchId);
                    return;
                }
                setErroredVendorNamesList({
                    data: get(result, 'data', []),
                    hidden: false
                });

                if (redirect) carousel.current.next();
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const getErroredVendorPartList = (pendingBatchId, payload = {}, redirect = false) => {
        console.log(payload)
        setLoading(true);
        dispatch(fetchErrorrdVendorPartList(pendingBatchId, {payload}))
            .then((result) => {
                setLoading(false);
                if (get(result, 'data', []).length === 0) {
                    props.getExistingPendingBatchDetails(pendingBatchId);
                    return;
                }
                setErroredVendorPartList({
                    ...result,
                    hidden: false
                });

                if (redirect) carousel.current.next();
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    const editBatchDistributorCustomer = (batchId, payload) => {
        setLoading(true);
        dispatch(updateBatchDistributorCustomer(batchId, payload))
            .then((result) => {
                setLoading(false);
                getErroredVendorList(pendingBatchId, true);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getActiveManufacturersList = () => {
        setLoading(true);
        dispatch(fetchActiveManufacturerList())
            .then((result) => {
                setLoading(false);
                const manufacturersList = get(result, 'data', []);

                setDistributorManufacturer(sortBy(manufacturersList, ['name']));
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onFinish = (payload) => {
        editBatchDistributorCustomer(pendingBatchId, payload);
    };

    const onRefresh = () => {
        getActiveManufacturersList();
    };

    useEffect(() => {
        if (get(pendingBatchDetails, 'batch_err_type') === 'VENDOR_NAMES_MISSING') {
            getErroredVendorNamesList(pendingBatchId);
            setRectificationCurrentStep(1);
        } else if (get(pendingBatchDetails, 'batch_err_type') === 'VENDOR_PART_MISSING') {
            getErroredVendorPartList(pendingBatchId);
            setRectificationCurrentStep(2);
        } else if (get(pendingBatchDetails, 'batch_err_type') === 'MANUFACTURER_MAPPING') {
            getErroredVendorList(pendingBatchId);
            setRectificationCurrentStep(3);
        }
        getActiveManufacturersList();
        if (pendingBatchId)
            getActiveDistributorCustomer(pendingBatchId);
    }, [pendingBatchDetails]);

    return (
        <Spin spinning={loading}>
            <Row>
                <Col span={24}>

                    {rectificationCurrentStep == 0 ? <div>
                        <Form
                            layout="vertical"
                            initialValues={{
                                customer_distributor_update_flag: false,
                            }}
                            onFinish={onFinish}
                        >
                            <FirstCarousel
                                pendingBatchId={pendingBatchId}
                                pendingBatchDetails={pendingBatchDetails}
                                distributorCustomer={distributorCustomer}
                            />
                        </Form>
                    </div> : null}

                    {rectificationCurrentStep == 1 ? <div>
                        <VendorNameMissingCorousal
                            pendingBatchId={pendingBatchId}
                            pendingBatchDetails={pendingBatchDetails}
                            getErroredVendorNamesList={getErroredVendorNamesList}
                            key={get(erroredVendorNamesList, 'data', []).length}
                            onClose={onClose}
                            erroredVendorNamesList={get(erroredVendorNamesList, 'data')}
                            hidden={get(erroredVendorNamesList, 'hidden')}
                        />
                    </div> : null}
                    {rectificationCurrentStep == 2 ? <div>
                        <VendorPartMissingCorousal
                            pendingBatchId={pendingBatchId}
                            pendingBatchDetails={pendingBatchDetails}
                            getErroredVendorPartList={getErroredVendorPartList}
                            onClose={onClose}
                            key={get(erroredVendorPartList, 'data', []).length}
                            erroredVendorPartList={erroredVendorPartList}
                            hidden={get(erroredVendorPartList, 'hidden')}
                        />
                    </div> : null}
                    {rectificationCurrentStep == 3 ? <div>
                        <SecondCarousel
                            distributorManufacturer={distributorManufacturer}
                            erroredVendorList={erroredVendorList}
                            getErroredVendorList={getErroredVendorList}
                            pendingBatchDetails={pendingBatchDetails}
                            pendingBatchId={pendingBatchId}
                            carousel={carousel}
                            onClose={onClose}
                            hidden={get(erroredVendorList, 'hidden')}
                            onRefresh={onRefresh}
                        />
                    </div> : null}
                </Col>
            </Row>
        </Spin>
    )
        ;
};

export default PendingBatchCarouselContainer;
