import * as TYPES from "../../constants/actionTypes";

export const filterDetails = (payload) => (dispatch, getState, api) => {
  dispatch({
    type: TYPES.FILTER,
    payload: payload,
  });
};
export const distributorFilterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.DISTRIBUTOR_FILTER,
      payload: payload,
    });
  };
export const organizationFilterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.ORGANIZATION_FILTER,
      payload: payload,
    });
  };
export const clearFilter = (payload) => (dispatch, getState, api) => {
  dispatch({
    type: TYPES.CANCEL_FILTER,
  });
};
export const clearManufacturerFilter =
  (payload) => (dispatch, getState, api) => {};
export const clearDistributorFilter =
  (payload) => (dispatch, getState, api) => {};
