import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Divider,
  message,
  Modal,
  Spin,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/svg/editIcon.svg";
import { updateNewPassword } from "../../redux/actions/accountInfoAction";
import * as MESSAGES from "../../constants/messages";
import * as ROUTES from "../../constants/routes";
import * as VALIDATIONS from "../../constants/data/validations";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/authActions";

const PasswordForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const current_password = form.getFieldValue("current_password");
    const repeat_new_password = form.getFieldValue("repeat_new_password");
    const new_password = form.getFieldValue("new_password");
    onFinishPassword({ current_password, repeat_new_password, new_password });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setNewPassword = (payload) => {
    setLoading(true);
    dispatch(updateNewPassword(payload))
      .then((result) => {
        setLoading(false);
        message.success(MESSAGES.PASSWORD_UPDATED);
        dispatch(logout());
        navigate(ROUTES.LOGIN);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishPassword = (value) => {
    if (value.new_password === value.repeat_new_password) {
      const newValue = {
        current_password: value.current_password,
        new_password: value.new_password,
      };
      setNewPassword(newValue);
    } else {
      message.error(MESSAGES.PASSWORD_NOT_MATCH);
    }
  };
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={showModal}
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <div className="account-info-heading">
              <b style={{ marginRight: 10 }}>Password</b>
              <div onClick={() => setEdit(!edit)}>
                <img src={editIcon} alt="" />
              </div>
            </div>
            <Divider style={{ marginTop: 6 }} />
            <Row gutter={16}>
              {edit && (
                <Col xl={12} xs={12}>
                  <Form.Item
                    name="current_password"
                    label="Current Password"
                    rules={[
                      { required: true, message: "Please enter your Password" },
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              {edit && (
                <Col xl={12} xs={12}>
                  <Form.Item
                    name="new_password"
                    label="New Password"
                    rules={VALIDATIONS.PASSWORD_VALIDATION}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              )}
              {edit && (
                <Col xl={12} xs={12}>
                  <Form.Item
                    name="repeat_new_password"
                    label="Repeat New Password"
                    rules={VALIDATIONS.PASSWORD_VALIDATION}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              )}
            </Row>

            {edit && (
              <Form.Item label="" id="btn">
                <Modal
                  width="30%"
                  title=" Confirm Logout"
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p style={{ marginTop: 10, fontSize: 15 }}>
                    If you continue, your current session will end and you will
                    return to Login screen.
                  </p>
                </Modal>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="edit-user-button"
                  size="large"
                >
                  UPDATE
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default PasswordForm;
