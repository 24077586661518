import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { get } from "lodash";
import React from "react";

export const TableDropdown = ({
  itemsArr,
  itemOnClick,
  tablePaginationParams,
}) => {
  const setDropDownItems = () => {
    const dropdownItems = itemsArr.map((item) => {
      return {
        ...item,
        label: (
          <Button
            type="text"
            style={{ width: "100%" }}
            onClick={() => {
              itemOnClick({
                ...tablePaginationParams,
                limit: +item.key,
              });
            }}
          >
            {item.label}
          </Button>
        ),
      };
    });
    return dropdownItems;
  };

  return (
    <Dropdown menu={{ items: setDropDownItems() }} trigger={["click"]}>
      <Button type="default">
        Items Per Page:{get(tablePaginationParams, "limit")}
        <DownOutlined className={"ml-5"} />
      </Button>
    </Dropdown>
  );
};
