export const NAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please Input Name" },
  {
    min: 2,
    message: "Name must contain at least 2 characters",
  },
  {
    max: 100,
    message: "Name must be less than 100 characters",
  },
];

export const CODE_VALIDATIONS = [
  {
    min: 3,
    max: 3,
    whitespace: true,
    message: "Please input 3 characters",
  },
  {
    pattern: new RegExp(/^[a-zA-Z0-9]+$/),
    message: "Default Code must contain only alphanumeric characters",
  },
];

export const WEBSITE_VALIDATIONS = [];
