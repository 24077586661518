//User-Screen
export const ACTIVE_UPDATE_MESSAGE = 'Active state updated sucessfully';
export const ADD_NEW_USER_MESSAGE = 'User created successfully';
export const UPDATE_ROLE_MESSAGE = 'User updated successfully';

// Manufacturer Screen
export const ADD_NEW_MANUFACTURER_MESSAGE = 'Manufacturer created successfully';
export const EDIT_MANUFACTURER_MESSAGE = 'Manufacturer updated successfully';

// Distributor Screen
export const ADD_NEW_DISTRIBUTOR_MESSAGE = 'Distributor created successfully';
export const EDIT_DISTRIBUTOR_MESSAGE = 'Distributor updated successfully';
export const UPDATE_MAPPING_MESSAGE = 'Mapping updated successfully';

// Distributor Screen
export const ADD_NEW_ORGANIZATION_MESSAGE = 'Organization created successfully';
export const EDIT_ORGANIZATION_MESSAGE = 'Organization updated successfully';

// Distributor Manufacturer Screen
export const MAP_DISTRIBUTORS_MANUFACTURERS_MESSAGE = 'Manufacturer mapping created successfully';
export const EDIT_DISTRIBUTORS_MANUFACTURERS_MESSAGE = 'Manufacturer mapping updated successfully';

// Distributor Organization
export const MAP_ORGANISATION = 'Organization mapped successfully';
export const EDIT_DISTRIBUTORS_ORGANIZATION_MESSAGE = 'Organization mapping updated successfully';

// Distributor Contact
export const CREATE_CONTACT = 'Contact created successfully';
export const EDIT_CONTACT = 'Contact updated successfully';
export const DELETE_CONTACT = 'Contact deleted successfully';

//Email-Screen

export const FORGOT_PASSWORD_EMAIL_SUBMIT_MESSAGE = 'Verification mail sent';

//// Organization Server Tab
export const CREATE_SERVER = 'Server created successfully';
export const EDIT_SERVER = 'Server updated successfully';

// Organization Sync Connector Tab
export const ORGANIZATION_SERVICE_ENDPOINT_VERIFIED =
  'The service endpoint is verfied successfully';
export const CREATE_SYNC_CONNECTOR = 'Sync Connector created successfully';
export const EDIT_SYNC_CONNECTOR = 'Sync Connector updated successfully';

//New Password Screen

export const RESET_PASSWORD_MESSAGE = 'Reset Password successful';
export const ERROR_MESSAGE = 'Error occurred';
export const PASSWORD_UPDATED = 'Password updated successfully';

//acoount info screen
export const USER_UPDATE = 'User updated successfully';
export const PASSWORD_NOT_MATCH = 'Passwords do not match';

export const ARCHIVE_BATCH = 'Batch archived successfully';

//sync-connectors
export const ADD_NEW_SYNC_CONNECTORS = 'Sync Connector Type created successfully';

export const LOGOUT_USER_ANOTHER_TAB = 'Please log out the user from another tab';

//Batch Management
export const UPDATE_PENDING_BATCH_MESSAGES = 'Batch item updated successfully';
export const ARCHIVE_BATCH_CONFERMATION_MESSAGE = 'Are you sure you want to archive this batch?';

//Data Rectification
export const UPDATE_SECOND_CAROUSEL_MESSAGE = 'Mapping(s) updated successfully.';
export const VOID_SUCCESSFUL_MESSAGE = "Item(s) voided successfully.";

export const DELETE_RELEASE_NOTE = "Are you sure you want to delete this note ?";
export const DELETE_RELEASE_NOTE_SUCCESS = "Release Note deleted successfully.";