import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import get from "lodash/get";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { columns } from "../../constants/data/AllBatchesTables/pendingBatchTable";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import {
  deletePendingBatch,
  fetchAllPendingBatch,
  fetchExistingPendingBatchDetails,
} from "../../redux/actions/allBatchActions/batchPendingActions";
import PendingBatchFilterForm from "../forms/batchForms/batchManagementFilterForms/PendingBatchFilterForm";
import PendingBatchCarouselContainer from "../PendingBatchCarouselContainer";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../../redux/actions/globalReducActions";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { TableDropdown } from "../common/TableDropDown";

function PendingTableContainer({
  selectedKey,
  distributorList,
  organizationList,
}) {
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [form] = Form.useForm();

  const [pendingData, setPendingData] = useState({});

  const [pendingBatchDrawer, setPendingBatchDrawer] = useState({
    visible: false,
    data: null,
  });

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const tablePaginationParams = useSelector(
    (state) => get(state, "global.globalPagesPagination")[PAGES.BATCH_PENDING]
  );
  const activeFilters = useSelector(
    (state) => get(state, "global.globalPagesFilterData")[PAGES.BATCH_PENDING]
  );
  // console.log("activefilters", activeFilters);
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(PAGES.BATCH_PENDING, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.BATCH_PENDING, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesFilterData(PAGES.BATCH_PENDING, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.BATCH_PENDING, param));
    }
  };
  useEffect(() => {
    getPendingBatchList();
  }, [tablePaginationParams]);
  const toggleDrawer = (data = null) => {
    setPendingBatchDrawer({
      visible: data ? true : false,
      data,
    });
  };

  const onClickEdit = (record) => {
    getExistingPendingBatchDetails(get(record, "id", 0));
  };

  const onClickDelete = (record) => {
    const id = get(record, "id", 0);
    removePendingBatch(id);
  };

  const getExistingPendingBatchDetails = (pendingId) => {
    setLoading(true);
    dispatch(fetchExistingPendingBatchDetails(pendingId))
      .then((result) => {
        setLoading(false);
        if (result.hasOwnProperty("batch_err_type")) {
          toggleDrawer(result);
          return;
        }
        navigate({
          pathname: ROUTES.EDIT_PENDING_BATCHES,
          search: `${pendingId}`,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getPendingBatchList = (payload, resetFilter = false) => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchAllPendingBatch(params))
      .then((result) => {
        setPendingData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const removePendingBatch = (batchId) => {
    setLoading(true);
    dispatch(deletePendingBatch(batchId))
      .then((result) => {
        setLoading(false);
        message.success(MESSAGES.ARCHIVE_BATCH);
        getPendingBatchList();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPendingBatchList();
  }, []);

  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };

  return (
    <Spin spinning={loading}>
      <PendingBatchFilterForm
        getPendingBatchList={getPendingBatchList}
        distributorList={distributorList}
        organizationList={organizationList}
        form={form}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />

      <div className="user-table" style={{ marginTop: -16 }}>
        <Table
          showSorterTooltip={false}
          ref={tableRef}
          size="small"
          rowKey="id"
          dataSource={get(pendingData, "data")}
          columns={columns({ onClickEdit, onClickDelete, pendingData })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(pendingData, "offset", 0) + 1} to ${
                    get(pendingData, "data", []).length +
                    get(pendingData, "offset", "--")
                  } of ${get(pendingData, "total", "--")} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() => {
                      if (tableRef?.current) {
                        const scrollContainer =
                          tableRef.current.querySelector(".ant-table-body");
                        if (scrollContainer) {
                          scrollContainer.scrollTop = 0;
                        }
                      }

                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(pendingData, "offset", 0) -
                          get(pendingData, "limit", 0),
                      });
                    }}
                    disabled={!get(pendingData, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() => {
                      if (tableRef?.current) {
                        const scrollContainer =
                          tableRef.current.querySelector(".ant-table-body");
                        if (scrollContainer) {
                          scrollContainer.scrollTop = 0;
                        }
                      }

                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(pendingData, "offset", 0) +
                          get(pendingData, "limit", 0),
                      });
                    }}
                    disabled={
                      get(pendingData, "offset", 0) +
                        get(pendingData, "limit", 0) >=
                      get(pendingData, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          scroll={{ y: "calc(100vh - 420px)", x: 1000 }}
          pagination={false}
        />
      </div>
      <Drawer
        height="100%"
        width="100%"
        title={
          <h2>
            Batch Data Rectification -{" "}
            {get(pendingBatchDrawer, "data.data.id", "--")}
          </h2>
        }
        placement="right"
        onClose={() => {
          toggleDrawer();
          getPendingBatchList();
        }}
        open={get(pendingBatchDrawer, "visible")}
        destroyOnClose
      >
        <PendingBatchCarouselContainer
          pendingBatchDetails={get(pendingBatchDrawer, "data")}
          onClose={() => {
            toggleDrawer();
            getPendingBatchList();
          }}
          getExistingPendingBatchDetails={getExistingPendingBatchDetails}
        />
      </Drawer>
    </Spin>
  );
}

export default PendingTableContainer;
