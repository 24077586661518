import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import batchFilterReducer from "./batchFilterReducer";
import batchSorterReducer from "./batchSorterReducer";
import filterReducer from "./filterReducer";
import sorterReducer from "./sorterReducer";
import distributorFilterReducer from "./distributorFilterReducer";
import manufacturerFilterReducer from "./manufacturerFilterReducer";
import organizationFilterReducer from "./organizationFilterReducer";
import userFilterReducer from "./userFilterReducer";
import globalReducer from "./globalReducer";

export default combineReducers({
  auth: AuthReducer,
  allSorter: sorterReducer,
  allFilter: filterReducer,
  batchFilter: batchFilterReducer,
  distributorFilter: distributorFilterReducer,
  batchSorter: batchSorterReducer,
  manufacturerFilter: manufacturerFilterReducer,
  organizationFilter: organizationFilterReducer,
  userFilter: userFilterReducer,
  global: globalReducer,
});
