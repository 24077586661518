import { DeleteTwoTone } from '@ant-design/icons/lib/icons';
import {Button, Popconfirm, Space} from 'antd';
import editIcon from '../../../assets/svg/editIcon.svg';
import { formatedDate, setBatchStatus } from '../../../utilities/helper';
import * as MESSAGE from '../../messages';
import get from "lodash/get";

export const columns = ({ onClickEdit, onClickDelete, pendingData }) => [
  {
    title: 'Actions',
    dataIndex: '',
    key: 2,
    width: 80,
    render: (text, record) => (
        <Button.Group >
            <Button type="text" size="small" onClick={() => onClickEdit(record)}>
              <img src={editIcon} alt="" />
            </Button>
            <Popconfirm
                title={MESSAGE.ARCHIVE_BATCH_CONFERMATION_MESSAGE}
                onConfirm={() => onClickDelete(record)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
            >
              <Button type="text" size="small">
                <DeleteTwoTone style={{ fontSize: 18, marginTop: 3, color: 'red' }} />
              </Button>
            </Popconfirm>
        </Button.Group>
    )
  },
  {
    title: 'Batch ID',
    dataIndex: 'id',
    key: 'id',
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(pendingData, 'sortBy') === "id" ?(get(pendingData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    width: 110
  },
  {
    title: 'Distributor',
    dataIndex: 'Distributor',
    key: 'Distributor',
    render: (text) => text.name,
    ellipsis: true,
    width: 240
  },
  {
    title: 'Organization',
    dataIndex: 'Organization',
    key: 'Organization',
    render: (text) => (text && text.name ? text.name : '-'),
    ellipsis: true,
    width: 240
  },
  {
    title: 'Publish Date',
    dataIndex: 'create_date',
    key: 'create_date',
    sorter:true,
    sortOrder : get(pendingData, 'sortBy') === "create_date" ?(get(pendingData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 200
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    sorter:true,
    sortOrder : get(pendingData, 'sortBy') === "effective_date" ?(get(pendingData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 200
  },

  {
    title: 'Batch Status',
    dataIndex: 'status',
    key: 'status',
    
    render: (text) => setBatchStatus(text),
    width: 180
  },

];
