import { Button, Form, Input, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailSubmit } from "../../redux/actions/authActions";
import BlackLeafMasterLogo from "../../assets/svg/BlackLeafMasterLogo.svg";
import { useState } from "react";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations";
import BeforeLoginLogo from "../../assets/images/BeforeLoginLogo.png";

const EmailScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn) {
    navigate("/users");
  }

  const onClickContinue = (value) => {
    setLoading(true);
    dispatch(emailSubmit(value))
      .then((result) => {
        message.success(MESSAGES.FORGOT_PASSWORD_EMAIL_SUBMIT_MESSAGE);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    onClickContinue(values);
  };

  return (
    <Spin spinning={loading}>
      <div className="forgot-password-form-wrapper">
        <div className="forgot-password-form-container">
          <div className="logo-container">
            <img src={BeforeLoginLogo} alt="" style={{ marginBottom: 10 }} />
          </div>
          <div style={{ fontSize: "14px" }} className="main-login-heading">
            <h3>Forgot Password?</h3>
            <p>Please enter your Email ID to reset your password</p>
          </div>
          <Form
            name="normal login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label="Email ID"
              rules={VALIDATIONS.EMAIL_VALIDATIONS}
            >
              <Input size="large" style={{ width: 350 }} />
            </Form.Item>

            <div>
              <p
                style={{ cursor: "pointer" }}
                className="forgot-password"
                onClick={() => navigate("/login")}
              >
                Back to Login
              </p>
            </div>
            <Form.Item>
              <Button
                className="continue-button"
                size="large"
                htmlType="submit"
              >
                CONTINUE
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default EmailScreen;
