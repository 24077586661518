import { Spin, Layout } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import LayoutHeader from "./components/layout/LayoutHeader";
import { loggedInUserDetails } from "./redux/actions/authActions";
import AuthRoutes from "./routes/AuthRoutes";

const AppRouter = () => {
  const [loading] = useState(false);
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) =>
    get(state, "auth.isLoggedIn", false)
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loggedInUserDetails());
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <Spin spinning={loading}>
        <Layout>
          {isLoggedIn && <LayoutHeader />}
          <Layout style={{ background: "white", marginTop: 125 }}>
            <AuthRoutes />
          </Layout>
        </Layout>
      </Spin>
    </Router>
  );
};

export default AppRouter;
