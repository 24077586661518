import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  DatePicker,
  Typography,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/OrganizationValidations";
import {
  createOrganization,
  checkServiceEndpoint,
} from "../../redux/actions/organizationActions";
import { ID_VALIDATION } from "../../constants/data/OrganizationValidations";
import get from "lodash/get";

const { Text } = Typography;

const AddOrganizationForm = () => {
  const [loading, setLoading] = useState(false);
  const [checkingUrlStatus, setCheckingUrlStatus] = useState(false);
  const [disableUrlField, setDisableUrlField] = useState(false);
  const [urlVerified, setUrlVerified] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const addOrganization = (payload) => {
    setLoading(true);
    dispatch(createOrganization(payload))
      .then((result) => {
        setLoading(false);
        navigate(ROUTES.ORGANISATION_MANAGEMENT);
      })
      .catch((result) => {
        setLoading(false);
      });
  };

  const checkServiceEndopint = () => {
    if (
      form.getFieldValue("portal_backend_url") &&
      form.getFieldError("portal_backend_url").length === 0
    ) {
      setCheckingUrlStatus(true);
      setDisableUrlField(true);
      dispatch(
        checkServiceEndpoint({
          portal_backend_url: form.getFieldValue("portal_backend_url"),
        })
      )
        .then(({ portal_status = null, message: message_backend }) => {
          setCheckingUrlStatus(false);
          if (portal_status === true) {
            setDisableUrlField(true);
            setUrlVerified(true);
            message.success(MESSAGES.ORGANIZATION_SERVICE_ENDPOINT_VERIFIED);
          } else if (portal_status === false) {
            setDisableUrlField(false);
            setUrlVerified(false);
            message.error(message_backend);
          }
        })
        .catch(() => {
          setCheckingUrlStatus(false);
        });
    }
  };

  const onFinish = (value) => {
    value["expires_at"] = get(value, "expires_at")
      ? value["expires_at"].format("YYYY-MM-DD")
      : null;
    addOrganization(value);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Organization Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(ROUTES.ORGANISATION_MANAGEMENT)}
            />
          </div>
          <h2 className="sub-title arrow-sub-title-alingment ">
            Add Organization
          </h2>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ is_active: true }}
        >
          <Row gutter={16}>
            <Col xl={20} xs={20}>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="id"
                    label="BLS Customer ID"
                    rules={VALIDATIONS.ID_VALIDATIONS}
                  >
                    <InputNumber
                      size="large"
                      style={{ width: "100%" }}
                      type={"number"}
                    />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="name"
                    label="Organization Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                {/*<Col xl={8} xs={8}>*/}
                {/*  <Form.Item*/}
                {/*    name="portal_backend_url"*/}
                {/*    label="Connect Portal Service Endpoint"*/}
                {/*    rules={VALIDATIONS.PORTAL_BACKEND_URL_VALIDATIONS}*/}
                {/*  >*/}
                {/*    <Input size="large" disabled={disableUrlField} className="non-required" addonAfter={urlVerified ? <CheckCircleTwoTone  /> : <Button*/}
                {/*        loading={checkingUrlStatus}*/}
                {/*        disabled={urlVerified}*/}
                {/*        onClick={() => checkServiceEndopint()}*/}
                {/*        size="small"*/}
                {/*        type={"link"}*/}
                {/*    >*/}
                {/*      Verify*/}
                {/*    </Button>}/>*/}
                {/*  </Form.Item>*/}
                {/*</Col>*/}
                <Col xl={8} xs={8}>
                  <Form.Item name="expires_at" label="Expires At">
                    <DatePicker style={{ width: 300, height: 41 }} />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <div className="distributor-form-subheading">
                <Text type="secondary">Primary Contact Details</Text>
              </div>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_first_name"
                    label="First Name"
                    rules={VALIDATIONS.PRIMARY_USER_FIRST_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_last_name"
                    label="Last Name"
                    rules={VALIDATIONS.PRIMARY_USER_LAST_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_email"
                    label="Email"
                    rules={VALIDATIONS.PRIMARY_USER_EMAIL_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_password"
                    label="Password"
                    rules={VALIDATIONS.PASSWORD_VALIDATION}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <div className="manufacturer-form-button-container form-positioning">
                <Form.Item label="">
                  <Button
                    className="add-user-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    ADD
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={() => navigate(ROUTES.ORGANISATION_MANAGEMENT)}
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};
export default AddOrganizationForm;
