export const PAGES_CODE = {
    BATCH_PENDING: 'BATCH_PENDING',
    BATCH_ARCHIVED: 'BATCH_ARCHIVED',
    BATCH_ITEMS_ARCHIVED: 'BATCH_ITEMS_ARCHIVED',
    BATCH_ITEMS_PENDING: 'BATCH_ITEMS_PENDING',
    DISTRIBUTOR: 'DISTRIBUTOR',
    MANUFACTURER: 'MANUFACTURER',
    ORGANISATION : 'ORGANISATION',
    USERS: 'USERS',
};
export const DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT = [{
    label: 10,
    key: 10,
}, {
    label: 20,
    key: 20,
}, {
    label: 50,
    key: 50,
}, {
    label: 100,
    key: 100,
}, {
    label: 500,
    key: 500,
}, {
    label: 1000,
    key: 1000,
}];