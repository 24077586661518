export const BATCH_CUTSHEET_URL_VALIDATIONS = [
  {
    type: "url",
    whitespace: true,
    message: "Please input a valid Cutsheet Url",
  },
  {
    max: 600,
    message: "Cutsheeet URL cannot exceed 600 characters",
  },
];
export const BATCH_ITEM_IMAGE_URL_VALIDATIONS = [
  {
    type: "url",
    whitespace: true,
    message: "Please input a valid Image Url",
  },
  {
    max: 600,
    message: "Item Image URL cannot exceed 600 characters",
  },
];

export const BATCH_ITEM_PRICE_VALIDATIONS = [
  {
    max: 20,
    message: "Price cannot exceed 20 characters",
  },
];
export const BATCH_ITEM_CATAGORY_CODE_VALIDATIONS = [
  {
    max: 50,
    message: "Category Code cannot exceed 50 characters",
  },
];
export const BATCH_ITEM_CATEGORY_NAME_VALIDATIONS = [
  {
    max: 50,
    message: "Category Name cannot exceed 50 characters",
  },
];
export const BATCH_ITEM_SUBCATEGORY_CODE_VALIDATIONS = [
  {
    max: 50,
    message: "Sub Category Code cannot exceed 50 characters",
  },
];
export const BATCH_ITEM_SUBCATEGORY_NAME_VALIDATIONS = [
  {
    max: 50,
    message: "Sub Category Name cannot exceed 50 characters",
  },
];
export const BATCH_ITEM_UPC_CODE_VALIDATIONS = [
  {
    max: 20,
    message: "UPC cannot exceed 20 characters",
  },
];
export const BATCH_ITEM_UOM_VALIDATIONS = [
  {
    max: 2,
    message: "UOM cannot exceed 2 characters",
  },
];
export const BATCH_COLOR_VALIDATIONS = [
  {
    max: 40,
    message: "Color cannot exceed 40 characters",
  },
];
