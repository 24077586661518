//AUTH ROUTES
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const FORGOT_PASSWORD_EMAIL = '/forgotPassword/email';
export const RESET_PASSWORD_NEWPASSWORD = '/reset-password/:id';

//HOMEPAGE ROUTES

export const BATCH_MANAGEMENT = '/batch-management';
export const SENT_BATCH_LIST = '/batch-management?tab=sent';
export const ARCHIVED_BATCH_LIST = '/batch-management?tab=archived';
export const USER_MANAGEMENT = '/users';
export const MANUFACTURER_MANAGEMENT = '/manufacturer-management';
export const ORGANISATION_MANAGEMENT = '/organization-management';
export const SYSTEM_CONFIGURATION = '/system-configuration';
export const PAGE_NOT_FOUND_ROUTES = '*';

// Distributors Routes
export const DISTRIBUTOR_MANAGEMENT = '/distributor-management';
export const EDIT_DISTRIBUTOR = '/edit-distributor';
export const ADD_DISTRIBUTOR = '/add-distributor';

//Organization Routes
export const EDIT_ORGANIZATION = '/edit-organization';
export const ADD_ORGANIZATION = '/add-organization';

// Manufacturer Routes

export const ADD_MANUFACTURER = '/add-manufacturer';
export const EDIT_MANUFACTURER = '/edit-manufacturer';

//Batch Routes
export const EDIT_PENDING_BATCHES = '/edit-pending-batch';
export const EDIT_SENT_BATCHES = '/edit-sent-batch';
export const EDIT_ARCHIVED_BATCHES = '/edit-archived-batch';
export const EDIT_PENDING_BATCH_ITEM = '/edit-batch-form';
export const EDIT_SENT_BATCH_ITEM = '/sent-batch-form';
export const EDIT_ARCHIVED_BATCH_ITEM = '/archived-batch-form';

//ACCOUNT INFORMATION
export const ACCOUNT_INFORMATION = '/account-information';
