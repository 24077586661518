import {
  GLOBAL_SIDER_COLLAPSED,
  GLOBAL_UPDATE_ROUTE,
  GLOBAL_SIDER_MENU, GLOBAL_PAGES_PAGINATION, GLOBAL_PAGES_FILTER_TOGGLE, GLOBAL_PAGES_FILTER_DATA,
} from '../reduxConstant';

export const globalUpdateRoute = (routeParams) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_UPDATE_ROUTE,
    payload: { ...routeParams },
  });
};
export const globalSiderCollapsed = (option) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_SIDER_COLLAPSED,
    payload: !!option,
  });
};
export const globalSiderMenu = (value) => (dispatch, getState) => {
  return dispatch({
    type: GLOBAL_SIDER_MENU,
    payload: value[0],
  });
};

export const globalPagesPagination =
  (tab,data) => (dispatch, getState) => {
    return dispatch({
      type: GLOBAL_PAGES_PAGINATION,
      payload: {tab,data}
    });
  };

export const globalPagesFilterToggle =
  (tab,data) => (dispatch, getState) => {
    return dispatch({
      type: GLOBAL_PAGES_FILTER_TOGGLE,
      payload: {tab,data}
    });
  };

export const globalPagesFilterData =
  (tab,data) => (dispatch, getState) => {
    return dispatch({
      type: GLOBAL_PAGES_FILTER_DATA,
      payload: {tab,data}
    });
  };

