import * as APIS from "../../constants/api";
import { getFormDataPayload } from "../../utilities/helper";

export const fetchAllDistributors = (payload) => (dispatch, getState, api) => {
  const url = APIS.FETCH_ALL_DISTRIBUTOR;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const createDistributor =
  (image, payload) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = `${APIS.CREATE_DISTRIBUTOR}`;
    return api
      .post(url, formData)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
export const fetchSingleDistributor = (id) => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_SINGLE_DISTRIBUTOR}/${id}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const updateDistributor =
  (payload, id, image) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = `${APIS.UPDATE_DISTRIBUTER}/${id}`;
    return api
      .patch(url, formData)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
//Distributor Compact api
export const fetchAllDistributorsList = () => (dispatch, getState, api) => {
  const url = APIS.FETCH_ALL_DISTRIBUTOR_LIST;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
