import get from "lodash/get";
import * as APIS from "../../constants/api";

export const roleTypeEnum = () => (dispatch, getState, api) => {
  const url = APIS.ROLE_TYPE;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchLoggedInUserDetails = () => (dispatch, getState, api) => {
  const url = APIS.FETCH_LOGGEDIN_USER;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const createUser = (payload) => (dispatch, getState, api) => {
  const url = APIS.CREATE_USER;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchAllUsers = (payload) => (dispatch, getState, api) => {
  const url = APIS.FETCH_ALL_USERS;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchSingleUser = (id) => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_SINGLE_USER}/${id}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const updateUser = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.UPDATE_USER}/${get(payload, "id")}`;
  return api
    .patch(url, payload)
    .then(() => {})
    .catch((error) => {
      throw error;
    });
};

export const updateActiveSwitch = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.UPDATE_ACTIVE_SWITCH}/${get(payload, "id")}`;
  return api
    .patch(url, { is_active: !get(payload, "is_active") })
    .then(() => {})
    .catch((error) => {
      throw error;
    });
};
