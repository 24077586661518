import get from "lodash/get";
import * as APIS from "../../../constants/api";

export const fetchAllDistributorsContacts =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${id}${APIS.FETCH_ALL_DISTRIBUTOR_CONTACTS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchSingleDistributorsContacts =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "distributor_id",
      0
    )}/contact/${get(payload, "id", 0)}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const createDistributorsContact =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "did",
      0
    )}${APIS.CREATE_DISTRIBUTORS_CONTACT}`;
    return api
      .post(url, {
        distributor_contact_type_id: get(
          payload,
          "distributor_contact_type_id",
          0
        ),
        name: get(payload, "name", ""),
        phone: get(payload, "phone", 0),
        email: get(payload, "email", ""),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const deleteDistributorContact =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "distributor_id",
      0
    )}/contact/${get(payload, "id", 0)}`;
    return api
      .delete(url)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const updateDistributorsContact =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "did",
      0
    )}/contact/${get(payload, "cid", 0)}`;
    return api
      .patch(url, {
        distributor_contact_type_id: get(
          payload,
          "distributor_contact_type_id",
          0
        ),
        name: get(payload, "name", ""),
        phone: get(payload, "phone", 0),
        email: get(payload, "email", ""),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
export const fetchContactType = () => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_CONTACT_TYPE}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
