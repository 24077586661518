//AUTH - API
export const LOGIN = '/user/login';
export const LOGOUT = '/user/logout';
export const VERIFY_OTP = '/user/2fa/otp/verify';
export const VERIFY_TOTP = '/user/2fa/totp/verify';

//RESET & FORGOT PASSWORD API
export const FORGOT_PASSWORD_EMAIL_SUBMIT = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';

//PROFILE-API
export const FETCH_LOGGEDIN_USER = '/me';
export const UPDATE_LOGGEDIN_USER = '/me/info';
export const UPDATE_NEWPASSWORD = '/me/password';
export const TWO_FA_QR_GENERATE = '/me/totp/generate';

//USER-API
export const ROLE_TYPE = '/role/list';
export const CREATE_USER = '/user/create';
export const UPDATE_USER = '/user';
export const UPDATE_ACTIVE_SWITCH = '/user';
export const FETCH_ALL_USERS = '/user/list';
export const FETCH_SINGLE_USER = '/user';

// Manufacturer Api
export const CREATE_MANUFACTURER = '/manufacturer/create';
export const FETCH_ALL_MANUFACTURER = '/manufacturer/list';
export const FETCH_SINGLE_MANUFACTURER = '/manufacturer';
export const UPDATE_MANUFACTURER = '/manufacturer';
export const FETCH_ALL_ACTIVE_MANUFACTURER = '/compact/manufacturer/active/list';
export const FETCH_ALL_MANUFACTURER_LIST = '/compact/manufacturer/list';

//Distributor Api
export const FETCH_ALL_DISTRIBUTOR = '/distributor/list';
export const UPDATE_DISTRIBUTER = '/distributor';
export const CREATE_DISTRIBUTOR = '/distributor/create';
export const FETCH_SINGLE_DISTRIBUTOR = '/distributor';
export const FETCH_ALL_DISTRIBUTOR_LIST = '/compact/distributor/list';

// Distributors Manufacturers Api
export const BASE_DISTRIBUTORS_MANUFACTURER = '/distributor';
export const FETCH_ALL_DISTRIBUTORS_MANUFACTURERS = '/manufacturer/list';
export const FETCH_ALL_MANUFACTURERS_LIST = '/compact/manufacturer/list';

//Distributor Organization Api
export const FETCH_ALL_DISTRIBUTORS_ORGANISATIONS = '/organization/list';
export const FETCH_ALL_ACTIVE_ORGANISATION = '/compact/organization/active/list';

//Distributor Contact Api
export const BASE_ORGANIZATION = '/organization';
export const FETCH_ALL_DISTRIBUTOR_CONTACTS = '/contact/list';
export const FETCH_CONTACT_TYPE = '/distributor/contact-type';
export const CREATE_DISTRIBUTORS_CONTACT = '/contact/create';
export const ACTIVE_MANUFACTURER_LIST = '/compact/manufacturer/active/list';

//Organization Apis
export const FETCH_ALL_ORGANIZATION = '/organization/list';
export const UPDATE_ORGANIZATION = '/organization';
export const CREATE_ORGANIZATION = '/organization/create';
export const FETCH_SINGLE_ORGANIZATION = '/organization';
export const CHECK_ORGANIZATION_STATUS = '/organization/portal/status';

//Organization Distributors Api
export const FETCH_ALL_ORGANIZATIONS_DISTRIBUTORS = '/distributor/list';

//Organization Contact Api
export const FETCH_ALL_ORGANIZATIONS_CONTACTS = '/contact/list';
export const FETCH_ORGANIZATION_CONTACT_TYPE = '/organization/contact-type';
export const CREATE_ORGANIZATION_CONTACT = '/contact/create';

//Organization server Api
export const FETCH_ALL_ORGANIZATIONS_SERVERS = '/server/list';
export const CREATE_ORGANIZATION_SERVER = '/server/create';

//Organization Sync-Connector Api
export const FETCH_ALL_ORGANIZATIONS_SYNC_CONNECTORS = '/sync-connector/list';
export const CREATE_ORGANIZATION_SYNC_CONNECTOR = '/sync-connector/create';
export const COMPACT_ORG_ACTIVE_LIST = 'compact/org/active/list';

// Organization Sync-Connector-Pair Api
export const FETCH_ALL_ORGANIZATION_SYNC_CONNECTOR_PAIRS = '/sync-connector-pair/list';
export const CREATE_ORGANIZATION_SYNC_CONNECTOR_PAIR = '/sync-connector-pair/create';
export const ORGANIZATION_SYNC_CONNECTOR_PAIR = '/sync-connector-pair';

export const FETCH_ALL_ORGANIZATION_PARENT_SYNC_CONNECTORS = '/compact/parent-sync-connector/list';
export const FETCH_ALL_ORGANIZATION_CHILD_SYNC_CONNECTORS = '/compact/child-sync-connector/list';

// export const ACTIVE_MANUFACTURER_LIST = "/compact/manufacturer/active/list";

//sync-connector-Api
export const FETCH_ALL_SYNC_CONNECTORS = '/sync-connector-type/list';
export const CONNECTOR_LIST_TYPE = '/compact/sync-connector-type/active/list';
export const ABOUT = '/about';
export const CREATE_SYNC_CONNECTORS = '/sync-connector-type/create';
export const UPDATE_SYNC_CONNECTORS = '/sync-connector-type';
export const UPDATE_SYNC_CONNECTOR_ACTIVE_SWITCH = '/sync-connector-type';

//Batch Management
export const BASE_BATCH = '/batch';

//Batch Management Pending
export const FETCH_ALL_PENDING_BATCHES = '/pending/list';

//Batch Management sent
export const FETCH_ALL_SENT_BATCHES = '/sent/list';

//Btch Management Archived
export const FETCH_ALL_ARCHIVED_BATCHES = '/archived/list';

//Batch Management Data Rectification
export const FETCH_ERRORED_VENDOR_LIST = '/errored/vendor/list/v2';
export const UPDATE_PENDING_BATCH_ERRORED = '/errored/vendor/v2';
export const FETCH_ERRORED_VENDOR_NAMES_LIST = '/errored/vendor/name/list';
export const UPDATE_PENDING_VENDOR_NAME_ERRORED = '/errored/vendor/name';
export const FETCH_ERRORED_VENDOR_PART_LIST = '/errored/vendor/part/list';
export const UPDATE_PENDING_VENDOR_PART_ERRORED = '/errored/vendor/part';
//Profile - api

export const LOGGEDIN_USER = '/me';

export const FETCH_RELEASE_NOTES_LIST = '/release-notes/list';
export const FETCH_SINGLE_RELEASE_NOTE = '/release-notes';
export const CREATE_RELEASE_NOTE = '/release-notes/create';
export const UPDATE_RELEASE_NOTE = '/release-notes';
