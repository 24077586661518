export const normalInitialFilter = {
  sortBy: "id",
  sortDir: "DESC",
  filters: {},
  limit: 500,
  offset: 0,
};

export const distributerFilter = {
  sortBy: "id",
  sortDir: "DESC",
  filters: {
    is_active: true,
  },
  limit: 500,
  offset: 0,
};

export const manufacturerFilter = {
  sortBy: "id",
  sortDir: "DESC",
  filters: {
    is_active: true,
  },
  limit: 500,
  offset: 0,
};

export const organizationFilter = {
  sortBy: "id",
  sortDir: "DESC",
  filters: {
    is_active: true,
  },
  limit: 500,
  offset: 0,
};

export const userFilter = {
  sortBy: "id",
  sortDir: "DESC",
  filters: {
    is_active: true,
  },
  limit: 500,
  offset: 0,
};
