import { Switch, Avatar, Button, Popconfirm } from "antd";
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import { formatDateWithoutMoment, getTextBetweenTags } from "../../utilities/helper";
import editIcon from '../../assets/svg/editIcon.svg';
import { DeleteTwoTone } from "@ant-design/icons";
import { DELETE_RELEASE_NOTE } from "../messages";

export const columns = ({ onChangeHandler, onClickEdit }) => [
  {
    title: 'Action',
    key: 'action',
    width: 100,
    render: (record) => (
      <Button type="text" size="small" onClick={() => onClickEdit(record)}>
        <img src={editIcon} alt="" />
      </Button>
    ),
  },
  {
    title: "ID",
    key: "id",
    width: 100,
    dataIndex: "id",
  },
  {
    title: "Image",
    key: "image_url",
    width: 150,
    dataIndex: "image_url",
    render:(value, record)=> 
        !isNull(get(record, 'image_url')) ? <Avatar size="large" src={get(record, 'image_url')} /> : 
        <Avatar style={{ backgroundColor: '#FAA500'}} size="large" > {get(record,"name").charAt(0)}</Avatar> 
  },
  {
    title: "Description",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date Active",
    dataIndex: "active_date",
    key: "active_date",
    render: (text) => formatDateWithoutMoment(text),
  },
  {
    title: "Date Inactive",
    dataIndex: "inactive_date",
    key: "inactive_date",
    render: (text) => formatDateWithoutMoment(text),
  },
  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
    render: (text, record) => (
      <>
        <Switch
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },
];

export const releaseNoteTable = ({ onClickEdit, onClickDelete }) => [
  {
    title: "Action",
    key: "action",
    width: 80,
    render: (record) => (
      <Button.Group>
        <Button type="text" size="small" onClick={() => onClickEdit(get(record, 'id')) }>
          <img src={editIcon} alt="" />
        </Button>

        <Popconfirm
                title={DELETE_RELEASE_NOTE}
                onConfirm={() => onClickDelete(get(record, 'id'))}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
            >
              <Button type="text" size="small">
                <DeleteTwoTone style={{ fontSize: 18, marginTop: 3, color: 'red' }} />
              </Button>
            </Popconfirm>
      </Button.Group>
    ),
  },
  {
    title: "Release Title",
    key: "release_title",
    width: 150,
    dataIndex: "title",
  },
  {
    title: "Version",
    key: "version",
    width: 150,
    dataIndex: "version",
  },
  {
    title: "Release Date",
    key: "release_date",
    width: 150,
    dataIndex: "date",
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "note",
    render: (item) => <>{getTextBetweenTags(item)}</>
  },
]
