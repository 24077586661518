import {Button, Form, Input, Layout, Select, Space} from "antd";
import get from "lodash/get";
import map from "lodash/map";
import isBoolean from "lodash/isBoolean";
import {ActiveList} from "../../utilities/helper";
import {useEffect, useState} from "react";

const { Option } = Select;

const ManufacturerFilterForm = ({
  form, setActiveFilters,tablePaginationParams,activeFilters,setTablePaginationParams
}) => {
  // const [filters,setFilters] = useState({});
  // useEffect(()=>{
  //   setActiveFilters(activeFilters);
  // },[activeFilters])
  

  return (
    <Layout className="filter-form">
      <Form
        form={form}
        layout="inline"
        onFinish= {() => setTablePaginationParams({
          ...tablePaginationParams,
          offset: 0,
          filters: { ...activeFilters },
        })}
      >
        <Space>
        <div>
          <p className="form-label">Name</p>
            <Input value={activeFilters.name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, name: e.target.value ? e.target.value : undefined};
            })} />
        </div>
        <div>
          <p className="form-label">Default Code</p>
            <Input value={activeFilters.default_code}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, default_code: e.target.value ? e.target.value : undefined};
            })} />
        </div>
        <div>
          <p className="form-label">Status</p>
            <Select className="filter-select" size="medium" value={activeFilters.is_active}  onChange={(value) => setActiveFilters(prev => {
              return { ...prev, is_active: value !== null ? value : undefined};
            })}>
              {map(ActiveList, (at) => (
                <Option key={get(at, "key")} value={get(at, "value")}>
                  {get(at, "name")}
                </Option>
              ))}
            </Select>
        </div>

        <Form.Item>
          <Button className="filter-btn add-btn" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
          <Button
            className="filter-btn cancel-btn"
            onClick={() => {
              setActiveFilters({});
              setTablePaginationParams({ ...tablePaginationParams, filters: {} });
            }}
          >
            Clear
          </Button>
        </Space>
      </Form>
    </Layout>
  );
};

export default ManufacturerFilterForm;
