import { Spin, Form, Row, Col, Input, Button, message, Select } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations/subOrganizationValidation/OrganizationServerValidations";
import {
  createOrganizationsServer,
  updateOrganizationsServer,
} from "../../redux/actions/allOrganizationsActions/organizationServerActions";
import map from "lodash/map";
import { dropdown } from "../../utilities/helper";

const { Option } = Select;
const AddOrganizationServerForm = ({
  toggalModal,
  initialValues,
  edit,
  onSucessView,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [dbSelected, setDbSelected] = useState(false);
  const [ApiSelected, setApiSelected] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const Obj = {
    name: "ServerName",
    environment: "ServerEnviroment",
  };

  const selectionType = (value) => {
    if (value === "DATABASE_TYPE") {
      setDbSelected(true);
      setApiSelected(false);
    } else if (value === "API_TYPE") {
      setApiSelected(true);
      setDbSelected(false);
    }
  };

  const onFinish = (value) => {
    for (var key in Obj) {
      value[`${key}`] = value[Obj[key]];
      delete value[Obj[key]];
    }
    if (edit) {
      const payload = {
        ...value,
        did: id,
        cid: get(initialValues, "id", 0),
      };
      editOrganizationsServers(payload);
    } else {
      const payload = { ...value, did: id };
      AddOrganizationsServers(payload);
    }
  };

  const AddOrganizationsServers = (payload) => {
    setLoading(true);
    dispatch(createOrganizationsServer(payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.CREATE_SERVER);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editOrganizationsServers = (payload) => {
    setLoading(true);
    dispatch(updateOrganizationsServer(payload))
      .then(() => {
        message.success(MESSAGES.EDIT_SERVER);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (edit) {
      const val = form.getFieldValue("connection_type");
      if (val === "DATABASE_TYPE") {
        setDbSelected(true);
      } else {
        setApiSelected(true);
      }
    }
  }, [edit]);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                ServerEnviroment: get(initialValues, "environment", ""),
                ServerName: get(initialValues, "name", ""),
              }
            : {}
        }
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              {edit && (
                <Col xl={8} xs={8}>
                  <Form.Item name="id" label="ID">
                    <Input className="id-input" size="large" disabled />
                  </Form.Item>
                </Col>
              )}
              <Col xl={8} xs={8}>
                <Form.Item
                  name="ServerName"
                  label="Name"
                  rules={VALIDATIONS.SERVER_NAME_VALIDATIONS}
                >
                  <Input placeholder="" size="large" />
                </Form.Item>
              </Col>
              <Col xl={8} xs={8}>
                <Form.Item
                  name="ServerEnviroment"
                  label="Environment"
                  rules={VALIDATIONS.SERVER_ENVIRONMENT_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>

              <Col xl={8} xs={8}>
                <Form.Item
                  name="connection_type"
                  label="Connection Type"
                  rules={VALIDATIONS.SERVER_CONNECTION_TYPE}
                >
                  <Select
                    size="large"
                    optionFilterProp="children"
                    onSelect={selectionType}
                  >
                    {map(dropdown, (rt) => (
                      <Option key={get(rt, "value")} value={get(rt, "value")}>
                        {get(rt, "name")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {dbSelected && (
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="ip"
                    label="Server IP Address"
                    rules={VALIDATIONS.SERVER_IP_VALIDATIONS}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                </Col>
              )}
              {dbSelected && (
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="db_name"
                    label="Database Name"
                    rules={VALIDATIONS.SERVER_DB_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              )}
              {ApiSelected && (
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="api_server"
                    label="API Server"
                    rules={VALIDATIONS.API_SERVER_VALIDATIONS}
                  >
                    <Input placeholder="" size="large" />
                  </Form.Item>
                </Col>
              )}
              {ApiSelected && (
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="auth_server"
                    label="Auth Server"
                    rules={VALIDATIONS.AUTH_SERVER_VALIDATIONS}
                  >
                    <Input size="large" className="non-required" />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddOrganizationServerForm;
