import { Button, Dropdown, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AfterLoginLogo from "../../assets/images/AfterLoginLogo.png";
import { useEffect, useState } from "react";
import { getKey } from "../../utilities/layout";
import meIcon from "../../assets/svg/meIcon.svg";
import * as ROUTES from "../../constants/routes";
import * as IDS from "../../constants/data/BasicData";
import { get } from "lodash";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const LayoutHeader = () => {
  const [selectedKeys, setSelectedKeys] = useState("/batch-management");
  let location = useLocation();
  let navigate = useNavigate();

  const pathname = location.pathname;
  const userName = useSelector((state) => get(state, "auth.user", ""));
  const role_code = useSelector((state) => get(state, "auth.role_code"));

  const onClickLogout = () => {
    navigate(ROUTES.LOGOUT);
  };

  const userMenu = () => {
    const items = [
      {
        key: "1",
        label: (
          <Button
            type="text"
            onClick={() => navigate(ROUTES.ACCOUNT_INFORMATION)}
            style={{ width: "100%" }}
          >
            Account Information
          </Button>
        ),
      },
      {
        key: "1",
        label: (
          <Button
            type="text"
            onClick={() => onClickLogout()}
            style={{ width: "100%" }}
          >
            Logout
          </Button>
        ),
      },
    ];
    return items;
  };

  const menuItems = () => {
    const items = [
      {
        key: "1",
        label: <Link to={ROUTES.BATCH_MANAGEMENT}>Batch Management </Link>,
        className: "modified",
      },
      {
        key: "5",
        label: (
          <Link to={ROUTES.DISTRIBUTOR_MANAGEMENT}>Distributor Management</Link>
        ),
        className: "modified",
      },
      {
        key: "2",
        label: (
          <Link to={ROUTES.MANUFACTURER_MANAGEMENT}>
            Manufacturer Management
          </Link>
        ),
        className: "modified",
      },
      {
        key: "3",
        label: (
          <Link to={ROUTES.ORGANISATION_MANAGEMENT}>
            Organization Management
          </Link>
        ),
        className: "modified",
      },
      {
        key: "6",
        label: (
          <Link to={ROUTES.SYSTEM_CONFIGURATION}>System Configuration</Link>
        ),
        className: "modified",
      },
    ];

    if (role_code && role_code !== IDS.ROLE_STANDERED_USER) {
      items.splice(4, 0, {
        key: "4",
        label: <Link to={ROUTES.USER_MANAGEMENT}>User Management</Link>,
        className: "modified",
      });
    }

    return items;
  };

  useEffect(() => {
    setSelectedKeys(getKey(pathname));
  }, [pathname]);

  return (
    <Header className="layout-header">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="navbar-logo">
          <img src={AfterLoginLogo} alt="" />
          <h1 className="header-divider">|</h1>
          <h1 className="navbar-heading-master">Connect Master</h1>
        </div>
        <div className="name-logo-container">
          <p
            className="name-container"
            style={{ lineHeight: "24px", fontSize: "16px" }}
          >
            Hi, {userName}
          </p>
          <Dropdown menu={{ items: userMenu() }}>
            <Button type="text">
              <img src={meIcon} alt="" />
            </Button>
          </Dropdown>
        </div>
      </div>
      <Menu
        className="layout-menu-container"
        theme="dark"
        mode="horizontal"
        style={{ background: "black" }}
        defaultSelectedKeys={["1"]}
        selectedKeys={[selectedKeys]}
        items={menuItems()}
        overflowedIndicator={<MenuOutlined />}
        overflowedIndicatorPopupClassName="layout-submenu-container"
      ></Menu>
    </Header>
  );
};

export default LayoutHeader;
