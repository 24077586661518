import get from "lodash/get";
import * as TYPES from "../../constants/actionTypes";

const initialState = {
  pendingBatchSorter: null,
  editPendingBatchSorter: null,
  sentBatchSorter: null,
  editSentBatchSorter: null,
  editArchiveBatchSorter: null,
};

const batchSorterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PENDING_BATCH_SORTER:
      return {
        ...state,
        pendingBatchSorter: get(action, "payload", {}),
      };
    case TYPES.EDIT_PENDING_BATCH_SORTER:
      return {
        ...state,
        editPendingBatchSorter: get(action, "payload", {}),
      };
    case TYPES.SENT_BATCH_SORTER:
      return {
        ...state,
        sentBatchSorter: get(action, "payload", {}),
      };
    case TYPES.EDIT_SENT_BATCH_SORTER:
      return {
        ...state,
        editSentBatchSorter: get(action, "payload", {}),
      };
    case TYPES.ARCHIVE_BATCH_SORTER:
      return {
        ...state,
        archiveBatchSorter: get(action, "payload", {}),
      };
    case TYPES.EDIT_ARCHIVE_BATCH_SORTER:
      return {
        ...state,
        editArchiveBatchSorter: get(action, "payload", {}),
      };
    default:
      return state;
  }
};

export default batchSorterReducer;
