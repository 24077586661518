import { Spin, Row, Col, Form, Input, message } from "antd";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../../constants/routes";
import { fetchBatchItemDetails } from "../../../redux/actions/allBatchActions/batchEditPendingActions";
import * as VALIDATIONS from "../../../constants/data/validations/batchManagementValidations/EditBatchFormValidations";
import blankImageLogo from "../../../assets/images/blankImageLogo.jpg";

const EditSentBatchItemForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const idArr = location.search.split("");
  idArr.shift();
  const newArray = idArr.join("").split("+");
  const batchId = newArray[0];
  const batchRecordNumber = newArray[1];

  const getBatchItemDetails = (batchId, batchRecordNo) => {
    setLoading(true);
    dispatch(fetchBatchItemDetails(batchId, batchRecordNo))
      .then((result) => {
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setInitialDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBatchItemDetails(batchId, batchRecordNumber);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialDetails]);

  const props = {
    name: "file",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Batch Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(`${ROUTES.EDIT_SENT_BATCHES}?${batchId}`)}
            />
          </div>
          <h2
            className="sub-title arrow-sub-title-alingment"
            style={{ marginBottom: 30 }}
          >
            Batch Item
          </h2>
        </div>

        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          initialValues={{
            ...initialDetails,
            Manufacturer: get(initialDetails, "Manufacturer.name", ""),
          }}
        >
          {}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginLeft: 20 }}>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <div style={{ height: 200, width: 200, background: "grey" }}>
                    <img
                      height={200}
                      width={200}
                      src={
                        get(initialDetails, "item_image_url", "")
                          ? get(initialDetails, "item_image_url", "")
                          : blankImageLogo
                      }
                      alt=""
                    />
                  </div>
                  <Row gutter={16}>
                    <Col xl={24} xs={24}>
                      <Form.Item name="name"></Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xl={24} xs={12}></Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="batch-item-input">
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="batch_record_no"
                        label="Batch Record No."
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="vendor_number" label="Vendor Number">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="vendor_name" label="Vendor Name">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="vendor_part_num"
                        label="Vendor Part Number"
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="description" label="Description">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="Manufacturer" label="Manufacturer">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="price"
                        label={`Price (in ${currencyCode})`}
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="color" label="Color">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="upc_code" label="UPC">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="uom" label="UOM">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="category_name" label="Category Name">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>

                    <Col xl={12} xs={12}>
                      <Form.Item name="category_code" label="Category Code">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="subcategory_name"
                        label="Sub Category Name"
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="subcategory_code"
                        label="Sub Category Code"
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="cutsheet_url"
                        label="Cutsheet URL"
                        rules={VALIDATIONS.BATCH_CUTSHEET_URL_VALIDATIONS}
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default EditSentBatchItemForm;
