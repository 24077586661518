import { Tabs } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArchivedTableContainer from "../components/batchTableContainer/ArchivedTableContainer";
import PendingTableContainer from "../components/batchTableContainer/PendingTableContainer";
import { fetchAllDistributorsList } from "../redux/actions/distributorActions";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../utilities/helper";
import { fetchAllActiveOrganization } from "../redux/actions/organizationActions";

// const { TabPane } = Tabs;

const BatchManagementScreen = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKeys] = useState("pending");
  const [distributorList, setDistributorList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const dispatch = useDispatch();

  const tabChanged = (activeKey) => {
    setSelectedKeys(activeKey);
  };

  useEffect(() => {
    const { tab } = parseQueryString(location.search);
    if (tab) tabChanged(tab);
  }, [location]);

  // funtion to call distributor list
  const getAllDistributorsList = () => {
    dispatch(fetchAllDistributorsList())
      .then((result) => {
        setDistributorList(get(result, "data", []));
      })
      .catch((error) => {});
  };

  // function to call all organizations
  const getAllOrganizationsList = () => {
    dispatch(fetchAllActiveOrganization()).then(({ data }) => {
      setOrganizationList(data);
    });
  };

  const newSelectkey = localStorage.getItem("selected");

  useEffect(() => {
    getAllDistributorsList();
    getAllOrganizationsList();
    if (newSelectkey) {
      setSelectedKeys(newSelectkey);
    }
  }, []);

  return (
    <>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Batch Management</h1>
        </div>

        <div className="card-container">
          <Tabs
            type="card"
            onChange={tabChanged}
            activeKey={selectedKey}
            destroyInactiveTabPane
            items={[
              {
                key: "pending",
                label: <p>Pending</p>,
                children: (
                  <PendingTableContainer
                    selectedKey={selectedKey}
                    distributorList={distributorList}
                    organizationList={organizationList}
                  />
                ),
              },
              {
                key: "archived",
                label: <p>Archive</p>,
                children: (
                  <ArchivedTableContainer
                    selectedKey={selectedKey}
                    distributorList={distributorList}
                  />
                ),
              },
            ]}
          >
            {/* <TabPane tab={<p>Sent</p>} key="2">
              <SentTableContainer selectedKey={selectedKey} distributorList={distributorList} />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default BatchManagementScreen;
