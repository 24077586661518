import * as TYPES from "../../constants/actionTypes";

export const editPendingBatchFilterDetails =
  (payload) => (dispatch, getState, api) => {};

export const sentBatchFilterDetails =
  (payload) => (dispatch, getState, api) => {};
export const editSentBatchFilterDetails =
  (payload) => (dispatch, getState, api) => {};
export const archiveBatchFilterDetails =
  (payload) => (dispatch, getState, api) => {};
export const editArchiveBatchFilterDetails =
  (payload) => (dispatch, getState, api) => {};

export const clearBatchFilter = (payload) => (dispatch, getState, api) => {};
