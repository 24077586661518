import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import EmailScreen from "../screens/forgotPasswordScreen/EmailScreen";
import NewPasswordScreen from "../screens/forgotPasswordScreen/NewPasswordScreen";
import LoginScreen from "../screens/LoginScreen";
import LogoutScreen from "../screens/LogoutScreen";
import * as ROUTES from "../constants/routes";
import ErrorScreen from "../screens/ErrorScreen";
import { useSelector } from "react-redux";
import get from "lodash/get";
import AddManufacturerForm from "../components/forms/AddManufacturerForm";
import EditManufacturerForm from "../components/forms/EditManufacturerForm";
import OrganizationManagementScreen from "../screens/OrganizationManagementScreen";
import ManufacturerScreen from "../screens/ManufacturerScreen";
import UserScreen from "../screens/UserScreen";
import BatchManagementScreen from "../screens/BatchManagementScreen";
import AccountInfoScreen from "../screens/AccountInfoScreen";
import DistributorScreen from "../screens/DistributorScreen";
import EditDistributorForm from "../components/forms/EditDistributorForm";
import SystemConfigurationScreen from "../screens/SystemConfigurationScreen";
import AddDistributorForm from "../components/forms/AddDistributorForm";
import EditOrganizationForm from "../components/forms/EditOrganizationForm";
import AddOrganizationForm from "../components/forms/AddOrganizationForm";
import * as IDS from "../constants/data/BasicData";
import EditPendingBatchScreen from "../screens/EditPendingBatchScreen";
import EditBatchItemForm from "../components/forms/batchForms/EditBatchItemForm";
import EditSentBatchScreen from "../screens/EditSentBatchScreen";
import EditArchiveBatchScreen from "../screens/EditArchiveBatchScreens";
import EditSentBatchItemForm from "../components/forms/batchForms/EditSentBatchItemForm";
import EditArchivedBatchItemForm from "../components/forms/batchForms/EditArchivedBatchItemForm";

const AuthRoutes = () => {
  const [resetPassword, setResetPassword] = useState(false);
  const isLoggedIn = useSelector((state) => get(state, "auth.isLoggedIn"));
  const role_code = useSelector((state) => get(state, "auth.role_code"));

  const checkUser = () => {
    if (isLoggedIn) {
      if (role_code === IDS.ROLE_STANDERED_USER) {
        return <Navigate to={ROUTES.PAGE_NOT_FOUND_ROUTES} />;
      } else {
        return <UserScreen />;
      }
    } else {
      return <Navigate to={ROUTES.LOGIN} />;
    }
  };
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<LoginScreen setResetPassword={setResetPassword} />}
      />
      <Route
        exact
        path={ROUTES.LOGIN}
        element={<LoginScreen setResetPassword={setResetPassword} />}
      />
      <Route exact path={ROUTES.LOGOUT} element={<LogoutScreen />} />
      <Route
        exact
        path={ROUTES.FORGOT_PASSWORD_EMAIL}
        element={<EmailScreen />}
      />
      <Route
        exact
        path={ROUTES.RESET_PASSWORD_NEWPASSWORD}
        element={<NewPasswordScreen resetPassword={resetPassword} />}
      />

      <Route
        exact
        path={ROUTES.ACCOUNT_INFORMATION}
        element={
          isLoggedIn ? <AccountInfoScreen /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.BATCH_MANAGEMENT}
        element={
          isLoggedIn ? (
            <BatchManagementScreen />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route exact path={ROUTES.USER_MANAGEMENT} element={checkUser()} />
      <Route
        exact
        path={ROUTES.MANUFACTURER_MANAGEMENT}
        element={
          isLoggedIn ? <ManufacturerScreen /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.ORGANISATION_MANAGEMENT}
        element={
          isLoggedIn ? (
            <OrganizationManagementScreen />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route
        exact
        path={ROUTES.ADD_MANUFACTURER}
        element={
          isLoggedIn ? <AddManufacturerForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_MANUFACTURER}
        element={
          isLoggedIn ? <EditManufacturerForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.DISTRIBUTOR_MANAGEMENT}
        element={
          isLoggedIn ? <DistributorScreen /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_DISTRIBUTOR}
        element={
          isLoggedIn ? <EditDistributorForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.ADD_DISTRIBUTOR}
        element={
          isLoggedIn ? <AddDistributorForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_ORGANIZATION}
        element={
          isLoggedIn ? <EditOrganizationForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.ADD_ORGANIZATION}
        element={
          isLoggedIn ? <AddOrganizationForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_PENDING_BATCHES}
        element={
          isLoggedIn ? (
            <EditPendingBatchScreen />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_SENT_BATCHES}
        element={
          isLoggedIn ? <EditSentBatchScreen /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_ARCHIVED_BATCHES}
        element={
          isLoggedIn ? (
            <EditArchiveBatchScreen />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_PENDING_BATCH_ITEM}
        element={
          isLoggedIn ? <EditBatchItemForm /> : <Navigate to={ROUTES.LOGIN} />
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_SENT_BATCH_ITEM}
        element={
          isLoggedIn ? (
            <EditSentBatchItemForm />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route
        exact
        path={ROUTES.EDIT_ARCHIVED_BATCH_ITEM}
        element={
          isLoggedIn ? (
            <EditArchivedBatchItemForm />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />

      <Route
        exact
        path={ROUTES.SYSTEM_CONFIGURATION}
        element={
          isLoggedIn ? (
            <SystemConfigurationScreen />
          ) : (
            <Navigate to={ROUTES.LOGIN} />
          )
        }
      />
      <Route path={ROUTES.PAGE_NOT_FOUND_ROUTES} element={<ErrorScreen />} />
    </Routes>
  );
};
export default AuthRoutes;
