import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import { get, map } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchPendingWithVendorPartBatchItems,
  fetchPendingWithVendorPartBatchItemsIdsList,
  voidAll,
} from "../../redux/actions/allBatchActions/batchEditPendingActions";
import { updatePendingVendorPartErroredItems } from "../../redux/actions/allBatchActions/dataRectificationActions";
import * as MESSAGES from "../../constants/messages";
import { addDollar, formatedDate } from "../../utilities/helper";

const { Option } = Select;
const { Panel } = Collapse;

function VendorPartMissingCorousal({
  getErroredVendorPartList,
  distributorManufacturer,
  erroredVendorPartList,
  pendingBatchId,
  pendingBatchDetails,
  onClose,
  onRefresh,
  hidden,
  ...props
}) {
  const [open, setOpen] = useState("");
  const [list, setList] = useState({});
  const [total, setTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [voidChecksManufacturer, setVoidChecksManufacturer] = useState({});
  const [voidChecksItem, setVoidChecksItem] = useState({});
  const [batchDataIds, setBatchDataIds] = useState({});
  const [vendorMapping, setVendorMapping] = useState({});
  const dispatch = useDispatch();

  function getVendorItems(key) {
    if (key.length) {
      getPendingBatchItems(pendingBatchId, {
        offset: 0,
        limit: 10,
        filters: {
          vendor_number: key,
        },
      });
      getPendingBatchItemsIdsList(pendingBatchId, key);
    }
    setOpen(key);
  }

  const getPendingBatchItemsIdsList = (id, vendor_number) => {
    dispatch(fetchPendingWithVendorPartBatchItemsIdsList(id, vendor_number))
      .then((result) => {
        setBatchDataIds(result);
      })
      .catch((error) => {});
  };
  const getPendingBatchItems = (id, payload) => {
    setList({});
    setTotal(get({}, "total", 0));
    setLoading(true);
    dispatch(
      fetchPendingWithVendorPartBatchItems(
        id,
        payload.filters.vendor_number,
        payload,
        true
      )
    )
      .then((result) => {
        setLoading(false);
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setList(result);
        setTotal(get(result, "total", 0));
        if (result.total === 0) getErroredVendorPartList(pendingBatchId);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onFinish = (obj) => {
    const vendor = [];

    for (let vendorKey in vendorMapping) {
      for (let key in vendorMapping[vendorKey]) {
        const newObj = {
          batch_record_no: +key,
          vendor_part_num: vendorMapping[vendorKey][key],
        };
        if (
          vendorMapping[vendorKey][key] &&
          vendorMapping[vendorKey][key].trim().length
        )
          vendor.push(newObj);
      }
    }

    updateErroredBatchItems(pendingBatchId, {
      data: vendor.length ? vendor : undefined,
    });
  };

  const updateErroredBatchItems = (id, payload) => {
    setLoading(true);
    let reqData = { ...payload, void_vendor: [], void_item: [] };

    if (Object.keys(voidChecksManufacturer)?.length > 0) {
      for (let key in voidChecksManufacturer) {
        if (voidChecksManufacturer[key]) {
          reqData.void_vendor.push(key);
        }
      }
    }

    if (Object.keys(voidChecksItem)?.length > 0) {
      for (let itemKey in voidChecksItem) {
        if (voidChecksItem[itemKey])
          Object.keys(voidChecksItem[itemKey]).forEach(function (key) {
            if (voidChecksItem[itemKey][key]) reqData.void_item.push(+key);
          });
      }
    }

    if (!reqData.void_item.length) delete reqData.void_item;
    if (!reqData.void_vendor.length) delete reqData.void_vendor;

    if (reqData.data === undefined && !(Object.keys(reqData).length > 1)) {
      setLoading(false);
      message.warning("Please fill the Vendor Part Number(s)");
      return;
    }
    dispatch(updatePendingVendorPartErroredItems(id, reqData))
      .then((result) => {
        setLoading(false);
        if (reqData.data)
          message.success(MESSAGES.UPDATE_SECOND_CAROUSEL_MESSAGE);
        if (reqData.void_vendor || reqData.void_item)
          message.success(MESSAGES.VOID_SUCCESSFUL_MESSAGE);
        setVoidChecksManufacturer({ ...voidChecksManufacturer, [open]: false });
        setVoidChecksItem({ ...voidChecksItem, [open]: {} });
        setVendorMapping({ ...vendorMapping, [open]: {} });

        if (open) {
          getPendingBatchItems(pendingBatchId, {
            offset: 0,
            limit: 10,
            filters: {
              vendor_number: open,
            },
          });

          getPendingBatchItemsIdsList(pendingBatchId, open);
        }

        getErroredVendorPartList(pendingBatchId);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const manageVoidItemCheckbox = (value, type = null, vendorNum) => {
    if (type === "ALL") {
      setVoidChecksManufacturer({
        ...voidChecksManufacturer,
        [vendorNum]: !!value,
      });
      setVoidChecksItem({ ...voidChecksItem, [vendorNum]: {} });
    } else {
      if (voidChecksManufacturer[vendorNum]) {
        let voidedChecked = {};
        get(batchDataIds, "data", []).forEach(function (id) {
          voidedChecked[id] = true;
        });
        setVoidChecksManufacturer({
          ...voidChecksManufacturer,
          [vendorNum]: false,
        });
        setVoidChecksItem({
          ...voidChecksItem,
          [vendorNum]: {
            ...voidChecksItem[vendorNum],
            ...voidedChecked,
            [type]: !!value,
          },
        });
      } else {
        setVoidChecksItem({
          ...voidChecksItem,
          [vendorNum]: { ...voidChecksItem[vendorNum], [type]: !!value },
        });
      }
    }
  };

  const handleChange = (pagination, record, sorting) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let ListParams = {
      offset,
      limit,
      sortBy: sorting.order ? sorting.field : undefined,
      sortDir: sorting.order
        ? sorting.order === "ascend"
          ? "ASC"
          : "DESC"
        : undefined,
      filters: { vendor_number: open },
    };

    getPendingBatchItems(pendingBatchId, ListParams);
  };
  const manageVendorMapping = (item, value, vendorNum) => {
    setVendorMapping({
      ...vendorMapping,
      [vendorNum]: {
        ...(vendorMapping[vendorNum] ? vendorMapping[vendorNum] : {}),
        [item]: value,
      },
    });
  };

  const batchListColumns = ({ currencyCode, vendorNum }) => [
    {
      title: "Void",
      key: "void",
      width: 80,
      render: (value, record) => (
        <Checkbox
          checked={
            voidChecksManufacturer[open] ||
            (voidChecksItem[open] &&
              voidChecksItem[open][record.batch_record_no])
          }
          onChange={(e) =>
            manageVoidItemCheckbox(
              e.target.checked,
              record.batch_record_no,
              vendorNum
            )
          }
        />
      ),
    },
    {
      title: "Vendor Part No.",
      dataIndex: "vendor_part_num",
      key: "batch_record_no",
      render: (value, record) => (
        <Form.Item
          name={["batch_item", `${record.batch_record_no}`]}
          style={{ marginBottom: 0 }}
        >
          <Input
            placeholder={"Vendor Part Number"}
            size={"small"}
            onChange={(e) => {
              manageVendorMapping(
                record.batch_record_no,
                e.target.value,
                vendorNum
              );
              e.stopPropagation();
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Record No.",
      dataIndex: "batch_record_no",
      key: "batch_record_no",
      sorter: true,
      width: 110,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        get(list, "sortBy") === "batch_record_no"
          ? get(list, "sortDir") === "DESC"
            ? "descend"
            : "ascend"
          : false,
    },
    {
      title: "Item No.",
      dataIndex: "item_num",
      key: "item_num",
      align: "right",
      ellipsis: true,
    },
    {
      title: "Vendor No.",
      dataIndex: "vendor_number",
      key: "vendor_number",
      sorter: true,
      ellipsis: true,
      sortOrder:
        get(list, "sortBy") === "vendor_number"
          ? get(list, "sortDir") === "DESC"
            ? "descend"
            : "ascend"
          : false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      // sorter: true,
      width: 150,
      ellipsis: true,
      // sortOrder : get(list, 'sortBy') === "vendor_name" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
      // sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (text) => addDollar(text, currencyCode),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Sub Category",
      dataIndex: "subcategory_name",
      key: "subcategory_name",

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Publish Date",
      dataIndex: "create_date",
      key: "create_date",
      render: (date) => formatedDate(date),
    },
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      key: "effective_date",
      render: (date) => formatedDate(date),
    },
  ];

  const voidAllList = () => {
    setLoading(true);

    dispatch(voidAll(pendingBatchId))
      .then((result) => {
        setLoading(false);
        if (get(result, "success")) {
          message.success(MESSAGES.VOID_SUCCESSFUL_MESSAGE);
          getErroredVendorPartList(pendingBatchId);
        }
      })
      .catch((result) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="">
        <Row>
          <Col>
            <div className="warning-text">
              <h3>
                <b>Missing Vendor Part Number Assignment </b>
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form onFinish={onFinish}>
              <Collapse
                className="collapse-style"
                activeKey={open}
                onChange={getVendorItems}
                accordion
              >
                {map(get(erroredVendorPartList, "data", []), (el) => (
                  <Panel
                    header={
                      <Row>
                        <Col>
                          Void{" "}
                          <Checkbox
                            onChange={(e) =>
                              manageVoidItemCheckbox(
                                e.target.checked,
                                "ALL",
                                el.vendor_number
                              )
                            }
                            checked={voidChecksManufacturer[el.vendor_number]}
                          />{" "}
                          Vendor Name: {el.vendor_name} | Vendor Number:{" "}
                          {el.vendor_number}
                        </Col>
                      </Row>
                    }
                    key={el.vendor_number}
                  >
                    <Table
                      loading={loading}
                      size="small"
                      showSorterTooltip={false}
                      rowKey="batch_record_no"
                      scroll={{ x: 1500 }}
                      dataSource={get(list, "data", [])}
                      key={el.vendor_number}
                      columns={batchListColumns({
                        currencyCode,
                        vendorNum: el.vendor_number,
                      })}
                      onChange={handleChange}
                      pagination={{
                        current: +(get(list, "offset", 0) / 10) + 1,
                        total: get(list, "total", 0),
                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                        showSizeChanger: true,
                        onChange: (pageNum) => setPaginationPage(pageNum),
                      }}
                    />
                  </Panel>
                ))}
              </Collapse>
            </Form>
            <Pagination
              style={{ marginTop: 8, float: "right" }}
              total={get(erroredVendorPartList, "total", 0)}
              pageSize={10}
              current={+(get(erroredVendorPartList, "offset", 0) / 10) + 1}
              onChange={(page) =>
                getErroredVendorPartList(pendingBatchId, {
                  offset: (page - 1) * 10,
                })
              }
            />
          </Col>
        </Row>

        <Row gutter={16} justify={"center"}>
          <Col>
            <Button
              type={"primary"}
              onClick={voidAllList}
              className="btn-orange"
            >
              Void All
            </Button>
          </Col>
          <Col>
            <Button type={"primary"} onClick={onFinish} className="btn-orange">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default VendorPartMissingCorousal;
