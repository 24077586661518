export const columns = () => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Server IP",
    dataIndex: "ip",
    key: "ip",
    render: (text,record) => {
      if (record.connection_type !== "DATABASE_TYPE") {
        return record.api_server;
      } else return text;
    },
  },
  {
    title: "Environment",
    dataIndex: "environment",
    key: "environment",
  },
];
