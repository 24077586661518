import { Layout, Form, Input, Button, DatePicker } from "antd";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBatchFilter,
  editSentBatchFilterDetails,
} from "../../../../redux/actions/batchFilterActions";

function EditSentBatchFilterForm({
  form,
  setPaginationPage,
  getSentBatchItems,
  id,
}) {
  const dispatch = useDispatch();
  const editSentBatchSorter = useSelector((state) =>
    get(state, "batchSorter.editSentBatchSorter", [])
  );

  const onFinish = (values) => {
    if (values["create_date"]) {
      values["create_date"] = values["create_date"].format("YYYY-MM-DD");
    }
    if (values["effective_date"]) {
      values["effective_date"] = values["effective_date"].format("YYYY-MM-DD");
    }

    for (var key in values) {
      if (get(values[key], "length", 0) === 0 && key !== "distributor_id") {
        delete values[`${key}`];
      }
    }

    if (values["batch_record_no"]) {
      values["batch_record_no"] = Number(values["batch_record_no"]);
    }

    const payload = {
      ...editSentBatchSorter,
      filters: values,
      offset: 0,
      limit: 10,
    };

    dispatch(editSentBatchFilterDetails({ filters: values }));
    getSentBatchItems(id, payload);
    setPaginationPage(1);
  };
  return (
    <Layout className="filter-form" style={{ marginTop: -16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <div>
          <p className="form-label">Batch Record No.</p>
          <Form.Item name="batch_record_no">
            <Input />
          </Form.Item>
        </div>
        <div>
          <p className="form-label">Vendor No.</p>
          <Form.Item name="vendor_number">
            <Input />
          </Form.Item>
        </div>
        <div className="datepicker">
          <p className="form-label">Publish Date</p>
          <Form.Item name="create_date">
            <DatePicker placeholder={false} size="medium" />
          </Form.Item>
        </div>

        <div className="datepicker">
          <p className="form-label">Effective Date</p>
          <Form.Item name="effective_date">
            <DatePicker size="medium" placeholder={false} />
          </Form.Item>
        </div>

        <Form.Item>
          <Button htmlType="submit" className="add-btn">
            Apply
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="cancel-btn"
            onClick={() => {
              form.resetFields();
              getSentBatchItems(id, {
                ...editSentBatchSorter,
                offset: 0,
                limit: 10,
                filters: {},
              });
              setPaginationPage(1);
              dispatch(clearBatchFilter());
            }}
          >
            Clear
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}

export default EditSentBatchFilterForm;
