import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Spin,
  Switch,
  message,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSyncConnector,
  updateSyncConnector,
} from "../../redux/actions/syncConnectorAction";
import * as VALIDATIONS from "../../constants/data/validations/SyncConnectorsValidation";
import get from "lodash/get";
import SingleImageUplaod from "../common/imageUpload";

const AddNewSyncConnectorForm = ({ modal, setModal, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [image, setImage] = useState({
    file: null,
    src: get(modal, "data") ? get(modal, "data.image_url") : null,
  });

  const addSyncConnector = (payload) => {
    setLoading(true);
    dispatch(createSyncConnector(payload, image))
      .then((result) => {
        setLoading(false);
        message.success(get(result, "message", ""));
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editSyncConnector = (payload, id) => {
    setLoading(true);
    dispatch(updateSyncConnector(payload, id, image))
      .then((result) => {
        setLoading(false);
        message.success(get(result, "message", ""));
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    get(modal, "data")
      ? editSyncConnector(values, get(modal, "data.id"))
      : addSyncConnector(values);
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
        initialValues={
          get(modal, "data")
            ? {
                name: get(modal, "data.name"),
                is_active: get(modal, "data.is_active"),
                max_spn_length: get(modal, "data.max_spn_length"),
                cpn_can_change: get(modal, "data.cpn_can_change"),
              }
            : { is_active: false, cpn_can_change: false }
        }
      >
        <Row gutter={16}>
          <Col xl={12} xs={12}>
            <Row>
              <Form.Item
                name="name"
                label="Connector Type Name"
                rules={VALIDATIONS.NAME_VALIDATIONS}
              >
                <Input size="large" />
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                label="Max SPN Length"
                name="max_spn_length"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Max SPN Length",
                  },
                  {
                    min: get(modal, "data")
                      ? parseInt(get(modal, "data.max_spn_length"))
                      : 1,
                    type: "number",
                    message:
                      "New Max SPN Length must be greater than or equal to current Max SPN Length",
                  },
                ]}
              >
                <InputNumber size="large" max={100} />
              </Form.Item>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  name="is_active"
                  label="Active"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={false} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="cpn_can_change"
                  label="CPN Editable"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={false}
                    disabled={!!get(modal, "data")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xl={12} xs={12}>
            <Form.Item label="Image">
              <SingleImageUplaod
                setLoading={setLoading}
                setImage={setImage}
                image={image}
                remove={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="button-wrapper">
          <Form.Item label="">
            <Button
              htmlType="submit"
              type="primary"
              className="add-user-button"
              size="large"
            >
              {get(modal, "data") ? "UPDATE" : "ADD"}
            </Button>
          </Form.Item>

          <Form.Item label="">
            <Button
              className="cancel-btn sync-connector"
              onClick={() =>
                setModal({
                  visible: false,
                  data: null,
                })
              }
              size="large"
              type="primary"
            >
              CANCEL
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default AddNewSyncConnectorForm;
