import {
  Button,
  Col,
  Form,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ManufacturerFilterForm from "../components/forms/ManufacturerFilterForm";
import { columns } from "../constants/data/manufacturerTable";
import {
  fetchAllManufacturer,
  updateManufacturer,
} from "../redux/actions/manufacturerActions";
import * as MESSAGES from "../constants/messages";
import * as ROUTES from "../constants/routes";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../redux/actions/globalReducActions";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { TableDropdown } from "../components/common/TableDropDown";

function ManufacturerScreen() {
  const [loading, setLoading] = useState(false);
  const [manufacturerData, setManufacturerData] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const tablePaginationParams = useSelector(
    (state) => get(state, "global.globalPagesPagination")[PAGES.MANUFACTURER]
  );
  const activeFilters = useSelector(
    (state) => get(state, "global.globalPagesFilterData")[PAGES.MANUFACTURER]
  );
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(PAGES.MANUFACTURER, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.MANUFACTURER, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(globalPagesFilterData(PAGES.MANUFACTURER, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.MANUFACTURER, param));
    }
  };
  useEffect(() => {
    getManufacturerList();
  }, [tablePaginationParams]);
  const onClickEdit = (record) => {
    const id = get(record, "id", 0);
    navigate({
      pathname: ROUTES.EDIT_MANUFACTURER,
      search: `${id}`,
    });
  };

  const onChangeHandler = (record) => {
    const payload = { is_active: !get(record, "is_active", false) };
    toggleActive(payload, get(record, "id", 0));
  };

  const getManufacturerList = () => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchAllManufacturer(params))
      .then((result) => {
        setManufacturerData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const toggleActive = (payload, id) => {
    setLoading(true);
    dispatch(updateManufacturer(payload, id))
      .then(() => {
        message.success(MESSAGES.ACTIVE_UPDATE_MESSAGE);
        setLoading(false);
        getManufacturerList();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };

  useEffect(() => {
    getManufacturerList();
  }, []);

  return (
    <div className="main-wrapper-div drawer">
      <div>
        <h1 className="title">Manufacturer Management </h1>
      </div>
      <div className="add-button-container">
        <Button
          className="main-button"
          size="large"
          onClick={() => {
            navigate(ROUTES.ADD_MANUFACTURER);
          }}
        >
          ADD MANUFACTURER
          <PlusOutlined />
        </Button>
      </div>

      <ManufacturerFilterForm
        form={form}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />

      <div className="user-table">
        <Table
          showSorterTooltip={false}
          rowKey="id"
          size="small"
          scroll={{ y: "calc(100vh - 430px)" }}
          dataSource={get(manufacturerData, "data")}
          columns={columns({ onClickEdit, onChangeHandler, manufacturerData })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          loading={loading}
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(manufacturerData, "offset", 0) + 1} to ${
                    get(manufacturerData, "data", []).length +
                    get(manufacturerData, "offset", "--")
                  } of ${get(
                    manufacturerData,
                    "total",
                    "--"
                  )} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(manufacturerData, "offset", 0) -
                          get(manufacturerData, "limit", 0),
                      })
                    }
                    disabled={!get(manufacturerData, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(manufacturerData, "offset", 0) +
                          get(manufacturerData, "limit", 0),
                      })
                    }
                    disabled={
                      get(manufacturerData, "offset", 0) +
                        get(manufacturerData, "limit", 0) >=
                      get(manufacturerData, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default ManufacturerScreen;
