import {Button, Form, Input, Layout, Select, Space} from "antd";
import get from "lodash/get";
import isBoolean from "lodash/isBoolean";
import {ActiveList} from "../../utilities/helper";
import map from "lodash/map";

const { Option } = Select;

const DistributorFilterForm = ({
                                 form, setActiveFilters,tablePaginationParams,activeFilters,setTablePaginationParams
}) => {

  return (
    <Layout className="filter-form">
      <Form
        form={form}
        layout="inline"
        onFinish= {() => setTablePaginationParams({
          ...tablePaginationParams,
          offset: 0,
          filters: { ...activeFilters },
        })}
      >
        <Space>
        <div>
          <p className="form-label">Distributor ID</p>
            <Input value={activeFilters.id}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, id: e.target.value ? e.target.value : undefined};
            })}/>
        </div>
        <div>
          <p className="form-label">Distributor Name</p>
            <Input value={activeFilters.name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, name: e.target.value ? e.target.value : undefined};
            })}/>
        </div>

        <div>
          <p className="form-label">Code</p>
            <Input value={activeFilters.code}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, code: e.target.value ? e.target.value : undefined};
            })}/>
        </div>

        <div>
          <p className="form-label">Status</p>
            <Select className="filter-select" size="medium" value={activeFilters.is_active}  onChange={(value) => setActiveFilters(prev => {
              return { ...prev, is_active: value !== null ? value : undefined};
            })}>
              {map(ActiveList, (at) => (
                <Option key={get(at, "key")} value={get(at, "value")}>
                  {get(at, "name")}
                </Option>
              ))}
            </Select>
        </div>

        <Form.Item>
          <Button className="add-btn" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
        <Form.Item>
          <Button className="cancel-btn"  onClick={() => {
            setActiveFilters({});
            setTablePaginationParams({ ...tablePaginationParams, filters: {} });
          }}>
            Clear
          </Button>
        </Form.Item>
        </Space>
      </Form>
    </Layout>
  );
};

export default DistributorFilterForm;
