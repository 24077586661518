export const NAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please Input Name" },
  {
    min: 3,
    message: "Name must contain at least 3 characters",
  },
  {
    max: 200,
    message: "Name must be less than 200 characters",
  },
];

export const CODE_VALIDATIONS = [
  {
    min: 3,
    message: "Please input 3 characters",
  },
  {
    max: 10,
    message: "Code must be less than 10 characters",
  },
  {
    pattern: new RegExp(/^[a-zA-Z0-9]+$/),
    whitespace: true,
    message: "Code must contain only alphanumeric characters",
  },
];

export const URL_VALIDATIONS = [
  {
    max: 100,
    message: "Url must be less than 100 characters",
  },
];
export const FTP_URL_VALIDATIONS = [
  {
    max: 100,
    message: "FTP Url must be less than 100 characters",
  },
];

export const CONTACT_NAME_VALIDATIONS = [
  {
    max: 200,
    message: "Name must be less than 200 characters",
  },
];

export const CONTACT_EMAIL_VALIDATIONS = [
  {
    type: "email",
    message: "Please input a valid Email",
  },
];

export const CONTACT_PHONE_VALIDATIONS = [
  {
    pattern: new RegExp(/^[0-9-+()]*$/),
    message: "Please input a valid Phone Number",
  },
  {
    max: 20,
    message: "Phone Number cannot exceed 20 characters ",
  },
];

export const DISTRIBUTOR_COMMENT_VALIDATIONS = [
  {
    max: 200,
    message: "Comment must be less than 200 characters",
  },
];
