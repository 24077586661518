import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Layout, Table, Modal } from "antd";
import get from "lodash/get";
import { columns } from "../constants/data/syncConnectorTable";
import AddNewSyncConnectorForm from "./forms/AddNewSyncConnectorForm";

function SyncConnectorTableContainer({
  setModal,
  modal,
  syncData,
  onSuccess,
  onChangeHandler,
  onClickEdit,
}) {
  return (
    <>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Sync Connector Type</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={() => {
              setModal({
                ...modal,
                visible: true,
              });
            }}
            size="large"
          >
            ADD NEW CONNECTOR TYPE
            <PlusOutlined />
          </Button>
        </div>
      </Layout>

      <div className="user-table">
        <Table
          size="small"
          rowKey="id"
          scroll={{ y: "calc(100vh - 430px)" }}
          dataSource={syncData}
          columns={columns({ onChangeHandler, onClickEdit })}
          pagination={{
            pageSize: [50],
          }}
        />
      </div>
      <Modal
        width="42%"
        title={
          get(modal, "data") ? (
            <h2>Edit Connector Type</h2>
          ) : (
            <h2>Add Connector Type</h2>
          )
        }
        open={get(modal, "visible")}
        onCancel={() =>
          setModal({
            visible: false,
            data: null,
          })
        }
        destroyOnClose
        footer={<></>}
      >
        <AddNewSyncConnectorForm
          modal={modal}
          setModal={setModal}
          onSuccess={onSuccess}
        />
      </Modal>
    </>
  );
}

export default SyncConnectorTableContainer;
