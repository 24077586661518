import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import { get, map } from "lodash";
import { useDispatch } from "react-redux";
import {
  fetchPendingBatchItems,
  fetchPendingWithVendorBatchItemsIdsList,
} from "../../redux/actions/allBatchActions/batchEditPendingActions";
import * as MESSAGES from "../../constants/messages";
import { useEffect, useState } from "react";
import { updatePendingBatchErroredItems } from "../../redux/actions/allBatchActions/dataRectificationActions";
import { addDollar, formatedDate } from "../../utilities/helper";
import AddManufacturerForm from "../forms/AddManufacturerForm";

const { Option } = Select;
const { Panel } = Collapse;

const SecondCarousel = ({
  distributorManufacturer,
  erroredVendorList,
  pendingBatchId,
  pendingBatchDetails,
  getErroredVendorList,
  onClose,
  onRefresh,
  hidden,
}) => {
  const [open, setOpen] = useState("");
  const [list, setList] = useState();
  const [total, setTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [voidChecksManufacturer, setVoidChecksManufacturer] = useState({});
  const [voidChecksItem, setVoidChecksItem] = useState({});
  const [batchDataIds, setBatchDataIds] = useState({});
  const [addManufacturerModal, setAddManufacturerModal] = useState({
    visible: false,
    data: null,
  });
  const [vendorMapping, setVendorMapping] = useState({});
  const [permanentVoid, setPermanentVoid] = useState([]);
  const dispatch = useDispatch();

  const contentStyle2 = {
    color: "black",
    padding: "8px",
  };

  function getVendorItems(key) {
    if (key.length) {
      getPendingBatchItems(pendingBatchId, {
        offset: 0,
        limit: 10,
        filters: {
          vendor_number: key,
        },
      });
      getPendingBatchItemsIdsList(pendingBatchId, key);
    }
    setOpen(key);
  }
  const getPendingBatchItemsIdsList = (id, vendor_number) => {
    dispatch(fetchPendingWithVendorBatchItemsIdsList(id, vendor_number))
      .then((result) => {
        setBatchDataIds(result);
      })
      .catch((error) => {});
  };
  const updateErroredBatchItems = (id, payload) => {
    setLoading(true);
    let reqData = {
      ...payload,
      void_vendor: [],
      void_item: [],
      permanent_void_vendor: [...permanentVoid],
    };
    Object.keys(voidChecksManufacturer).forEach(function (key) {
      if (voidChecksManufacturer[key]) reqData.void_vendor.push(key);
    });
    Object.keys(voidChecksItem).forEach(function (item) {
      Object.keys(voidChecksItem[item]).forEach(function (key) {
        if (voidChecksItem[item][key]) reqData.void_item.push(+key);
      });
    });
    if (!reqData.void_item.length) delete reqData.void_item;
    if (!reqData.void_vendor.length) delete reqData.void_vendor;
    if (!reqData.vendor_mapping?.length) delete reqData.vendor_mapping;
    if (reqData.data === undefined && !Object.keys(reqData).length) {
      setLoading(false);
      message.warning("Please fill the Manufacturer(s)");
      return;
    }
    dispatch(updatePendingBatchErroredItems(id, reqData))
      .then((result) => {
        setLoading(false);
        if (reqData.vendor_mapping) {
          message.success(MESSAGES.UPDATE_SECOND_CAROUSEL_MESSAGE);
        }
        if (reqData.void_vendor || reqData.void_item) {
          setVoidChecksManufacturer({});
          setVoidChecksItem({});
          message.success(MESSAGES.VOID_SUCCESSFUL_MESSAGE);
        }
        getErroredVendorList(pendingBatchId);
        setOpen(null);
        setVendorMapping({});
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (pagination, record, sorting) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let ListParams = {
      offset,
      limit,
      sortBy: sorting.order ? sorting.field : undefined,
      sortDir: sorting.order
        ? sorting.order === "ascend"
          ? "ASC"
          : "DESC"
        : undefined,
      filters: { vendor_number: open },
    };

    getPendingBatchItems(pendingBatchId, ListParams);
  };
  const onFinish = (obj) => {
    var vendor = [];
    for (var key in vendorMapping) {
      const newObj = {
        vendor_number: key,
        manufacturer_id: +vendorMapping[key],
      };
      if (vendorMapping[key]) vendor.push(newObj);
    }
    updateErroredBatchItems(pendingBatchId, {
      vendor_mapping: vendor.length ? vendor : undefined,
    });
  };
  const getPendingBatchItems = (id, payload) => {
    setList({});
    setTotal(get({}, "total", 0));
    setLoading(true);
    dispatch(fetchPendingBatchItems(id, payload, true))
      .then((result) => {
        setLoading(false);
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setList(result);
        setTotal(get(result, "total", 0));
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const manageVendorMapping = (item, value) => {
    setVendorMapping({ ...vendorMapping, [item]: value });
  };
  const renderManufacturerForm = (el, vendorMapping) => {
    return (
      <div className="manufacturer-form">
        <Select
          style={{ width: 300 }}
          onChange={(value) => {
            manageVendorMapping(get(el, "vendor_number", 0), value);
          }}
          placeholder="Please select a Manufacturer"
          showSearch={true}
          optionFilterProp="children"
          size={"small"}
          allowClear
          onClick={(e) => e.stopPropagation()}
          value={vendorMapping[get(el, "vendor_number", 0)]}
        >
          {map(distributorManufacturer, (el) => (
            <Option key={get(el, "id", 0)} value={get(el, "id", 0)}>
              {`${get(el, "name", "")}`}- {`${get(el, "id", "")}`}
            </Option>
          ))}
        </Select>

        <Button
          size="small"
          className="add-new-link"
          onClick={() =>
            setAddManufacturerModal({
              visible: true,
              data: get(el, "vendor_number", 0),
            })
          }
        >
          + New
        </Button>
      </div>
    );
  };

  const manageVoidItemCheckbox = (value, type = null) => {
    if (type === "ALL") {
      setVoidChecksManufacturer({ ...voidChecksManufacturer, [open]: !!value });
      setVoidChecksItem({ ...voidChecksItem, [open]: {} });
    } else {
      if (voidChecksManufacturer[open]) {
        let voidedChecked = {};
        get(batchDataIds, "data", []).forEach(function (id) {
          voidedChecked[id] = true;
        });
        setVoidChecksManufacturer({ ...voidChecksManufacturer, [open]: false });
        setVoidChecksItem({
          ...voidChecksItem,
          [open]: {
            ...voidChecksItem[open],
            ...voidedChecked,
            [type]: !!value,
          },
        });
      } else {
        setVoidChecksItem({
          ...voidChecksItem,
          [open]: { ...voidChecksItem[open], [type]: !!value },
        });
      }
    }
  };

  const managePermanentVoidCheckbox = (vendor_number, value) => {
    let temp = [];
    if (value) {
      temp = [...permanentVoid, vendor_number];
    } else {
      temp = permanentVoid.filter((elem) => elem !== vendor_number);
    }

    setPermanentVoid([...temp]);
  };

  const columns = ({ currencyCode }) => [
    {
      title: (
        <Checkbox
          onChange={(e) => manageVoidItemCheckbox(e.target.checked, "ALL")}
          checked={voidChecksManufacturer[open]}
        >
          Void
        </Checkbox>
      ),
      key: "void",
      width: 80,
      render: (value, record) => (
        <Checkbox
          checked={
            voidChecksManufacturer[open] ||
            (voidChecksItem[open] &&
              voidChecksItem[open][record.batch_record_no])
          }
          onChange={(e) =>
            manageVoidItemCheckbox(e.target.checked, record.batch_record_no)
          }
        />
      ),
    },
    {
      title: "Record No.",
      dataIndex: "batch_record_no",
      key: "batch_record_no",
      sorter: true,
      width: 110,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend",
      sortOrder:
        get(list, "sortBy") === "batch_record_no"
          ? get(list, "sortDir") === "DESC"
            ? "descend"
            : "ascend"
          : false,
    },
    {
      title: "Item No.",
      dataIndex: "item_num",
      key: "item_num",
      align: "right",
      ellipsis: true,
    },
    {
      title: "Vendor No.",
      dataIndex: "vendor_number",
      key: "vendor_number",
      sorter: true,
      ellipsis: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        get(list, "sortBy") === "vendor_number"
          ? get(list, "sortDir") === "DESC"
            ? "descend"
            : "ascend"
          : false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      // sorter: true,
      ellipsis: true,
      // sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "Vendor Part No.",
      dataIndex: "vendor_part_num",
      key: "vendor_number",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (text) => addDollar(text, currencyCode),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Sub Category",
      dataIndex: "subcategory_name",
      key: "subcategory_name",

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Publish Date",
      dataIndex: "create_date",
      key: "create_date",
      render: (date) => formatedDate(date),
    },
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      key: "effective_date",
      render: (date) => formatedDate(date),
    },
  ];
  useEffect(() => {
    if (!get(addManufacturerModal, "visible")) {
      onRefresh();
    }
  }, [addManufacturerModal?.visible]);
  return (
    <>
      <Form onFinish={onFinish}>
        <div style={contentStyle2}>
          <div className="details-wrapper">
            <div>
              {!hidden && (
                <div className="warning-text">
                  <h3>
                    <b>Missing Manufacturer Assignment </b>
                  </h3>
                </div>
              )}
              <h3>
                <b>Distributor Customer Number </b>-{" "}
                {get(pendingBatchDetails, "data.customer_number")}
              </h3>
            </div>
            <div>
              <h3 style={{ marginLeft: 165 }}>
                <b>Batch</b> - {pendingBatchId}
              </h3>
              {!hidden && (
                <div>
                  <Button
                    style={{ marginRight: 10 }}
                    size="large"
                    className="refresh-button"
                    onClick={onRefresh}
                  >
                    Refresh
                  </Button>
                  <Button
                    size="large"
                    className="add-new-link"
                    onClick={() =>
                      setAddManufacturerModal({
                        visible: true,
                        data: null,
                      })
                    }
                  >
                    Add New Manufacturer
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {!hidden ? (
                <>
                  <Collapse
                    className="collapse-style"
                    activeKey={open}
                    onChange={getVendorItems}
                    accordion
                  >
                    {map(get(erroredVendorList, "data", []), (el) => (
                      <Panel
                        header={
                          <div className="vendor-panel">
                            Permanent Void{" "}
                            <Checkbox
                              onChange={(e) => {
                                managePermanentVoidCheckbox(
                                  get(el, "vendor_number"),
                                  e.target.checked
                                );
                              }}
                              checked={permanentVoid.find(
                                (item) => item === get(el, "vendor_number")
                              )}
                            />{" "}
                            Vendor Name: {el.vendor_name} | Vendor Number:{" "}
                            {el.vendor_number}
                          </div>
                        }
                        key={el.vendor_number}
                        extra={renderManufacturerForm(el, vendorMapping)}
                      >
                        <Table
                          loading={loading}
                          size="small"
                          showSorterTooltip={false}
                          rowKey="vendor_number"
                          dataSource={get(list, "data", [])}
                          columns={columns({ currencyCode })}
                          onChange={handleChange}
                          pagination={{
                            current: paginationPage,
                            total: total,
                            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                            showSizeChanger: true,
                            onChange: (pageNum) => setPaginationPage(pageNum),
                          }}
                        />
                      </Panel>
                    ))}
                  </Collapse>
                  <Pagination
                    style={{ marginTop: 8, float: "right" }}
                    total={get(erroredVendorList, "total", 0)}
                    current={+(get(erroredVendorList, "offset", 0) / 10) + 1}
                    onChange={(page) =>
                      getErroredVendorList(pendingBatchId, {
                        offset: (page - 1) * 10,
                      })
                    }
                  />
                </>
              ) : (
                <div>
                  <h2 style={{ marginTop: 50 }}>
                    Valid Distributor-Vendor mapping(s) exist for this batch.
                  </h2>
                  <h2>This Batch is ready to be processed.</h2>
                </div>
              )}
            </div>
          </div>
          <div className="">
            {/* <div /> */}

            <Row justify={"space-around"} style={{ marginTop: 10 }}>
              <Col>
                <Form.Item>
                  {!hidden && (
                    <Button
                      style={{ marginRight: 10 }}
                      className="save-button"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  )}
                  <Button className="close-button" onClick={onClose}>
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <Modal
        title={"Add Manufacturer"}
        width={1000}
        open={get(addManufacturerModal, "visible")}
        footer={null}
        onCancel={() =>
          setAddManufacturerModal({
            visible: false,
            data: null,
          })
        }
        destroyOnClose
      >
        <AddManufacturerForm
          callback={(result) => {
            setAddManufacturerModal({
              ...addManufacturerModal,
              visible: false,
            });
            if (
              get(addManufacturerModal, "data") &&
              result &&
              get(result, "is_active")
            ) {
              manageVendorMapping(
                get(addManufacturerModal, "data"),
                get(result, "id")
              );
            }
          }}
        />
      </Modal>
    </>
  );
};

export default SecondCarousel;
