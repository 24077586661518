import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import assign from "lodash/assign";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import {
  createManufacturer,
  fetchAllManufacturerList,
} from "../../redux/actions/manufacturerActions";
import * as VALIDATIONS from "../../constants/data/validations/ManufacturerValidations";
import { filter, get, map } from "lodash";
import { getFormattedWebUrl, checkValidUrl } from "../../utilities/helper";
import SingleImageUplaod from "../common/imageUpload";

const { Option } = Select;
const { TextArea } = Input;

const AddManufacturerForm = ({ callback }) => {
  const [loading, setLoading] = useState(false);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [display, setDisplay] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [image, setImage] = useState({
    file: null,
    src: null,
  });

  const addManufacturer = (payload) => {
    setLoading(true);
    dispatch(createManufacturer(payload, image))
      .then((result) => {
        setLoading(false);
        message.success(MESSAGES.ADD_NEW_MANUFACTURER_MESSAGE);
        if (callback) callback(get(result, "data"));
        else navigate(ROUTES.MANUFACTURER_MANAGEMENT);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getAllCompactManufacturerList = () => {
    setLoading(true);
    dispatch(fetchAllManufacturerList())
      .then((result) => {
        setLoading(false);
        setManufacturerList(get(result, "data", []));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    assign(value, {
      website: get(value, "website")
        ? getFormattedWebUrl(get(value, "website"))
        : null,
    });
    const payload = omitBy(value, (v, k) => {
      if (k !== "is_active") return isNull(v) || isUndefined(v) || isEmpty(v);
      else return isNull(v) || isUndefined(v);
    });

    addManufacturer(payload);
  };

  const onselectManufacturer = (value, event) => {
    const filterList = filter(manufacturerList, (mt) => mt.id === value);
    if (get(filterList[0], "is_active", false) === false) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  useEffect(() => {
    getAllCompactManufacturerList();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        {callback ? null : (
          <div>
            <h1 className="title">Manufacturer Management</h1>
          </div>
        )}
        {callback ? null : (
          <div className="form-sub-heading">
            <div className="arrow-img">
              <img
                src={arrowLeft}
                alt=""
                onClick={() => navigate(ROUTES.MANUFACTURER_MANAGEMENT)}
              />
            </div>
            <h2 className="sub-title arrow-sub-title-alingment">
              Add Manufacturer
            </h2>
          </div>
        )}
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ is_active: true }}
        >
          <Row gutter={16}>
            <Col xl={12} xs={12}>
              <Row gutter={16}>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="default_code"
                    label="Default Code"
                    rules={VALIDATIONS.CODE_VALIDATIONS}
                  >
                    <Input size="large" className="capitalise" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="website"
                    label="Website"
                    rules={[
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Form.Item
                    name="parent_manufacturer_id"
                    label="Parent Manufacturer"
                  >
                    <Select
                      size="large"
                      showSearch={true}
                      optionFilterProp="children"
                      onSelect={onselectManufacturer}
                      onClear={() => setDisplay(false)}
                      allowClear
                    >
                      {map(manufacturerList, (at) => (
                        <Option key={get(at, "id", 0)} value={get(at, "id", 0)}>
                          {`${get(at, "name", "")}  ${
                            get(at, "is_active", "") ? "(Active)" : "(Inactive)"
                          }`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {display ? (
                    <p className="select-validation">
                      The selected Parent Manufacturer is inactive
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Form.Item name="note" label="Note">
                    <TextArea maxLength={1000} rows={3} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="manufacturer-form-button-container">
                <Form.Item label="">
                  <Button
                    className="add-user-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    ADD
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={
                      callback ||
                      (() => navigate(ROUTES.MANUFACTURER_MANAGEMENT))
                    }
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item label="Image">
                <SingleImageUplaod
                  setLoading={setLoading}
                  setImage={setImage}
                  image={image}
                  remove={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};
export default AddManufacturerForm;
