import { DeleteTwoTone } from "@ant-design/icons/lib/icons";
import { Button, Popconfirm, Tag } from "antd";
import editIcon from "../../../assets/svg/editIcon.svg";

export const columns = ({ onClickEdit, onClickDelete }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    render: (text, record) => (
        <Button.Group size={"small"}>
            <Button type="text" onClick={() => onClickEdit(record)}>
              <img src={editIcon} alt="" />
            </Button>
            <Popconfirm
                title="Are you sure you want to delete this contact?"
                onConfirm={() => onClickDelete(record)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
            >
              <Button type="text">
                <DeleteTwoTone
                    style={{ fontSize: 18, marginTop: 3, color: "red" }}
                />
              </Button>
            </Popconfirm>
        </Button.Group>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
  },
  {
    title: "Email ID",
    dataIndex: "email",
    key: "email",
    
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
    
  },
  {
    title: "Contact Type",
    dataIndex: "OrgContactType",
    key: "OrgContactType",
    
    render: (text, record) => {
      switch (text.name) {
        case "Primary":
          return (
            <Tag
              className="tag-border-radius"
              color="#86F7AF"
              style={{ color: "#068434" }}
            >
              {text.name}
            </Tag>
          );
        case "Administrator":
          return (
            <Tag
              className="tag-border-radius"
              color="#F3B329"
              style={{ color: "#604306" }}
            >
              {text.name}
            </Tag>
          );
        case "Sales":
          return (
            <Tag
              className="tag-border-radius"
              color="#F27431"
              style={{ color: "#411B06" }}
            >
              {text.name}
            </Tag>
          );
        case "Support":
          return (
            <Tag
              className="tag-border-radius"
              color="#97AFE2"
              style={{ color: "#111D37" }}
            >
              {text.name}
            </Tag>
          );
        case "Vendor":
          return (
            <Tag
              className="tag-border-radius"
              color="#F9F458"
              style={{ color: "#262603" }}
            >
              {text.name}
            </Tag>
          );
        default:
          return null;
      }
    },
  },

];
