import get from "lodash/get";
import * as APIS from "../../../constants/api";

export const fetchAllOrganizationsServers =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${id}${APIS.FETCH_ALL_ORGANIZATIONS_SERVERS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchSingleOrganizationsServers =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${get(
      payload,
      "organization_id",
      0
    )}/server/${get(payload, "id", 0)}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const createOrganizationsServer =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${get(payload, "did", 0)}${
      APIS.CREATE_ORGANIZATION_SERVER
    }`;
    return api
      .post(url, {
        environment: get(payload, "environment", ""),
        name: get(payload, "name"),
        ip: get(payload, "ip", ""),
        db_name: get(payload, "db_name", ""),
        connection_type: get(payload, "connection_type", ""),
        api_server: get(payload, "api_server", ""),
        auth_server: get(payload, "auth_server", ""),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const updateOrganizationsServer =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${get(
      payload,
      "did",
      0
    )}/server/${get(payload, "cid", 0)}`;
    return api
      .put(url, {
        environment: get(payload, "environment", ""),
        name: get(payload, "name", ""),
        ip: get(payload, "ip", ""),
        db_name: get(payload, "db_name", ""),
        connection_type: get(payload, "connection_type", ""),
        api_server: get(payload, "api_server", ""),
        auth_server: get(payload, "auth_server", ""),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
