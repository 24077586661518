import { notification } from "antd";
import axios from "axios";
import { get } from "lodash";

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_MASTER_BACKEND_BASE_URL,
  });
const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use((config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (err) => {
    if (!get(err, "response")) {
      notification.error({
        message: "Something went wrong",
        description: "Please try again or contact technical team",
      });
    } else {
      notification.error({
        message: get(err, "response.data.message"),
      });
      if (get(err, "response.status") === 401) {
        // eslint-disable-next-line no-undef
        localStorage.clear();
        window.location.replace("/login");
      }
    }
    throw err;
  }
);
export default axiosInstance;
