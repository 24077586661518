import { PlusOutlined } from "@ant-design/icons/lib/icons";
import {
  Button,
  Layout,
  Table,
  Modal,
  Spin,
  message,
  Form,
  Select,
  Input,
} from "antd";
import get from "lodash/get";
import map from "lodash/map";
import AddContactForm from "../forms/subDistributorForm/AddContactForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllDistributorTables/distributorContactTable";
import {
  deleteDistributorContact,
  fetchAllDistributorsContacts,
  fetchContactType,
  fetchSingleDistributorsContacts,
} from "../../redux/actions/allDistributorsAction/distributorContactActions";
import * as IDS from "../../constants/data/BasicData";
import * as MESSAGES from "../../constants/messages";
import { isEmpty } from "lodash";
import { totalCount } from "../../utilities/helper";

const { Option } = Select;

function ContactTableContainer({ ids }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });
  const [listData, setListData] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sortDir, setSortDir] = useState("");
  const [sort, setSort] = useState({});
  const [total, setTotal] = useState(0);
  const [isFilter, setIsFilter] = useState({});
  const [contactType, setContactType] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSucessView = () => {
    toggalModal();
    if (!isEmpty(isFilter)) {
      getDistributersContactList(ids, {
        ...isFilter,
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    } else {
      getDistributersContactList(ids, {
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    }
  };

  const getDistributersContactList = (id, payload) => {
    setLoading(true);
    dispatch(fetchAllDistributorsContacts(id, payload))
      .then((result) => {
        setListData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setSortDir(get(result, "sortDir", ""));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClickEdit = (record) => {
    getSingleDistributorsContacts(record);
  };

  const onClickDelete = (record) => {
    removeDistributorContact(record);
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const getContactType = () => {
    setLoading(false);
    dispatch(fetchContactType())
      .then((result) => {
        setLoading(false);
        setContactType(get(result, "data", []));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const removeDistributorContact = (payload) => {
    setLoading(true);
    dispatch(deleteDistributorContact(payload))
      .then(() => {
        message.success(MESSAGES.DELETE_CONTACT);
        if (!isEmpty(isFilter)) {
          getDistributersContactList(ids, {
            ...isFilter,
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        } else {
          getDistributersContactList(ids, {
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getSingleDistributorsContacts = (payload) => {
    setLoading(true);
    dispatch(fetchSingleDistributorsContacts(payload))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    for (var key in values) {
      if (
        get(values[key], "length", 0) === 0 &&
        key !== "distributor_contact_type_id"
      ) {
        delete values[`${key}`];
      }
    }
    values["name"] = values["contact_name"];
    delete values["contact_name"];

    const payload = { ...sort, filters: values, offset: 0, limit: 10 };
    setIsFilter({ filters: values });
    getDistributersContactList(ids, { ...payload });
    setPaginationPage(1);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const email = form.getFieldValue("email");
    const name = form.getFieldValue("contact_name");
    const distributor_contact_type_id = form.getFieldValue(
      "distributor_contact_type_id"
    );

    if (
      name !== null ||
      email !== null ||
      distributor_contact_type_id !== null
    ) {
      userListParams.filters = {
        name,
        email,
        distributor_contact_type_id,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }
    setSort(userListParams);
    getDistributersContactList(ids, { ...userListParams });
  };

  useEffect(() => {
    getContactType();
    getDistributersContactList(ids, {
      offset: 0,
      limit: 10,
      sortBy: "name",
      sortDir: "DESC",
    });
    setSort({ sortDir: "DESC", sortBy: "name" });
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Contact</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={toggalModal}
            size="large"
          >
            ADD CONTACT
            <PlusOutlined />
          </Button>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="contact_name">
            <div>
              <p className="form-label">Name</p>
              <Input />
            </div>
          </Form.Item>
          <Form.Item name="email">
            <div>
              <p className="form-label">Email</p>
              <Input />
            </div>
          </Form.Item>
          <div>
            <p className="form-label">Contact Type</p>
            <Form.Item name="distributor_contact_type_id">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
                allowClear
              >
                {map(contactType, (at) => (
                  <Option key={get(at, "id")} value={get(at, "id")}>
                    {get(at, "name")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                setIsFilter({});
                getDistributersContactList(ids, {
                  ...sort,
                  offset: 0,
                  limit: 10,
                  filters: {},
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>
      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={listData}
          columns={columns({ onClickEdit, onClickDelete })}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
      <Modal
        width="42%"
        title={
          <h2>
            {get(editDetails, "visible") ? "Edit Contact" : "Add Contact"}
          </h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddContactForm
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          initialValues={get(editDetails, "data")}
          onSucessView={onSucessView}
          id={ids}
          contactType={contactType}
          getContactType={getContactType}
        />
      </Modal>
    </Spin>
  );
}

export default ContactTableContainer;
