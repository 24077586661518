import React, { useState } from "react";
import get from "lodash/get";
import moment from "moment";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Spin,
  message,
} from "antd";
import {
  createReleaseNote,
  updateReleaseNote,
} from "../../redux/actions/syncConnectorAction";

// css for react-quill text-editor
import "react-quill/dist/quill.snow.css";
import { ArrowLeftOutlined } from "@ant-design/icons";

const editorFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    // [{size: []}],
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

const AddEditReleaseNote = ({ addEditWindow, setAddEditWindow, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addReleaseNote = (payload) => {
    setLoading(true);

    dispatch(createReleaseNote(payload))
      .then((result) => {
        setLoading(false);
        if (get(result, "success")) {
          message.success("Release Note Created Successfully !");
          onSuccess();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const editReleaseNote = (payload, id) => {
    setLoading(true);

    dispatch(updateReleaseNote({ payload, id }))
      .then((result) => {
        setLoading(false);
        if (get(result, "success")) {
          message.success("Release Note Updated Successfully !");
          onSuccess();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      date: moment(get(values, "date.$d")).format("YYYY-MM-DD"),
    };

    get(addEditWindow, "data")
      ? editReleaseNote(payload, get(addEditWindow, "data.id"))
      : addReleaseNote(payload);
  };

  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">
            <ArrowLeftOutlined
              style={{ cursor: "pointer", marginRight: 14 }}
              onClick={() =>
                setAddEditWindow({
                  visible: false,
                  data: null,
                })
              }
            />
            {get(addEditWindow, "data")
              ? "Edit Release Note"
              : "Add Release Note"}
          </h3>
        </div>
      </Layout>

      <Form
        layout="vertical"
        scrollToFirstError
        initialValues={
          get(addEditWindow, "data")
            ? {
                title: get(addEditWindow, "data.title"),
                version: get(addEditWindow, "data.version"),
                date: moment(get(addEditWindow, "data.date"), "YYYY-MM-DD"),
                note: get(addEditWindow, "data.note"),
              }
            : {}
        }
        onFinish={onFinish}
        onFinishFailed={() => {}}
        requiredMark
      >
        <Row gutter={16}>
          <Col xs={6}>
            <Form.Item
              label="Title"
              name={"title"}
              rules={[
                {
                  required: true,
                  message: "Please enter the title",
                },
                {
                  min: 3,
                  message: "Title must be atleast 3 characters long",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col xs={6}>
            <Form.Item
              label="Version"
              name={"version"}
              rules={[
                {
                  required: true,
                  message: "Please enter the version",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col xs={6}>
            <Form.Item
              label="Date"
              name={"date"}
              rules={[
                {
                  required: true,
                  message: "Please enter the date",
                },
              ]}
            >
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                disabledDate={(current) => moment(current.$d) > moment()}
              />
            </Form.Item>
          </Col>

          <Col xs={18}>
            <Form.Item
              label="Description"
              name={"note"}
              rules={[
                {
                  required: true,
                  message: "Please enter the description",
                },
                {
                  min: 17,
                  message: "Description length must be 10 characters long",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                modules={editorModules}
                formats={editorFormats}
                style={{ height: "100%", width: "100%" }}
                // bounds={'.app'}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="button-wrapper">
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="add-user-button"
              size="large"
            >
              {get(addEditWindow, "data") ? "UPDATE" : "ADD"}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="cancel-btn sync-connector"
              onClick={() =>
                setAddEditWindow({
                  visible: false,
                  data: null,
                })
              }
              size="large"
              type="primary"
            >
              CANCEL
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default AddEditReleaseNote;
