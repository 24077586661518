import { Button, Layout, Table, Spin, Form, Select } from "antd";
import get from "lodash/get";
import map from "lodash/map";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllOrganizationTables/OrganizationsDistributorsTables";
import * as IDS from "../../constants/data/BasicData";
import { ActiveList, totalCount } from "../../utilities/helper";
import { fetchAllOrganizationsDistributors } from "../../redux/actions/allOrganizationsActions/organizationsDistributorsActions";

const { Option } = Select;

function DistributorTableContainer({ id }) {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sort, setSort] = useState({});
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getDistributersOrganizationsList = (id, payload) => {
    setLoading(true);
    dispatch(fetchAllOrganizationsDistributors(id, payload))
      .then((result) => {
        setListData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    value["is_active"] = value["organizationDistributorActive"];
    delete value["organizationDistributorActive"];
    const payload = { ...sort, filters: value, offset: 0, limit: 10 };
    getDistributersOrganizationsList(id, { ...payload });
    setPaginationPage(1);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const is_active = form.getFieldValue("organizationDistributorActive");

    if (is_active !== null) {
      userListParams.filters = {
        is_active,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }
    setSort(userListParams);
    getDistributersOrganizationsList(id, { ...userListParams });
  };

  useEffect(() => {
    form.setFieldsValue({ organizationDistributorActive: true });
    getDistributersOrganizationsList(id, {
      offset: 0,
      limit: 10,
      sortDir: "DESC",
      sortBy: "distributor_customer_name",
      filters: { is_active: true },
    });
    setSort({ sortDir: "DESC", sortBy: "distributor_customer_name" });
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Distributors</h3>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <div>
            <p className="form-label">Mapping</p>
            <Form.Item name="organizationDistributorActive">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
              >
                {map(ActiveList, (at) => (
                  <Option key={get(at, "key")} value={get(at, "value")}>
                    {get(at, "name")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                form.setFieldsValue({ organizationDistributorActive: null });
                getDistributersOrganizationsList(id, {
                  ...sort,
                  offset: 0,
                  limit: 10,
                  filters: {},
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>

      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={listData}
          columns={columns()}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
    </Spin>
  );
}

export default DistributorTableContainer;
