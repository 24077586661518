import * as APIS from "../../../constants/api";

export const fetchAllPendingBatch = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.BASE_BATCH}${APIS.FETCH_ALL_PENDING_BATCHES}`;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchActiveDistributorCustomer =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${payload}/${APIS.COMPACT_ORG_ACTIVE_LIST}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchExistingPendingBatchDetails =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${payload}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const deletePendingBatch = (batchId) => (dispatch, getState, api) => {
  const url = `${APIS.BASE_BATCH}/pending/${batchId}`;
  return api
    .delete(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
