import { DeleteTwoTone } from "@ant-design/icons/lib/icons";
import { Button, Popconfirm, Tag } from "antd";
import editIcon from "../../../assets/svg/editIcon.svg";

export const columns = ({ onClickEdit }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    
    render: (text, record) => (
        <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    
    width: 120,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    
  },
  {
    title: "Environment",
    dataIndex: "environment",
    key: "environment",
    ellipsis: true,
    
  },
  {
    title: "Connection Type",
    dataIndex: "connection_type",
    key: "connection_type",
    
    render: (text) => {
      if (text === "DATABASE_TYPE") {
        return "Database";
      } else {
        return "API";
      }
    },
  },
  {
    title: "Server IP",
    dataIndex: "ip",
    key: "ip",
    width: "10%",
    
    ellipsis: true,
    render: (text,record) => {
      if (record.connection_type !== "DATABASE_TYPE") {
        return record.api_server;
      } else return text;
    },
  },
  {
    title: "DB Name",
    dataIndex: "db_name",
    key: "db_name",
    
    ellipsis: true,
    render: (text) => {
      if (text) {
        return text;
      } else return "-";
    },
  },
];
