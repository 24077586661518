import { Button, Col, Form, Row, Space, Table, Typography } from "antd";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columns } from "../constants/data/AllBatchesTables/editArchivedBatchTable";
import * as ROUTES from "../constants/routes";
import { fetchArchiveBatchItems } from "../redux/actions/allBatchActions/batchEditPendingActions";
import { useNavigate, useLocation } from "react-router-dom";
import arrowLeft from "../assets/svg/arrowLeft.svg";
import EditArchiveBatchFilterForm from "../components/forms/batchForms/batchManagementFilterForms/EditArchiveBatchFilterForm";
import { setBatchStatus } from "../utilities/helper";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../redux/actions/globalReducActions";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE,
  GLOBAL_PAGES_PAGINATION_DEFAULT_STATE,
} from "../redux/reducers/globalDefaultStates";
import { TableDropdown } from "../components/common/TableDropDown";

const EditArchiveBatchScreen = () => {
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [status, setStatus] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const idArr = location.search.split("");
  idArr.shift();
  const id = idArr.join("");
  const tablePaginationParams = useSelector(
    (state) =>
      get(state, "global.globalPagesPagination")[PAGES.BATCH_ITEMS_ARCHIVED]
  );
  const activeFilters = useSelector(
    (state) =>
      get(state, "global.globalPagesFilterData")[PAGES.BATCH_ITEMS_ARCHIVED]
  );
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(
          PAGES.BATCH_ITEMS_ARCHIVED,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.BATCH_ITEMS_ARCHIVED, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesFilterData(PAGES.BATCH_ITEMS_ARCHIVED, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.BATCH_ITEMS_ARCHIVED, param));
    }
  };
  useEffect(() => {
    getArchivedBatchItems();
  }, [tablePaginationParams]);

  const onClickEdit = (record) => {
    const batchId = get(record, "batch_id", "");
    const batchRecordNo = get(record, "batch_record_no", 0);
    navigate({
      pathname: ROUTES.EDIT_ARCHIVED_BATCH_ITEM,
      search: `${batchId}+${batchRecordNo}`,
    });
  };

  const getArchivedBatchItems = () => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchArchiveBatchItems(id, params))
      .then((result) => {
        setStatus(get(result, "Batch.status", ""));
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setList(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };

  useEffect(() => {
    if (get(activeFilters, "batch_id") != id) {
      setActiveFilters({
        ...GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE["BATCH_ITEMS_ARCHIVED"],
        batch_id: id,
      });
      setTablePaginationParams({
        ...GLOBAL_PAGES_PAGINATION_DEFAULT_STATE["BATCH_ITEMS_ARCHIVED"],
        filters: {
          ...GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE["BATCH_ITEMS_ARCHIVED"],
          batch_id: id,
        },
      });
    }
  }, [id]);
  return (
    <div className="main-wrapper-div">
      <div>
        <h1 className="title">Batch Management </h1>
      </div>
      <div className="form-sub-heading">
        <div className="arrow-img">
          <img
            src={arrowLeft}
            alt=""
            onClick={() => navigate(ROUTES.ARCHIVED_BATCH_LIST)}
          />
        </div>
        <h2
          className="sub-title arrow-sub-title-alingment"
          style={{ marginBottom: 30 }}
        >
          {`Batch - ${id}`}
        </h2>
        <div className="edit-batch-tag">{setBatchStatus(status)}</div>
      </div>
      <EditArchiveBatchFilterForm
        form={form}
        id={id}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />
      <div className="user-table" style={{ marginTop: -16 }}>
        <Table
          showSorterTooltip={false}
          rowKey="i"
          size="small"
          dataSource={get(list, "data", [])}
          rowClassName={(record) => `${record.status === "V" ? "voided" : ""}`}
          columns={columns({ onClickEdit, currencyCode, list })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          loading={loading}
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(list, "offset", 0) + 1} to ${
                    get(list, "data", []).length + get(list, "offset", "--")
                  } of ${get(list, "total", "--")} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset: get(list, "offset", 0) - get(list, "limit", 0),
                      })
                    }
                    disabled={!get(list, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset: get(list, "offset", 0) + get(list, "limit", 0),
                      })
                    }
                    disabled={
                      get(list, "offset", 0) + get(list, "limit", 0) >=
                      get(list, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          scroll={{ y: "calc(100vh - 420px)", x: 1000 }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default EditArchiveBatchScreen;
