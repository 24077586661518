import { Form, Row, Col, Input, Divider, Button, message } from "antd";
import { get } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/svg/editIcon.svg";
import { updateLoggedInUser } from "../../redux/actions/accountInfoAction";
import * as MESSAGES from "../../constants/messages";
import * as IDS from "../../constants/data/BasicData";
import * as VALIDATIONS from "../../constants/data/validations";

const BasicInfoForm = ({ userDetails, getUserInfo }) => {
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const editLoggedInUser = (payload) => {
    dispatch(updateLoggedInUser(payload))
      .then((result) => {
        message.success(MESSAGES.USER_UPDATE);
        getUserInfo();
      })
      .catch((error) => error);
  };

  useEffect(() => {
    form.resetFields();
  }, [userDetails]);

  const handleDisabled = () => {
    if (!edit) {
      return true;
    }
    if (get(userDetails, "Role.id", 0) === IDS.SUPER_ADMIN_ID) {
      return true;
    }
  };

  const onFinishProfile = (value) => {
    editLoggedInUser(value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={onFinishProfile}
      initialValues={userDetails}
    >
      <Row gutter={16}>
        <Col xl={24} xs={24}>
          <div className="account-info-heading">
            <b style={{ marginRight: 3 }}>Basic Information</b>
            <Button
              type="text"
              size="small"
              onClick={() => setEdit(!edit)}
              disabled={get(userDetails, "Role.id", 0) === IDS.SUPER_ADMIN_ID}
            >
              <img src={editIcon} alt="" />
            </Button>
          </div>
          <Divider style={{ marginTop: 6 }} />
          <Row gutter={16}>
            <Col xl={12} xs={12}>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={VALIDATIONS.FIRSTNAME_VALIDATIONS}
              >
                <Input size="large" disabled={handleDisabled()} />
              </Form.Item>
            </Col>
            <Col xl={12} xs={12}>
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={VALIDATIONS.LASTNAME_VALIDATIONS}
              >
                <Input size="large" disabled={handleDisabled()} />
              </Form.Item>
            </Col>
          </Row>
          {edit && (
            <Form.Item label="">
              <Button
                htmlType="submit"
                type="primary"
                className="edit-user-button"
                size="large"
              >
                UPDATE
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default BasicInfoForm;
