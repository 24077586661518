import {PAGES_CODE as PAGES} from '../../constants/pages';

export const GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE = {
    [PAGES.BATCH_PENDING]: {},
    [PAGES.BATCH_ARCHIVED]: {},
    [PAGES.BATCH_ITEMS_ARCHIVED]: {},
    [PAGES.BATCH_ITEMS_PENDING]: {},
    [PAGES.DISTRIBUTOR]: {is_active: true},
    [PAGES.MANUFACTURER]: {is_active: true},
    [PAGES.ORGANISATION]: {is_active: true},
    [PAGES.USERS]: {is_active: true},
};
export const GLOBAL_PAGES_PAGINATION_DEFAULT_STATE = {
    [PAGES.BATCH_PENDING]: {
        limit: 20,
        sortBy: "effective_date",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.BATCH_PENDING]
    },
    [PAGES.BATCH_ARCHIVED]: {
        limit: 20,
        sortBy: "effective_date",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.BATCH_ARCHIVED]
    },
    [PAGES.BATCH_ITEMS_ARCHIVED]: {
        limit: 20,
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.BATCH_ITEMS_ARCHIVED]
    },
    [PAGES.BATCH_ITEMS_PENDING]: {
        limit: 20,
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.BATCH_ITEMS_PENDING]
    },
    [PAGES.DISTRIBUTOR]: {
        limit: 100,
        sortBy: "name",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.DISTRIBUTOR]
    },
    [PAGES.MANUFACTURER]: {
        limit: 100,
        sortBy: "name",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.MANUFACTURER]
    },
    [PAGES.ORGANISATION]: {
        limit: 20,
        sortBy: "name",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.ORGANISATION]
    },
    [PAGES.USERS]: {
        limit: 20,
        sortBy: "first_name",
        sortDir: "ASC",
        filters: GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[PAGES.USERS]
    },
};
