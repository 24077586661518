import { Button } from 'antd';
import { formatedDate, setBatchStatus } from '../../../utilities/helper';
import { EyeOutlined } from '@ant-design/icons/lib/icons';
import get from "lodash/get";
export const columns = ({ onClickEdit, archiveData }) => [
  {
    title: 'Actions',
    dataIndex: '',
    key: 2,
    width: 120,
    render: (text, record) => (
        <div>
          <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
            <EyeOutlined style={{ fontSize: 20, color: '#b1b1b1' }} />
          </Button>
        </div>
    )
  },
  {
    title: 'Batch ID',
    dataIndex: 'id',
    key: 'id',
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(archiveData, 'sortBy') === "id" ?(get(archiveData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    width: 110
  },
  {
    title: 'Distributor',
    dataIndex: 'Distributor',
    key: 'Distributor',
    render: (text) => text.name,
    ellipsis: true,
    width: 240
  },
  {
    title: 'Organization',
    dataIndex: 'Organization',
    key: 'Organization',
    render: (text) => (text && text.name ? text.name : '-'),
    ellipsis: true,
    width: 240
  },

  {
    title: 'Publish Date',
    dataIndex: 'create_date',
    key: 'create_date',
    sorter:true,
    sortOrder : get(archiveData, 'sortBy') === "create_date" ?(get(archiveData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 200
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    sorter:true,
    sortOrder : get(archiveData, 'sortBy') === "effective_date" ?(get(archiveData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render: (date) => formatedDate(date),
    width: 200
  },
  {
    title: 'Batch Status',
    dataIndex: 'status',
    key: 'status',
    
    render: (text) => setBatchStatus(text),
    width: 180
  },

];
