//________AuthActions______
export const LOGIN = "login";
export const LOGOUT = "logout";
export const PERSIST = "persist/REHYDRATE";
export const USER_DETAILS = "userDetails";

//
export const FILTER = "filter";
export const DISTRIBUTOR_FILTER = "distributor-filter";
export const CANCEL_FILTER = "cancel-filter";
export const ORGANIZATION_FILTER = "organisation-filter";
export const CLEAR_ORGANIZATION_FILTER = "clear-organization-filter";
export const CLEAR_DISTRIBUTOR_FILTER = "clear-distributor-filter";
export const MANUFACTURER_FILTER = "manufacturer-filter";
export const CLEAR_MANUFACTURER_FILTER = "clear-manufacturer-filter";
export const USER_FILTER = "user-filter";
export const CLEAR_USER_FILTER = "clear-user-filter";

//Batch Management Filter
export const PENDING_BATCH_FILTER = "pending-batch-filter";
export const SENT_BATCH_FILTER = "sent-batch-filter";
export const ARCHIVE_BATCH_FILTER = "archive-batch-filter";
export const CLEAR_BATCH_FILTERS = "clear-batch-filter";

// Batch Management Sorter
export const PENDING_BATCH_SORTER = "pending-batch-sorter";
export const EDIT_PENDING_BATCH_SORTER = "edit-pending-batch-sorter";
export const SENT_BATCH_SORTER = "sent-batch-sorter";
export const EDIT_SENT_BATCH_SORTER = "edit-sent-batch-sorter";
export const ARCHIVE_BATCH_SORTER = "archive-batch-sorter";
export const EDIT_ARCHIVE_BATCH_SORTER = "edit-archive-batch-sorter";

//All Sorter
export const MANUFACTURER_SORTER = "manufacturer-sorter";
export const DISTRIBUTOR_SORTER = "distributor-sorter";
export const ORGANIZATION_SORTER = "organization-sorter";
export const CANCEL_SORTER = "cancel-sorter";
export const CANCEL_DISTRIBUTOR_SORTER = "cancel-distributor-sorter";
export const CANCEL_MANUFACTURER_SORTER = "cancel-manufacturer-sorter";
export const CANCEL_ORGANIZATION_SORTER = "cancel-organization-sorter";
