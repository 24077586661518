import { get } from "lodash";
import { useSelector } from "react-redux";

const ErrorScreen = () => {
  const isLoggedIn = useSelector((state) => get(state, "auth.isLoggedIn"));
  return (
    <>
      <div>
        <div className="error-screen-wrapper">
          <h1>{isLoggedIn ? "" : 404}</h1>
          <p>This page does not exist</p>
        </div>
      </div>
    </>
  );
};

export default ErrorScreen;
