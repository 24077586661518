import { Button, Col, Form, Row, Space, Spin, Table, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { columns } from "../../constants/data/AllBatchesTables/archivedBatchTable";
import { fetchAllArchivedBatch } from "../../redux/actions/allBatchActions/batchArchivedActions";
import get from "lodash/get";
import ArchivedBatchFilterForm from "../forms/batchForms/batchManagementFilterForms/ArchivedBatchFilterForm";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../../redux/actions/globalReducActions";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { TableDropdown } from "../common/TableDropDown";

const ArchivedTableContainer = ({ selectedKey, distributorList }) => {
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [archiveData, setArchiveData] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const tablePaginationParams = useSelector(
    (state) => get(state, "global.globalPagesPagination")[PAGES.BATCH_ARCHIVED]
  );
  const activeFilters = useSelector(
    (state) => get(state, "global.globalPagesFilterData")[PAGES.BATCH_ARCHIVED]
  );
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(
          PAGES.BATCH_ARCHIVED,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.BATCH_ARCHIVED, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesFilterData(PAGES.BATCH_ARCHIVED, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.BATCH_ARCHIVED, param));
    }
  };
  useEffect(() => {
    getArchivedBatchList();
  }, [tablePaginationParams]);
  const onClickEdit = (record) => {
    const id = get(record, "id", 0);
    navigate({
      pathname: ROUTES.EDIT_ARCHIVED_BATCHES,
      search: `${id}`,
    });
  };

  const getArchivedBatchList = () => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchAllArchivedBatch(params))
      .then((result) => {
        setArchiveData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getArchivedBatchList();
  }, []);
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };
  return (
    <Spin spinning={loading}>
      <ArchivedBatchFilterForm
        form={form}
        distributorList={distributorList}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />
      <div className="user-table" style={{ marginTop: -16 }}>
        <Table
          showSorterTooltip={false}
          ref={tableRef}
          size="small"
          rowKey="1"
          dataSource={get(archiveData, "data")}
          columns={columns({ onClickEdit, archiveData })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(archiveData, "offset", 0) + 1} to ${
                    get(archiveData, "data", []).length +
                    get(archiveData, "offset", "--")
                  } of ${get(archiveData, "total", "--")} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() => {
                      if (tableRef?.current) {
                        const scrollContainer =
                          tableRef.current.querySelector(".ant-table-body");
                        if (scrollContainer) {
                          scrollContainer.scrollTop = 0;
                        }
                      }

                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(archiveData, "offset", 0) -
                          get(archiveData, "limit", 0),
                      });
                    }}
                    disabled={!get(archiveData, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() => {
                      if (tableRef?.current) {
                        const scrollContainer =
                          tableRef.current.querySelector(".ant-table-body");
                        if (scrollContainer) {
                          scrollContainer.scrollTop = 0;
                        }
                      }

                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(archiveData, "offset", 0) +
                          get(archiveData, "limit", 0),
                      });
                    }}
                    disabled={
                      get(archiveData, "offset", 0) +
                        get(archiveData, "limit", 0) >=
                      get(archiveData, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          scroll={{ y: "calc(100vh - 420px)", x: 1000 }}
          pagination={false}
        />
      </div>
    </Spin>
  );
};

export default ArchivedTableContainer;
