import { Spin, Tabs, message } from "antd";
import get from "lodash/get";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAllSyncConnectors,
  updateActiveSwitch,
} from "../redux/actions/syncConnectorAction";
import AboutPage from "../components/AboutPage";
import SyncConnectorTableContainer from "../components/SyncConnectorTableContainer";
import * as MESSAGES from "../constants/messages";
import { clearFilter } from "../redux/actions/filterActions";
import { clearBatchFilter } from "../redux/actions/batchFilterActions";
import ReleaseNotes from "../components/releaseNotes";

// const { TabPane } = Tabs;

const SystemConfigurationScreen = () => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    data: null,
  });
  const [syncData, setSyncData] = useState([]);

  const dispatch = useDispatch();

  const getSyncConnectorList = () => {
    setLoading(true);
    dispatch(fetchAllSyncConnectors())
      .then((result) => {
        setSyncData(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onChangeHandler = (record) => {
    setLoading(true);
    dispatch(updateActiveSwitch(record))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.ACTIVE_UPDATE_MESSAGE);
        getSyncConnectorList();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onSuccess = () => {
    getSyncConnectorList();
    setModal({
      visible: false,
      data: null,
    });
  };

  const onClickEdit = (record) => {
    setModal({
      visible: true,
      data: record,
    });
  };

  useEffect(() => {
    localStorage.removeItem("selected");
    dispatch(clearBatchFilter());
    dispatch(clearFilter());
    getSyncConnectorList();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">System Configuration</h1>
        </div>

        <div className="card-container">
          <Tabs
            type="card"
            items={[
              {
                key: "1",
                label: <h1>Sync Connector Type</h1>,
                children: (
                  <SyncConnectorTableContainer
                    syncData={syncData}
                    modal={modal}
                    setModal={setModal}
                    onSuccess={onSuccess}
                    onChangeHandler={onChangeHandler}
                    onClickEdit={onClickEdit}
                  />
                ),
              },
              {
                key: "2",
                label: <h1>Release Notes</h1>,
                children: <ReleaseNotes />,
              },
              {
                key: "3",
                label: <h1>About</h1>,
                children: <AboutPage />,
              },
            ]}
          ></Tabs>
        </div>
      </div>
    </Spin>
  );
};

export default SystemConfigurationScreen;
