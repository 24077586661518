import { Button, Switch, Tag } from "antd";
import get from "lodash/get";
import { formatedDate } from "../../../utilities/helper";
import editIcon from "../../../assets/svg/editIcon.svg";

export const columns = ({ onChangeHandler, onClickEdit }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    width:80,
    render: (text, record) => (
        <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "Distributor Customer ID",
    dataIndex: "distributor_customer_id",
    key: "distributor_customer_id",
    width:180
  },
  {
    title: "Distributor Customer Name",
    dataIndex: "distributor_customer_name",
    key: "distributor_customer_name",
    ellipsis: true,
    
  },
  {
    title: "Organization",
    dataIndex: "Organization",
    key: "Distributor",
    render: (text, record) => get(text, "name", ""),
    ellipsis: true,
    
  },

  {
    title: "Organization Status",
    dataIndex: "Organization",
    key: "Organization",
    
    render: (text, record) =>
      text.is_active === true ? (
        <Tag className="tag-border-radius" color="#86f7af">
          Active
        </Tag>
      ) : (
        <Tag className="tag-border-radius">Inactive</Tag>
      ),
  },
  {
    title: "Date Active",
    dataIndex: "active_date",
    key: "active_date",
    
    render: (date) => formatedDate(date),
  },
  {
    title: "Date Inactive",
    dataIndex: "inactive_date",
    key: "inactive_date",
    
    render: (date) => formatedDate(date),
  },
  {
    title: "Mapping",
    dataIndex: "is_active",
    key: "is_active",
    
    render: (text, record) => (
      <>
        <Switch
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },


];
