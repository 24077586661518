import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  Typography,
} from "antd";
import assign from "lodash/assign";
import get from "lodash/get";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations/DistributorValidations";
import { createDistributor } from "../../redux/actions/distributorActions";
import {
  getFormattedWebUrl,
  getFormattedFtpUrl,
  checkValidUrl,
  checkValidMobileNumber,
} from "../../utilities/helper";
import SingleImageUplaod from "../common/imageUpload";

const { Text } = Typography;
const { TextArea } = Input;

const AddDistributorForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [image, setImage] = useState({
    file: null,
    src: null,
  });

  const addDistributor = (payload) => {
    setLoading(true);
    dispatch(createDistributor(image, payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.ADD_NEW_DISTRIBUTOR_MESSAGE);
        navigate(ROUTES.DISTRIBUTOR_MANAGEMENT);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    assign(value, {
      url: get(value, "url") ? getFormattedWebUrl(get(value, "url")) : null,
      ftp_url: get(value, "ftp_url")
        ? getFormattedFtpUrl(get(value, "ftp_url"))
        : null,
    });
    const payload = omitBy(value, (v, k) => {
      if (k !== "is_active") return isNull(v) || isUndefined(v) || isEmpty(v);
      else return isNull(v) || isUndefined(v);
    });
    addDistributor(payload);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Distributor Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(ROUTES.DISTRIBUTOR_MANAGEMENT)}
            />
          </div>
          <h2 className="sub-title arrow-sub-title-alingment">
            Add Distributor
          </h2>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ is_active: false }}
        >
          <Row gutter={16}>
            <Col xl={20} xs={20}>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="name"
                    label="Distributor Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="code"
                    label="Code"
                    rules={VALIDATIONS.CODE_VALIDATIONS}
                  >
                    <Input
                      size="large"
                      className="capitalise distributorInput non-required"
                    />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="url"
                    label="URL"
                    rules={[
                      ...VALIDATIONS.URL_VALIDATIONS,
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" className="non-required" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="ftp_url"
                    label="FTP URL"
                    rules={[
                      ...VALIDATIONS.FTP_URL_VALIDATIONS,
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch checked={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>
                  <Form.Item label="Image">
                    <SingleImageUplaod
                      setLoading={setLoading}
                      setImage={setImage}
                      image={image}
                      remove={true}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={12}>
                  <Form.Item
                    name="comment"
                    label="Comment"
                    rules={VALIDATIONS.DISTRIBUTOR_COMMENT_VALIDATIONS}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="distributor-form-subheading">
                <Text type="secondary">Distributor Contact</Text>
              </div>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_name"
                    label="Contact Name"
                    rules={VALIDATIONS.CONTACT_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_phone"
                    label="Phone"
                    rules={[
                      ...VALIDATIONS.CONTACT_PHONE_VALIDATIONS,
                      {
                        validator: checkValidMobileNumber,
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_email"
                    label="Email"
                    rules={VALIDATIONS.CONTACT_EMAIL_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="manufacturer-form-button-container form-positioning">
                <Form.Item label="">
                  <Button
                    className="add-user-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    ADD
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={() => navigate(ROUTES.DISTRIBUTOR_MANAGEMENT)}
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};
export default AddDistributorForm;
