//Password Validation
const PASSWORD_REGEX =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[]?[;,=!@#$%^&*()_-]).{12,}$";

export const PASSWORD_VALIDATION = [
  { required: true, message: "Please input Password" },
  {
    min: 12,
    message: "Password does not meet minimum length of 12",
  },
  {
    pattern: new RegExp(PASSWORD_REGEX),
    message:
      "Password must contain at least 4 alphabets, 1 uppercase, 1 lowercase, 1 number, and 1 special character",
    validateTrigger: "onSubmit",
  },
];

export const LOGIN_SCREEN_PASSWORD_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please input Password" },
];

export const PASSWORD_VALIDATIONS_WITHOUT_REQUIRED = [
  {
    min: 12,
    message: "Password does not meet minimum length of 12",
  },
  {
    pattern: new RegExp(PASSWORD_REGEX),
    message:
      "Password must contain at least 4 alphabets, 1 uppercase, 1 lowercase, 1 number, and 1 special character",
    validateTrigger: "onSubmit",
  },
];

export const FIRSTNAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please input First Name",
  },

  {
    pattern: new RegExp(/^[a-zA-Z0-9 ]+$/),
    message: "First Name must contain only alphanumeric characters and spaces",
  },
  {
    min: 3,
    message: "First Name must contain at least 3 characters",
  },
];

export const LASTNAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please input Last Name" },
  {
    pattern: new RegExp(/^[a-zA-Z0-9 ]+$/),
    message: "Last Name must contain only alphanumeric characters and spaces",
  },
];

export const EMAIL_VALIDATIONS = [
  { required: true, message: "Please input Email ID" },
  {
    type: "email",
    message: "Please input a valid Email ID",
  },
];
export const REQUIRED_FIELD_WITH_NAME_MESSAGE = "'${label}' is required!";