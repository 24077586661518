import { Tag } from 'antd';
import filter from 'lodash/filter';
import countBy from 'lodash/countBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import indexOf from 'lodash/indexOf';
import moment from 'moment';
import * as IDS from '../constants/data/BasicData';

export const getFormattedWebUrl = (url) => {
  if (startsWith(url, 'http://') || startsWith(url, 'https://')) {
    return url;
  }
  return `http://${url}`;
};

export const getFormattedFtpUrl = (url) => {
  if (startsWith(url, 'ftp://')) {
    return url;
  }
  return `ftp://${url}`;
};

export const checkValidUrl = (rule, value, callback) => {
  if (!value || isEmpty(value)) {
    callback();
    return;
  }
  const arr = value.split('.');
  if (arr.length < 2) {
    callback('Please input a valid Url');
    return;
  }
  callback();
  return;
};

export const checkValidMobileNumber = (rule, value, callback) => {
  const errorMessage = 'Please input a valid mobile Number';

  if (!value || isEmpty(value)) {
    callback();
    return;
  }
  const dashCount = countBy(value)['-'] || 0;

  if (dashCount > 3) {
    callback(errorMessage);
    return;
  }

  const plusCount = countBy(value)['+'] || 0;
  if (plusCount > 1) {
    callback(errorMessage);
    return;
  }

  if (plusCount && !startsWith(value, '+')) {
    callback(errorMessage);
    return;
  }
  callback();
  return;
};

export const hasPermissions = (mainArray, subArray) => {
  let allow = true;
  subArray.forEach((s) => {
    if (indexOf(mainArray, s) === -1) {
      allow = false;
    }
  });
  return allow;
};

export const hasAnyPermission = (mainArray, subArray) => {
  let allow = false;
  subArray.forEach((s) => {
    if (indexOf(mainArray, s) >= 0) {
      allow = true;
    }
  });
  return allow;
};

export const changeDisabled = (loggedInUser, record) => {
  if (
    get(loggedInUser, 'Role.id', '') === IDS.SUPER_ADMIN_ID ||
    get(loggedInUser, 'Role.id', '') === IDS.ADMIN_ID
  ) {
    if (get(record, 'Role.role_code', '') === get(loggedInUser, 'Role.role_code', '')) {
      return true;
    }
  } else {
    return true;
  }
};

export const toggleClick = (order) => {
  return {
    sortDir: order && order === 'ascend' ? IDS.ASCENDING : IDS.DECENDING
  };
};

export const RoleType = [
  {
    id: '2',
    name: 'Admin User'
  },
  {
    id: '3',
    name: 'Standard User'
  }
];

export const ActiveList = [
  { key: '0', value: undefined, name: 'All' },
  { key: '1', value: true, name: 'Active' },
  { key: '2', value: false, name: 'Inactive' }
];

export const dropdown = [
  {
    name: 'Database',
    value: 'DATABASE_TYPE'
  },
  {
    name: 'API',
    value: 'API_TYPE'
  }
];

// Date Format
export const formatedDate = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  } else return '-';
};
// Date Format without moment
export const formatDateWithoutMoment = (text) => {
  if (text) {
    const newDate = text.split('-').reverse().join('-');
    return newDate;
  } else return '-';
};

export const batchStatus = [
  {
    code: 'N',
    status: 'New',
    color: '#9EF0A3',
    textColor: '#0D6316'
  },
  {
    code: 'I',
    status: 'Identified',
    color: '#C3E5F4',
    textColor: '#1E6F8F'
  },
  {
    code: 'S',
    status: 'Sent',
    color: '#7EF43E',
    textColor: '#112A04'
  },
  {
    code: 'R',
    status: 'Received',
    color: '#F8F120',
    textColor: '#494504'
  },
  {
    code: 'P',
    status: 'Processing',
    color: '#47BD4F',
    textColor: '#0E200E'
  },
  {
    code: 'E',
    status: 'Error',
    color: '#F89BA4',
    textColor: '#640710'
  },
  {
    code: 'O',
    status: 'Open',
    color: '#F9D995',
    textColor: '#6F4E06'
  },
  {
    code: 'C',
    status: 'Completed',
    color: '#2DE1AB',
    textColor: '#0E5D47'
  },
  {
    code: 'V',
    status: 'Void',
    color: '#CBE2DB',
    textColor: '#3A6456'
  }
];

export const currencyCodeMap = {
  CAD: '$',
  USD: '$',
  AUD: '$',
  INR: '₹',
  JPY: '¥',
  AED: 'د.إ'
};

export const addDollar = (price, currencyCode) => {
  for (var key in currencyCodeMap) {
    if (key === currencyCode) {
      return `${currencyCodeMap[key]}${price}`;
    }
  }
  return `${currencyCode} ${price}`;
};

export const setBatchStatus = (text) => {
  const filteredArray = filter(batchStatus, (el) => el.code === text);
  return (
    <Tag
      className="tag-border-radius"
      color={get(filteredArray[0], 'color', '#FFA500')}
      style={{ color: `${get(filteredArray[0], 'textColor', '#FFFFFF')}` }}
    >
      {get(filteredArray[0], 'status', '')}
    </Tag>
  );
};

export const totalCount = (total) => {
  return <div className="total-count">Total Records: {total}</div>;
};

export const parseQueryString = function (query) {
  const obj = {};
  if (query.length) {
    if (query[0] == '?' || query[0] == '#') {
      query = query.substring(1, query.length)
    }
    const tempArr = query.split('&');
    tempArr.forEach(function (str) {
      const arr = str.split('=');
      if (arr.length == 2) {
        obj[arr[0]] = arr[1]
      }
    });
  }
  return obj;
}

export const getFormDataPayload = (image, payload) => {
  let formData = new FormData();
  if (get(image,'file') || get(image, 'file') === "") {
    formData.append("image", get(image,'file'));
  }

  for (var key in payload) {
    formData.append(`${key}`, get(payload, `${key}`, ""));
  }
  return formData;
};

export function getTextBetweenTags(htmlString) {
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, 'text/html');
  
  // Get the text content between the tags
  const textContent = doc.body.textContent;

  return textContent;
}