import {Button, Form, Input, Layout, Select, Space} from "antd";
import get from "lodash/get";
import map from "lodash/map";
import isBoolean from "lodash/isBoolean";
import {ActiveList, RoleType} from "../../utilities/helper";

const { Option } = Select;

const UserFilterForm = ({ form,setActiveFilters,tablePaginationParams,activeFilters,setTablePaginationParams }) => {
  return (
    <Layout className="filter-form user-filter-form-wrapper">
      <Form
        form={form}
        layout="inline"
        onFinish= {() => setTablePaginationParams({
          ...tablePaginationParams,
          offset: 0,
          filters: { ...activeFilters },
        })}
      >
        <Space>
        <div>
          <p className="form-label">First Name</p>
            <Input value={activeFilters.first_name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, first_name: e.target.value ? e.target.value : undefined};
            })}/>
        </div>
        <div>
          <p className="form-label">Last Name</p>
            <Input value={activeFilters.last_name}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, last_name: e.target.value ? e.target.value : undefined};
            })}/>
        </div>
        <div>
          <p className="form-label">User Type</p>
            <Select className="filter-select" size="medium" allowClear value={activeFilters.role_id}  onChange={(value) => setActiveFilters(prev => {
              return { ...prev, role_id: value ? value : undefined};
            })}>
              {map(RoleType, (rt) => (
                <Option key={get(rt, "id")} value={get(rt, "id")}>
                  {get(rt, "name")}
                </Option>
              ))}
            </Select>
        </div>
        <div>
          <p className="form-label">Email</p>
            <Input value={activeFilters.email}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, email: e.target.value ? e.target.value : undefined};
            })}/>
        </div>
        <div>
          <p className="form-label">Status</p>
            <Select className="filter-select" size="medium" value={activeFilters.is_active}  onChange={(value) => setActiveFilters(prev => {
              return { ...prev, is_active: value !== null ? value : undefined};
            })}>
              {map(ActiveList, (at) => (
                <Option key={get(at, "key")} value={get(at, "value")}>
                  {get(at, "name")}
                </Option>
              ))}
            </Select>
        </div>

        <Form.Item>
          <Button htmlType="submit" className="filter-btn add-btn">
            Apply
          </Button>
        </Form.Item>
        <Form.Item>
          <Button className="filter-btn cancel-btn" onClick={() => {
            setActiveFilters({});
            setTablePaginationParams({ ...tablePaginationParams, filters: {} });
          }}>
            Clear
          </Button>
        </Form.Item>
        </Space>
      </Form>
    </Layout>
  );
};

export default UserFilterForm;
