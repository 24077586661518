import {Button, Switch, Tag} from "antd";
import get from "lodash/get";
import editIcon from "../../assets/svg/editIcon.svg";
import {changeDisabled} from "../../utilities/helper";

export const columns = ({ onClickEdit, onChangeHandler, loggedInUser, userData }) => [ {
  title: "Actions",
  dataIndex: "",
  key: 2,
  width: 120,
  render: (text, record) => (
      <Button
          size={"small"}
          disabled={changeDisabled(loggedInUser, record)}
          type="text"
          onClick={() => onClickEdit(record)}
      >
        <img src={editIcon} alt="" />
      </Button>
  ),
},
  {
    title: "User ID",
    dataIndex: "id",
    key: "id",
    width: 120,
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(userData, 'sortBy') === "id" ?(get(userData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-'
  },
  {
    title: "Name",
    dataIndex: "first_name",
    key: "first_name",
    ellipsis: true,
    render:(value)=> value || '-',
    width: 350,
  },
  {
    title: "User Type",
    dataIndex: "Role",
    key: "Role",
    width: 140,
    render: (text, record) => (
      <Tag className="tag-border-radius" color="#FFA500">
        {get(text, "name", "")}
      </Tag>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter:true,
    sortOrder : get(userData, 'sortBy') === "email" ?(get(userData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    ellipsis: true,
    width: 350,
  },
  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
     
    width: 120,
    render: (text, record) => (
      <>
        <Switch
          disabled={changeDisabled(loggedInUser, record)}
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },

];
