import { Button, Form, Input, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginWithOtp,
  loginWithTotp,
} from "../../redux/actions/authActions";
import BeforeLoginLogo from "../../assets/images/BeforeLoginLogo.png";
import { useState, useEffect } from "react";
import get from "lodash/get";
import * as ROUTES from "../../constants/routes";
import * as VALIDATIONS from "../../constants/data/validations";
import * as MESSAGES from "../../constants/messages";
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from "../../constants/data/validations";

const LoginForm = ({ setResetPassword }) => {
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(false);
  const [twoFAType, setTwoFAType] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let isLoggedIn = useSelector((state) => get(state, "auth.isLoggedIn", false));

  const getToken = () => {
    const loggedInDetails = localStorage.getItem("persist:root");
    const auth = get(JSON.parse(loggedInDetails), "auth", "");
    const parsedAuth = JSON.parse(auth);
    const token = get(parsedAuth, "token", "");
    return token;
  };

  const onLogin = (credentials) => {
    const genToken = getToken();
    if (genToken) {
      message.error(MESSAGES.LOGOUT_USER_ANOTHER_TAB);
    } else {
      setLoading(true);
      dispatch(login(credentials))
        .then((result) => {
          setLoading(false);
          setTwoFAType(get(result, "data.multi_factor_auth_type"));
          setLoginData(result.data);
        })
        .catch((error) => {
          if (get(error, "response.data.require_reset_password", "")) {
            navigate(get(error, "response.data.reset_password_path", ""));
          }
          setResetPassword(true);
          setLoading(false);
        });
    }
  };
  const verifyOtp = (payload) => {
    const genToken = getToken();
    if (genToken) {
      message.error(MESSAGES.LOGOUT_USER_ANOTHER_TAB);
    } else {
      setLoading(true);
      dispatch(loginWithOtp({ ...payload, otp_token: loginData.otp_token }))
        .then((result) => {
          setLoading(false);
          setLoginData(result.data);
        })
        .catch((error) => {
          if (get(error, "response.data.require_reset_password", "")) {
            navigate(get(error, "response.data.reset_password_path", ""));
          }
          setResetPassword(true);
          setLoading(false);
        });
    }
  };
  const verifyTotp = (payload) => {
    const genToken = getToken();
    if (genToken) {
      message.error(MESSAGES.LOGOUT_USER_ANOTHER_TAB);
    } else {
      setLoading(true);
      dispatch(loginWithTotp({ ...payload, totp_token: loginData.totp_token }))
        .then((result) => {
          setLoading(false);
          setLoginData(result.data);
        })
        .catch((error) => {
          if (get(error, "response.data.require_reset_password", "")) {
            navigate(get(error, "response.data.reset_password_path", ""));
          }
          setResetPassword(true);
          setLoading(false);
        });
    }
  };
  const onClickLogIn = (credentials) => {
    onLogin(credentials);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.BATCH_MANAGEMENT);
    }
  }, [isLoggedIn]);

  const onFinish = (values) => {
    onClickLogIn(values);
  };

  return (
    <Spin spinning={loading}>
      <div className="login-form-container">
        <div className="logo-container">
          <img src={BeforeLoginLogo} alt="" />
        </div>
        <p className="main-login-heading">
          Log in to <span className="highlight">Connect Master</span>
        </p>
        {!twoFAType ? (
          <Form
            name="normal login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label="Email ID"
              rules={VALIDATIONS.EMAIL_VALIDATIONS}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={VALIDATIONS.LOGIN_SCREEN_PASSWORD_VALIDATIONS}
            >
              <Input type="password" size="large" />
            </Form.Item>
            <div>
              <p
                style={{ cursor: "pointer" }}
                className="forgot-password"
                onClick={() => navigate("/forgotPassword/email")}
              >
                Forgot Password?
              </p>
            </div>
            <Form.Item>
              <Button
                className="login-button"
                type="primary"
                size="large"
                htmlType="submit"
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        ) : null}
        {twoFAType === "OTP" ? (
          <>
            <p className="mt-10-n">Please enter the OTP sent to your mail</p>
            <Form
              id={"components-form-demo-normal-login"}
              name="normal_login"
              size={"large"}
              className="login-form"
              onFinish={verifyOtp}
              layout="vertical"
            >
              <Form.Item
                label="OTP"
                name="code"
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>

              <Form.Item>
                <Button
                  className="login-button"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : null}
        {twoFAType === "TOTP" ? (
          <>
            <p className="mt-10-n">
              Please enter the TOTP from authenticator app
            </p>
            <Form
              id={"components-form-demo-normal-login"}
              name="normal_login"
              size={"large"}
              className="login-form"
              onFinish={verifyTotp}
              layout="vertical"
            >
              <Form.Item
                label="TOTP"
                name="code"
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-button"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : null}
      </div>
    </Spin>
  );
};

export default LoginForm;
