import { get } from "lodash";
import * as APIS from "../../../constants/api";

export const fetchAllDistributorsManufacturers =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${id}${APIS.FETCH_ALL_DISTRIBUTORS_MANUFACTURERS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchSingleDistributorsManufacturers =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "distributor_id",
      0
    )}/manufacturer/${get(payload, "Manufacturer.id", 0)}/link/${get(
      payload,
      "id",
      0
    )}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const createManufacturerMapping =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "did",
      0
    )}/manufacturer/${get(payload, "manufacturer_id", 0)}/link`;
    return api
      .post(url, {
        distributor_vendor_id: get(payload, "distributor_vendor_id", 0),
        distributor_vendor_name: get(payload, "distributor_vendor_name", ""),
        is_active: get(payload, "is_active", false),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const updateManufacturerMapping =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "did",
      0
    )}/manufacturer/${get(payload, "old_manufacturer_id", 0)}/link/${get(
      payload,
      "linkId",
      0
    )}`;
    return api
      .patch(url, {
        is_active: get(payload, "is_active", false),
        manufacturer_id: get(payload, "manufacturer_id", 0),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
export const fetchActiveManufacturerList = () => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_ALL_ACTIVE_MANUFACTURER}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchAllManufacturerList = () => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_ALL_MANUFACTURERS_LIST}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const updateManufacturerMappingSwitch =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "distributor_id",
      0
    )}/manufacturer/${get(payload, "Manufacturer.id", 0)}/link/${get(
      payload,
      "id",
      0
    )}`;
    return api
      .patch(url, { is_active: !get(payload, "is_active") })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
