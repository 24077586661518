import get from "lodash/get";
import * as APIS from "../../../constants/api";

export const fetchAllOrganizationsDistributors =
  (id, payload) => (dispatch, getState, api) => {
    const url = `organization/${id}${APIS.FETCH_ALL_ORGANIZATIONS_DISTRIBUTORS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
