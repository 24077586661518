import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  Tabs,
  DatePicker,
  Typography,
  Divider,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/OrganizationValidations";
import {
  fetchSingleOrganization,
  updateOrganization,
} from "../../redux/actions/organizationActions";
import DistributorTableContainer from "../../components/organizationTablesContainer/DistributorTableContainer";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import OrganizationContactTableContainer from "../organizationTablesContainer/OrganizationContactTableContainer";
import OrganizationServerTableContainer from "../organizationTablesContainer/OrganizationServerTableContainer";
import OrganizationSyncConnectorTableContainer from "../organizationTablesContainer/OrganizationSyncConnectorTableContainer";
import moment from "moment";

const { TabPane } = Tabs;
const { Text } = Typography;

const EditOrganizationForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState({});
  const [isTouched, setIsTouched] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const idArr = location.search.split("");
  idArr.shift();
  const id = idArr.join("");

  const editOrganization = (payload, id) => {
    setLoading(true);
    dispatch(updateOrganization(payload, id))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.EDIT_ORGANIZATION_MESSAGE);
        navigate(ROUTES.ORGANISATION_MANAGEMENT);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getSingleOrganization = (id) => {
    setLoading(true);
    dispatch(fetchSingleOrganization(id))
      .then((result) => {
        setInitialDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    for (const property in value) {
      if (value[property] === null) {
        delete value[property];
      }
    }
    delete value["id"];
    value["expires_at"] = get(value, "expires_at")
      ? value["expires_at"].format("YYYY-MM-DD")
      : null;

    if (isTouched) {
      if (get(value, "primary_user_password", 0).length > 0) {
        editOrganization(
          {
            ...value,
            multi_factor_auth_type:
              value.multi_factor_auth_type === false ? "NONE" : undefined,
          },
          id
        );
      } else {
        delete value["primary_user_password"];
        editOrganization(
          {
            ...value,
            multi_factor_auth_type:
              value.multi_factor_auth_type === false ? "NONE" : undefined,
          },
          id
        );
      }
    } else {
      delete value["primary_user_password"];
      editOrganization(
        {
          ...value,
          multi_factor_auth_type:
            value.multi_factor_auth_type === false ? "NONE" : undefined,
        },
        id
      );
    }
  };

  useEffect(() => {
    getSingleOrganization(id);
  }, [location]);

  useEffect(() => {
    form.resetFields();
  }, [initialDetails]);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Organization Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(ROUTES.ORGANISATION_MANAGEMENT)}
            />
          </div>
          <h2 className="sub-title arrow-sub-title-alingment">
            Edit Organization
          </h2>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            ...initialDetails,
            multi_factor_auth_type:
              get(initialDetails, "multi_factor_auth_type") !== "NONE",
            expires_at:
              get(initialDetails, "expires_at") &&
              moment(moment(get(initialDetails, "expires_at", "")).format()),
          }}
        >
          <Row gutter={16}>
            <Col xl={20} xs={20}>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item name="id" label="BLS Customer ID">
                    <Input className="id-input" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="name"
                    label="Organization Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="portal_backend_url"
                    label="Connect Portal Service Endpoint"
                    rules={VALIDATIONS.PORTAL_BACKEND_URL_VALIDATIONS}
                  >
                    <Input
                      size="large"
                      className="non-required"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xl={8} xs={8}>
                  <Form.Item
                    name="expires_at"
                    label="Expires At"
                    rules={VALIDATIONS.EXPIRES_DATE_VALIDATIONS}
                  >
                    <DatePicker size="large" style={{ width: 330 }} />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item name="expires_at" label="Expires At">
                    <DatePicker size="large" style={{ width: 330 }} />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch checked={false} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="distributor-form-subheading">
                <Text type="secondary">Primary Contact Details</Text>
              </div>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_first_name"
                    label="First Name"
                    rules={VALIDATIONS.PRIMARY_USER_FIRST_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_last_name"
                    label="Last Name"
                    rules={VALIDATIONS.PRIMARY_USER_LAST_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_email"
                    label="Email"
                    rules={VALIDATIONS.PRIMARY_USER_EMAIL_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_user_password"
                    label="Password"
                    rules={VALIDATIONS.PASSWORD_VALIDATION_WITHOUT_REQUIRED}
                  >
                    <Input.Password
                      onChange={() => setIsTouched(true)}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    label="Two Factor Authentication"
                    name="multi_factor_auth_type"
                    valuePropName="checked"
                  >
                    <Switch
                      className="ant-switch-green"
                      disabled={
                        get(initialDetails, "multi_factor_auth_type") === "NONE"
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="manufacturer-form-button-container form-positioning">
                <Form.Item label="">
                  <Button
                    className="save-manufacturer-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    SAVE
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={() => navigate(ROUTES.ORGANISATION_MANAGEMENT)}
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <Divider style={{ marginTop: -10 }} />
      <div className="card-container distributer-tabs">
        <Tabs
          type="card"
          items={[
            {
              key: "2",
              label: <h1>Distributors</h1>,
              children: <DistributorTableContainer id={id} />,
            },
            {
              key: "3",
              label: <h1>Sync Connectors</h1>,
              children: <OrganizationSyncConnectorTableContainer ids={id} />,
            },
            {
              key: "5",
              label: <h1>Servers</h1>,
              children: <OrganizationServerTableContainer ids={id} />,
            },
            {
              key: "4",
              label: <h1>Contact</h1>,
              children: <OrganizationContactTableContainer ids={id} />,
            },
          ]}
        ></Tabs>
      </div>
    </Spin>
  );
};
export default EditOrganizationForm;
