import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Button,
  message,
  Select,
  Checkbox,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as MESSAGES from "../../../constants/messages";
import * as VALIDATIONS from "../../../constants/data/validations/subDistributorValidations/DistributorManufacturerValidations";
import map from "lodash/map";
import {
  createManufacturerMapping,
  fetchActiveManufacturerList,
  updateManufacturerMapping,
} from "../../../redux/actions/allDistributorsAction/distributorsManufacturerActions";

const { Option } = Select;
const AddManufacturerMapping = ({
  toggalModal,
  initialValues,
  edit,
  onSucessView,
  listData,
  id,
  linkId,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const Obj = {
    is_active: "manufacturer_mapping_modal_active",
    distributor_vendor_id: "distributorVendorId",
    distributor_vendor_name: "distributorVendorName",
    manufacturer_id: "manufacturerId",
  };

  const onFinish = (value) => {
    for (var key in Obj) {
      value[`${key}`] = value[Obj[key]];
      delete value[Obj[key]];
    }

    if (edit) {
      const payload = {
        ...value,
        old_manufacturer_id: get(initialValues, "Manufacturer.id"),
        did: id,
        linkId: get(initialValues, "id", 0),
        manufacturer_id: get(value, 'permanently_void') ? -1 : get(value, 'manufacturer_id'),
        is_active: get(value, 'permanently_void') ? true : get(value, 'is_active'),
      };
      delete payload['permanently_void'];

      editManufacturerMapping(payload);
    } else {
      const payload = {
        ...value,
        did: id,
        manufacturer_id: get(value, 'permanently_void') ? -1 : get(value, 'manufacturer_id'),
        is_active: get(value, 'permanently_void') ? true : get(value, 'is_active'),
      };
      delete payload['permanently_void'];

      AddManufacturerMapping(payload);
    }
  };

  const AddManufacturerMapping = (payload) => {
    setLoading(true);
    dispatch(createManufacturerMapping(payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.MAP_DISTRIBUTORS_MANUFACTURERS_MESSAGE);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editManufacturerMapping = (payload) => {
    setLoading(true);
    dispatch(updateManufacturerMapping(payload))
      .then(() => {
        message.success(MESSAGES.EDIT_DISTRIBUTORS_MANUFACTURERS_MESSAGE);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getActiveManufacturerList = () => {
    setLoading(false);
    dispatch(fetchActiveManufacturerList())
      .then((result) => {
        const data = get(result, "data", []);
        setLoading(false);
        if (get(initialValues, "Manufacturer", null)) {
          if (get(initialValues, "Manufacturer.is_active", false) === false) {
            setActiveList([
              {
                id: get(initialValues, "Manufacturer.id", 0),
                name: get(initialValues, "Manufacturer.name", ""),
              },
              ...data,
            ]);
          } else {
            setActiveList(data);
          }
        } else {
          setActiveList(data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getActiveManufacturerList();
  }, [initialValues]);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                distributorVendorId: get(
                  initialValues,
                  "distributor_vendor_id",
                  ""
                ),
                distributorVendorName: get(
                  initialValues,
                  "distributor_vendor_name",
                  ""
                ),
                manufacturerId: get(initialValues, "manufacturer_id", ""),
                manufacturer_mapping_modal_active: get(
                  initialValues,
                  "is_active",
                  false
                ),
                permanently_void: get(initialValues, 'manufacturer_id') === -1,
              }
            : {
                manufacturer_mapping_modal_active: false,
              }
        }
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="distributorVendorId"
                  label="Distributor Vendor ID"
                  rules={VALIDATIONS.DISTRIBUTOR_VENDOR_ID_VALIDATIONS}
                >
                  <Input size="large" disabled={edit ? true : false} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="distributorVendorName"
                  label="Distributor Vendor Name"
                  rules={VALIDATIONS.DISTRIBUTOR_VENDOR_NAME_VALIDATIONS}
                >
                  <Input size="large" disabled={edit ? true : false} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item
                  name={'permanently_void'}
                  valuePropName="checked"
                >
                  <Checkbox>Permanently Void</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xl={24} xs={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currValues) => {
                    return prevValues.permanently_void !== currValues.permanently_void
                  }}
                >
                  {
                    ({getFieldValue}) => {
                      return getFieldValue('permanently_void')
                            ? null
                            : (
                              <Row gutter={16}>
                                <Col xl={12} xs={12}>
                                  <Form.Item
                                    name="manufacturerId"
                                    label="Manufacturer"
                                    rules={VALIDATIONS.DISTRIBUTOR_MANUFACTURER_VALIDATIONS}
                                  >
                                    <Select
                                      size="large"
                                      showSearch={true}
                                      optionFilterProp="children"
                                    >
                                      {map(activeList, (rt) => (
                                        <Option key={get(rt, "id")} value={get(rt, "id")}>
                                          {get(rt, "name")}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                  <p className="select-validation">
                                    {get(initialValues, "Manufacturer.is_active") === false
                                      ? `The selected Manufacturer "${get(
                                          initialValues,
                                          "Manufacturer.name"
                                        )}" is inactive`
                                      : ""}
                                  </p>
                                </Col>
                                <Col xl={12} xs={12}>
                                  <Form.Item
                                    name="manufacturer_mapping_modal_active"
                                    label="Mapping"
                                  >
                                    <Switch
                                      defaultChecked={
                                        edit ? get(initialValues, "is_active") : false
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )
                    }
                  }
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddManufacturerMapping;
