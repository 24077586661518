import { Button, Switch, Typography } from "antd";
import { get } from "lodash";
import editIcon from "../../../assets/svg/editIcon.svg";

const { Text } = Typography;

export const columns = ({ onClickEdit, onChangeHandler }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    width: 80,
    render: (text, record) => (
        <Button
            type="text"
            onClick={() => {
              onClickEdit(record);
            }}
            size={"small"}
        >
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    
    width: 120,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    
    ellipsis: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    
    ellipsis: true,
  },
  {
    title: "Sync Connector Type",
    dataIndex: "SyncConnectorType",
    key: "SyncConnectorType",
    render: (text) => get(text, "name", ""),
    
  },
  {
    title: "Parent Sync Connector",
    key: 'parent_sync_connector',
    dataIndex: ['ParentSyncConnector', 'name'],
    render: (item) => <Text>{item || '--'}</Text>
  },
  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
    
    width: 200,
    render: (text, record) => (
      <>
        <Switch
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },

];
