import { addDollar, formatedDate } from '../../../../utilities/helper';
import {get} from "lodash";

export const columns = ({ currencyCode, list }) => [
  {
    title: 'Record No.',
    dataIndex: 'batch_record_no',
    key: 'batch_record_no',
    sorter: true,
    width:110,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(list, 'sortBy') === "batch_record_no" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
  },
  {
    title: 'Item No.',
    dataIndex: 'item_num',
    key: 'item_num',
    align:'right',
    ellipsis: true
  },
  {
    title: 'Vendor No.',
    dataIndex: 'vendor_number',
    key: 'vendor_number',
    sorter: true,
    ellipsis: true,
    sortOrder : get(list, 'sortBy') === "vendor_number" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    // sorter: true,
    // sortOrder : get(list, 'sortBy') === "vendor_name" ?(get(list, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    // sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Vendor Part No.',
    dataIndex: 'vendor_part_num',
    key: 'vendor_number',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    align:'right',
    render: (text) => addDollar(text, currencyCode)
  },
  {
    title: 'Category',
    dataIndex: 'category_name',
    key: 'category_name',
    
    render: (text) => (text ? text : '-')
  },
  {
    title: 'Sub Category',
    dataIndex: 'subcategory_name',
    key: 'subcategory_name',
    
    render: (text) => (text ? text : '-')
  },
  {
    title: 'Publish Date',
    dataIndex: 'create_date',
    key: 'create_date',
    render: (date) => formatedDate(date)
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    render: (date) => formatedDate(date)
  }
];
