import { get } from "lodash";
import * as TYPES from "../../constants/actionTypes";
import axiosInstance from "../../utilities/configureAxios";

const initialState = {
  user: null,
  token: null,
  isLoggedIn: null,
  authorities: [],
  role_code: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        token: get(action, "payload.data.token"),
        isLoggedIn: true,
      };

    case TYPES.LOGOUT:
      axiosInstance.defaults.headers.common.authorization = null;
      return initialState;

    case TYPES.USER_DETAILS:
      return {
        ...state,
        user: get(action, "payload.data.name"),
        authorities: get(action, "payload.data.Role.RolePermissions", []),
        role_id: get(action, "payload.data.Role.id"),
        role_code: get(action, "payload.data.Role.role_code"),
      };
    case TYPES.PERSIST:
      if (get(action, "payload.auth.token")) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${get(
          action,
          "payload.auth.token"
        )}`;
      }
      return state;
    default:
      return state;
  }
};

export default AuthReducer;
