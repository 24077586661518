//ALL USER ID

export const SUPER_ADMIN_ID = 1;
export const ADMIN_ID = 2;
export const STANDERED_USER_ID = 3;

// SORT DIRECTIONS

export const ASCENDING = "ASC";
export const DECENDING = "DESC";
export const EMAIL = "email";

//ALL USER ROLE
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_ADMIN_USER = "ROLE_ADMIN_USER";
export const ROLE_STANDERED_USER = "ROLE_STANDARD_USER";
