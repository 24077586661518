import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Layout, Table, Modal, Spin, Form, Input } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllOrganizationTables/organizationServerTable";
import * as IDS from "../../constants/data/BasicData";
import { isEmpty } from "lodash";
import {
  fetchAllOrganizationsServers,
  fetchSingleOrganizationsServers,
} from "../../redux/actions/allOrganizationsActions/organizationServerActions";
import AddOrganizationServerForm from "../forms/AddOrganizationServerForm";
import { totalCount } from "../../utilities/helper";

function OrganizationServerTableContainer({ ids }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });
  const [listData, setListData] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sort, setSort] = useState({});
  const [total, setTotal] = useState(0);
  const [isFilter, setIsFilter] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSucessView = () => {
    toggalModal();
    if (!isEmpty(isFilter)) {
      getOrganizationServerList(ids, {
        ...isFilter,
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    } else {
      getOrganizationServerList(ids, {
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    }
  };

  const getOrganizationServerList = (id, payload) => {
    setLoading(true);
    dispatch(fetchAllOrganizationsServers(id, payload))
      .then((result) => {
        setListData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClickEdit = (record) => {
    getSingleOrganizationsServer(record);
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const getSingleOrganizationsServer = (payload) => {
    setLoading(true);
    dispatch(fetchSingleOrganizationsServers(payload))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    for (var key in values) {
      if (get(values[key], "length", 0) === 0) {
        delete values[`${key}`];
      }
    }
    values["name"] = values["server_name"];
    delete values["server_name"];

    const payload = { ...sort, filters: values, offset: 0, limit: 10 };
    setIsFilter({ filters: values });
    getOrganizationServerList(ids, { ...payload });
    setPaginationPage(1);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const environment = form.getFieldValue("environment");
    const name = form.getFieldValue("server_name");

    if (name !== null || environment !== null) {
      userListParams.filters = {
        name,
        environment,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }
    setSort(userListParams);
    getOrganizationServerList(ids, { ...userListParams });
  };

  useEffect(() => {
    getOrganizationServerList(ids, {
      offset: 0,
      limit: 10,
      sortBy: "id",
      sortDir: "DESC",
    });
    setSort({ sortDir: "DESC", sortBy: "id" });
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Server</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={toggalModal}
            size="large"
          >
            ADD SERVER
            <PlusOutlined />
          </Button>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="server_name">
            <div>
              <p className="form-label">Name</p>
              <Input />
            </div>
          </Form.Item>
          <Form.Item name="environment">
            <div>
              <p className="form-label">Environment</p>
              <Input />
            </div>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                setIsFilter({});
                getOrganizationServerList(ids, {
                  ...sort,
                  filters: {},
                  offset: 0,
                  limit: 10,
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>
      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={listData}
          columns={columns({ onClickEdit })}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
      <Modal
        width="42%"
        title={
          <h2>{get(editDetails, "visible") ? "Edit Server" : "Add Server"}</h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddOrganizationServerForm
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          initialValues={get(editDetails, "data")}
          onSucessView={onSucessView}
          id={ids}
        />
      </Modal>
    </Spin>
  );
}

export default OrganizationServerTableContainer;
