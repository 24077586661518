import * as TYPES from "../../constants/actionTypes";

export const pendingBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.PENDING_BATCH_SORTER,
      payload: payload,
    });
  };

export const editPendingBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.EDIT_PENDING_BATCH_SORTER,
      payload: payload,
    });
  };
export const sentBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.SENT_BATCH_SORTER,
      payload: payload,
    });
  };
export const editSentBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.EDIT_SENT_BATCH_SORTER,
      payload: payload,
    });
  };
export const archiveBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.ARCHIVE_BATCH_SORTER,
      payload: payload,
    });
  };
export const editArchiveBatchSorterDetails =
  (payload) => (dispatch, getState, api) => {
    dispatch({
      type: TYPES.EDIT_ARCHIVE_BATCH_SORTER,
      payload: payload,
    });
  };
