import get from "lodash/get";
import * as APIS from "../../../constants/api";

export const fetchAllDistributorsOrganizations =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "id",
      0
    )}${APIS.FETCH_ALL_DISTRIBUTORS_ORGANISATIONS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const createOrganizationMapping =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "did",
      0
    )}/organization/${get(payload, "organisatiion_id", 0)}/link`;
    return api
      .post(url, {
        distributor_customer_id: get(payload, "distributor_customer_id", 0),
        distributor_customer_name: get(
          payload,
          "distributor_customer_name",
          ""
        ),
        note: get(payload, "note", ""),
        is_active: get(payload, "is_active", false),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const updateOrganizationMappingSwitch =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_DISTRIBUTORS_MANUFACTURER}/${get(
      payload,
      "distributor_id",
      0
    )}/organization/${get(payload, "organization_id", 0)}/link/${get(
      payload,
      "id",
      0
    )}`;
    return api
      .patch(url, {
        is_active: !get(payload, "is_active", false),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
export const fetchActiveOrganizationList = () => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_ALL_ACTIVE_ORGANISATION}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

// // export const updateActiveSwitch =
// //   (payload) =>
// //   (dispatch, getState, { api }) => {
// //     const url = `${APIS.UPDATE_ACTIVE_SWITCH}/${get(payload, "id")}`;
// //     return api
// //       .patch(url, { is_active: !get(payload, "is_active") })
// //       .then(() => {})
// //       .catch((error) => {
// //         throw error;
// //       });
// //   };
