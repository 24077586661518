import get from "lodash/get";
import assign from "lodash/assign";
import * as TYPES from "../../constants/actionTypes";
import { manufacturerFilter } from "../../constants/filterTypes";

const initialState = assign({}, { ...manufacturerFilter });

const manufacturerFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MANUFACTURER_FILTER:
      return {
        ...state,
        ...get(action, "payload", {}),
      };

    case TYPES.CLEAR_MANUFACTURER_FILTER:
      return assign({}, { ...manufacturerFilter });

    default:
      return state;
  }
};

export default manufacturerFilterReducer;
