import { Button, Switch, Space, Avatar} from "antd";
import editIcon from "../../assets/svg/editIcon.svg";
import { formatedDate } from "../../utilities/helper";
import get from "lodash/get";
import isNull from 'lodash/isNull'

export const columns = ({ onChangeHandler, onClickEdit, distributorData }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    
    width: 120,
    render: (text, record) => (
        <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 120,
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(distributorData, 'sortBy') === "id" ?(get(distributorData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-'
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    sorter:true,
    sortOrder : get(distributorData, 'sortBy') === "name" ?(get(distributorData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value, record)=> (
      <Space>
        {!isNull(get(record, 'image_url')) ? <Avatar size="large" src={get(record, 'image_url')} /> : 
        <Avatar style={{ backgroundColor: '#FAA500'}} size="large" > {value.charAt(0)}</Avatar> }
        {value || '-'}
      </Space>
    )
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    width: 120,
    sorter:true,
    sortOrder : get(distributorData, 'sortBy') === "code" ?(get(distributorData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-'
  },
  {
    title: "URL",
    dataIndex: "url",
    key: "url",
    ellipsis: true,
    render: (text) =>
      text ? (
        <span
          style={{ color: "orange", cursor: "pointer" }}
          onClick={() => window.open(text)}
        >
          {text}
        </span>
      ) : (
        "-"
      ),
  },
  {
    title: "Active Date",
    dataIndex: "active_date",
    key: "active_date",
    width: 140,
    render: (date) => formatedDate(date),
  },
  {
    title: "Inactive Date",
    dataIndex: "inactive_date",
    key: "inactive_date",
    
    width: 140,
    render: (date) => formatedDate(date),
  },
  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
    
    width: 120,
    render: (text, record) => (
      <>
        <Switch
          defaultChecked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },

];
