export const ORGANIZATION_CONTACT_PHONE_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please input Phone Number",
  },
  {
    pattern: new RegExp(/^[0-9-+()]*$/),
    message: "Please input a valid Phone Number",
  },
  {
    max: 20,
    message: "Phone Number must be less than 20 characters",
  },
];

export const ORGANIZATION_CONTACT_NAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Contact Name",
  },
  {
    min: 3,
    message: "Contact Name must contain at least 3 characters",
  },
  {
    max: 255,
    message: "Contact Name must be less than 255 characters",
  },
];

export const ORGANIZATION_CONTACT_TYPE_VALIDATIONS = [
  {
    required: true,
    message: "Please select Contact Type",
  },
];

export const ORGANIZATION_CONTACT_EMAIL_VALIDATIONS = [
  { required: true, message: "Please input Email" },
  {
    type: "email",
    message: "Please input a valid Email",
  },
  {
    max: 100,
    message: "Email must be less than 100 characters",
  },
];
