import * as APIS from "../../../constants/api";

export const fetchAllArchivedBatch = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.BASE_BATCH}${APIS.FETCH_ALL_ARCHIVED_BATCHES}`;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
