import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Button,
  message,
  Select,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as MESSAGES from "../../../constants/messages";
import * as VALIDATIONS from "../../../constants/data/validations/subDistributorValidations/DistributorOrganizationValidations";
import map from "lodash/map";
import {
  createOrganizationMapping,
  fetchActiveOrganizationList,
} from "../../../redux/actions/allDistributorsAction/distributorsOrganizationActions";
import { updateOrganizationMapping } from "../../../redux/actions/allDistributorsAction/distributorsOrganisationActions";

const { Option } = Select;
const { TextArea } = Input;

const AddOrganizationMappingForm = ({
  id,
  onSucessView,
  toggalModal,
  initialValues,
  edit,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    value["is_active"] = value["add_org_mapping_is_active"];
    delete value["add_org_mapping_is_active"];

    if (edit) {
      delete value["distributor_customer_id"];
      delete value["distributor_customer_name"];
      delete value["organisatiion_id"];

      if (value["note"] === null) {
        value["note"] = "";
      }

      const payload = {
        ...value,
        did: id,
        old_organization_id: get(initialValues, "organization_id", 0),
        linkId: get(initialValues, "id", 0),
      };
      EditOrganizationMapping(payload);
    } else {
      const payload = { ...value, did: id };
      AddOrganizationMapping(payload);
    }
  };

  const AddOrganizationMapping = (payload) => {
    setLoading(true);
    dispatch(createOrganizationMapping(payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.MAP_ORGANISATION);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const EditOrganizationMapping = (payload) => {
    setLoading(true);
    dispatch(updateOrganizationMapping(payload))
      .then(() => {
        message.success(MESSAGES.EDIT_DISTRIBUTORS_ORGANIZATION_MESSAGE);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getActiveOrganizationList = () => {
    setLoading(false);
    dispatch(fetchActiveOrganizationList())
      .then((result) => {
        setLoading(false);
        setActiveList(get(result, "data", []));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getActiveOrganizationList();
  }, [initialValues]);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                distributor_customer_id: get(
                  initialValues,
                  "distributor_customer_id",
                  0
                ),
                distributor_customer_name: get(
                  initialValues,
                  "distributor_customer_name",
                  ""
                ),
                organisatiion_id: get(initialValues, "organization_id", 0),
                note: get(initialValues, "note", ""),
                add_org_mapping_is_active: get(
                  initialValues,
                  "is_active",
                  false
                ),
              }
            : {
                add_org_mapping_is_active: false,
              }
        }
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="distributor_customer_id"
                  label="Distributor Customer ID"
                  rules={VALIDATIONS.DISTRIBUTOR_CUSTOMER_ID_VALIDATIONS}
                >
                  <Input size="large" disabled={edit ? true : false} />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="distributor_customer_name"
                  label="Distributor Customer Name"
                  rules={VALIDATIONS.DISTRIBUTOR_CUSTOMER_NAME_VALIDATIONS}
                >
                  <Input size="large" disabled={edit ? true : false} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="organisatiion_id"
                  label="Organization"
                  rules={VALIDATIONS.DISTRIBUTOR_ORGANISATION_VALIDATIONS}
                >
                  <Select
                    size="large"
                    showSearch={true}
                    optionFilterProp="children"
                    allowClear
                    disabled={edit ? true : false}
                  >
                    {map(activeList, (rt) => (
                      <Option key={get(rt, "id")} value={get(rt, "id")}>
                        {get(rt, "name")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  size="small"
                  className="add-new-link"
                  onClick={() =>
                    window.open(
                      "add-organization",
                      "_blank",
                      "height=500,width=1200,left=115,top=100"
                    )
                  }
                  disabled={edit ? true : false}
                >
                  Add New Organization
                </Button>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="note"
                  label="Note"
                  rules={VALIDATIONS.ORGANISATION_NOTE_VALIDATIONS}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xl={24} xs={24}>
                <Row gutter={16}>
                  <Col xl={12} xs={12}>
                    <Form.Item name="add_org_mapping_is_active" label="Mapping">
                      <Switch
                        defaultChecked={
                          edit ? get(initialValues, "is_active") : false
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddOrganizationMappingForm;
