import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "../redux/actions/authActions";
import { LOGIN } from "../constants/routes";

import {
  LOGOUT,
  CLEAR_BATCH_FILTERS,
  CLEAR_DISTRIBUTOR_FILTER,
  CLEAR_MANUFACTURER_FILTER,
  CLEAR_ORGANIZATION_FILTER,
  CLEAR_USER_FILTER,
} from "../constants/actionTypes";

const LogoutScreen = () => {
  const dispatch = useDispatch();
  dispatch(logout());
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAR_BATCH_FILTERS,
  });
  dispatch({
    type: CLEAR_DISTRIBUTOR_FILTER,
  });

  dispatch({
    type: CLEAR_MANUFACTURER_FILTER,
  });

  dispatch({
    type: CLEAR_ORGANIZATION_FILTER,
  });

  dispatch({
    type: CLEAR_USER_FILTER,
  });

  return <Navigate to={LOGIN} />;
};

export default LogoutScreen;
