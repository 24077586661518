import get from "lodash/get";
import * as TYPES from "../../constants/actionTypes";
import { normalInitialFilter } from "../../constants/filterTypes";

const initialState = {
  pendingBatchFilter: { ...normalInitialFilter },
  sentBatchFilter: { ...normalInitialFilter },
  archiveBatchFilter: { ...normalInitialFilter },
};

const batchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PENDING_BATCH_FILTER:
      return {
        ...state,
        pendingBatchFilter: get(action, "payload", {}),
      };

    case TYPES.SENT_BATCH_FILTER:
      return {
        ...state,
        sentBatchFilter: get(action, "payload", {}),
      };

    case TYPES.ARCHIVE_BATCH_FILTER:
      return {
        ...state,
        archiveBatchFilter: get(action, "payload", {}),
      };

    case TYPES.CLEAR_BATCH_FILTERS:
      return {
        pendingBatchFilter: { ...normalInitialFilter },
        sentBatchFilter: { ...normalInitialFilter },
        archiveBatchFilter: { ...normalInitialFilter },
      };

    default:
      return state;
  }
};

export default batchFilterReducer;
