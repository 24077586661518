import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { get, map } from "lodash";
import { columns } from "../../constants/data/AllBatchesTables/dataRectificationTables/editPendingBatchTable";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPendingBatchItems } from "../../redux/actions/allBatchActions/batchEditPendingActions";
import { updatePendingVendorNameErroredItems } from "../../redux/actions/allBatchActions/dataRectificationActions";
import * as MESSAGES from "../../constants/messages";
import { VOID_SUCCESSFUL_MESSAGE } from "../../constants/messages";

const { Option } = Select;
const { Panel } = Collapse;

function VendorNameMissingCorousal({
  distributorManufacturer,
  erroredVendorNamesList,
  pendingBatchId,
  pendingBatchDetails,
  onClose,
  onRefresh,
  hidden,
  ...props
}) {
  const [open, setOpen] = useState("");
  const [list, setList] = useState({});
  const [total, setTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  function getVendorItems(key) {
    if (key.length) {
      getPendingBatchItems(pendingBatchId, {
        offset: 0,
        limit: 10,
        filters: {
          vendor_number: key,
        },
      });
    }
    setOpen(key);
  }

  const getPendingBatchItems = (id, payload) => {
    setList({});
    setTotal(get({}, "total", 0));
    setLoading(true);
    dispatch(fetchPendingBatchItems(id, payload, true))
      .then((result) => {
        setLoading(false);
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setList(result);
        setTotal(get(result, "total", 0));
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleChange = (pagination, record, sorting) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let ListParams = {
      offset,
      limit,
      sortBy: sorting.order ? sorting.field : undefined,
      sortDir: sorting.order
        ? sorting.order === "ascend"
          ? "ASC"
          : "DESC"
        : undefined,
      filters: { vendor_number: open },
    };

    getPendingBatchItems(pendingBatchId, ListParams);
  };
  const onFinish = (obj) => {
    var vendor = [];
    var void_vendor = [];
    for (let key in obj.name) {
      const newObj = {
        vendor_number: key,
        vendor_name: obj.name[key],
      };
      if (obj.name[key] && obj.name[key].trim().length && !obj.void[key])
        vendor.push(newObj);
    }
    for (let key in obj.void) {
      if (obj.void[key]) void_vendor.push(key);
    }
    console.log(obj);
    if (vendor.length || void_vendor.length)
      updateErroredBatchItems(pendingBatchId, {
        vendor: vendor.length ? vendor : undefined,
        void_vendor: void_vendor.length ? void_vendor : undefined,
      });
    else message.warning("Please Fill the Vendor Names");
  };
  const updateErroredBatchItems = (id, payload) => {
    setLoading(true);
    dispatch(updatePendingVendorNameErroredItems(id, payload))
      .then((result) => {
        setLoading(false);
        if (payload.vendor)
          message.success(MESSAGES.UPDATE_SECOND_CAROUSEL_MESSAGE);
        if (payload.void_vendor)
          message.success(MESSAGES.VOID_SUCCESSFUL_MESSAGE);
        props.getErroredVendorNamesList(pendingBatchId);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onChange = (option) => {
    let void_vendor = {};
    erroredVendorNamesList.forEach((id) => {
      void_vendor[id] = !!option;
    });
    formRef.current.setFieldsValue({
      void: void_vendor,
    });
  };
  return (
    <Form onFinish={onFinish} ref={formRef}>
      <div>
        <div className="">
          <Row>
            <Col>
              <div className="warning-text">
                <h3>
                  <b>Missing Vendor Name Assignment </b>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {/*<Form.Item style={{marginBottom: 0}} name={'void_all'} valuePropName={"checked"}><Checkbox onChange={(e)=>onChange(e.target.checked)}> Void*/}
              {/*    All</Checkbox></Form.Item>*/}
              <Collapse
                className="collapse-style"
                activeKey={open}
                onChange={getVendorItems}
                accordion
              >
                {map(erroredVendorNamesList, (el) => (
                  <Panel
                    header={
                      <Row justify={"space-between"}>
                        <Col
                          span={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Form.Item
                            style={{ marginBottom: 0, width: 80 }}
                            name={["void", `${el}`]}
                            label={"Void"}
                            valuePropName={"checked"}
                          >
                            <Checkbox onClick={(e) => e.stopPropagation()} />
                          </Form.Item>
                          <span>Vendor Number: {el}</span>
                        </Col>
                        <Col>
                          <Form.Item
                            style={{ marginBottom: 0 }}
                            name={["name", el]}
                          >
                            <Input
                              onClick={(e) => e.stopPropagation()}
                              placeholder={"Vendor Name"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                    key={el}
                  >
                    <Table
                      loading={loading}
                      size="small"
                      showSorterTooltip={false}
                      rowKey="vendor_number"
                      scroll={{ x: 1500 }}
                      dataSource={get(list, "data", [])}
                      columns={columns({ currencyCode, list })}
                      onChange={handleChange}
                      pagination={{
                        current: +(get(list, "offset", 0) / 10) + 1,
                        total: get(list, "total", 0),
                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                        showSizeChanger: true,
                        onChange: (pageNum) => setPaginationPage(pageNum),
                      }}
                    />
                  </Panel>
                ))}
              </Collapse>
            </Col>
            <Col span={24}>
              <Row justify={"space-around"} style={{ marginTop: 10 }}>
                <Col>
                  <Form.Item>
                    {!hidden && (
                      <Button
                        style={{ marginRight: 10 }}
                        className="save-button"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    )}
                    <Button className="close-button" onClick={onClose}>
                      Close
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}

export default VendorNameMissingCorousal;
