import { Layout, Divider, Spin, Typography } from "antd";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAboutContent } from "../redux/actions/syncConnectorAction";

const AboutPage = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(false);
  const dispatch = useDispatch();

  const { Text } = Typography;

  const getAboutDetails = () => {
    setLoading(true);
    dispatch(fetchAboutContent())
      .then((result) => {
        setDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAboutDetails();
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <h1 className="about-heading">About</h1>
        <div className="description">
          <Text disabled>Name</Text>
          <p className="desc-paragraph1">{get(details, "name", "")}</p>
        </div>
        <Divider className="about-page-divider" />
        <div className="description">
          <Text disabled>Version</Text>
          <p className="desc-paragraph2">{get(details, "version", "")}</p>
        </div>
        <Divider className="about-page-divider" />
        <div className="description">
          <Text disabled>Contact Info</Text>
          <p className="desc-paragraph3">{get(details, "email", "")}</p>
        </div>
      </Layout>
    </Spin>
  );
};

export default AboutPage;
