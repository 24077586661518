import { Button, Switch, Tag } from "antd";
import get from "lodash/get";
import editIcon from "../../../assets/svg/editIcon.svg";

export const columns = ({ onClickEdit, onChangeHandler }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    
    render: (text, record) => (
        <Button
          type="text"
          onClick={() => onClickEdit(record)}
          size={"small"}
          disabled={get(record, 'manufacturer_id') === -1}
        >
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "Distributor Vendor ID",
    dataIndex: "distributor_vendor_id",
    key: "distributor_vendor_id",
    
  },
  {
    title: "Distributor Vendor Name",
    dataIndex: "distributor_vendor_name",
    key: "distributor_vendor_name",
    sorter: true,
    
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    ellipsis: true,
  },
  {
    title: "Manufacturer",
    dataIndex: "Manufacturer",
    key: "Manufacturer",
    
    render: (text, record) => get(text, "name", ""),
  },
  {
    title: "Manufacturer Status",
    dataIndex: "Manufacturer",
    key: "Manufacturer",
    
    render: (text, record) =>
      get(text, "is_active", false) === true ? (
        <Tag className="tag-border-radius" color="#86f7af">
          Active
        </Tag>
      ) : (
        <Tag className="tag-border-radius">Inactive</Tag>
      ),
  },
  {
    title: 'Permanently Void',
    key: 'permanently_void',
    dataIndex: 'manufacturer_id',
    render: (item) => {
      return item === -1 ? <Tag color="error">Permanently Void</Tag> : ''
    }
  },
  {
    title: "Mapping",
    dataIndex: "is_active",
    key: "is_active",
    
    render: (text, record) => (
      <>
        <Switch
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
          disabled={get(record, 'manufacturer_id') === -1}
        />
      </>
    ),
  },

];
