import { get, isEmpty } from "lodash";
import * as APIS from "../../../constants/api";

export const fetchPendingBatchItems =
  (id, payload, flag) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${id}/item/list${
      flag ? "?rectification=true" : ""
    }`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchPendingWithVendorPartBatchItems =
  (id, vendor_number, payload, flag) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${id}/errored/vendor/part/${vendor_number}/list`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchPendingWithVendorPartBatchItemsIdsList =
  (id, vendor_number) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${id}/compact/errored/vendor/part/${vendor_number}/item/list`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchPendingWithVendorBatchItemsIdsList =
  (id, vendor_number) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${id}/compact/${vendor_number}/item/list`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchSentBatchItems =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/sent/${id}/item/list`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchArchiveBatchItems =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/archived/${id}/item/list`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchBatchItemDetails =
  (batchId, batchRecordNo) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/${batchId}/item/${batchRecordNo}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const fetchArchivedBatchItemDetails =
  (batchId, batchRecordNo) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/archived/${batchId}/item/${batchRecordNo}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateBatchItemDetails =
  (payload, batchId, batchRecordNumber, itemImage) =>
  (dispatch, getState, api) => {
    let formData = new FormData();

    if (get(itemImage, "name", false) || itemImage === "") {
      formData.append("item_image", itemImage);
    }
    for (var key in payload) {
      formData.append(`${key}`, get(payload, `${key}`, ""));
    }
    const url = `${APIS.BASE_BATCH}/${batchId}/item/${batchRecordNumber}`;
    return api
      .patch(url, formData)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const processPendingBatch =
  (pendingBatchId) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_BATCH}/pending/${pendingBatchId}/process`;
    return api
      .post(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

// fetch vendors list
export const fetchBatchVendorsList = (id) => (dispatch, getState, api) => {
  const url = `${APIS.BASE_BATCH}/${id}/vendor_name/list`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

// void all vendor list
export const voidAll = (id) => (dispatch, getState, api) => {
  const url = `${APIS.BASE_BATCH}/pending/${id}/errored/vendor/void-all`;

  return api
    .post(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

// /batch/pending/1006558/errored/vendor/void-all
