import { Tag } from "antd";
import get from "lodash/get";

export const columns = () => [
  {
    title: "Distributor ID",
    dataIndex: "distributor_id",
    key: "distributor_id",
    
    width: 120,
  },
  {
    title: "Distributor",
    dataIndex: "Distributor",
    key: "Distributor",
    
    render: (text) => get(text, "name", ""),
  },
  {
    title: "Distributor Customer",
    dataIndex: "distributor_customer_name",
    key: "distributor_customer_name",
    
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "descend",
    ellipsis: true,
  },
  {
    title: "Mapping",
    dataIndex: "is_active",
    key: "is_active",
    
    render: (text, record) =>
      text === true ? (
        <Tag className="tag-border-radius" color="#86f7af">
          Active
        </Tag>
      ) : (
        <Tag className="tag-border-radius">Inactive</Tag>
      ),
  },
];
