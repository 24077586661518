export const NAME_VALIDATIONS = [
  {
    required: true,
    message: "Please input Sync Connector Type Name",
  },
  {
    min: 3,
    whitespace: true,
    message: "Please input at least 3 characters",
  },
  {
    max: 20,
    message: "Sync Connector Type Name must be less than 20 characters",
  },
];
