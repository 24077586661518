import {Button, Col, Divider, Form, Image, Modal, Row, Select, Spin} from "antd";
import get from "lodash/get";
import React, {useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import BasicInfoForm from "../components/forms/BasicInfoForm";
import PasswordForm from "../components/forms/PasswordForm";
import { fetchLoggedInUser } from "../redux/actions/accountInfoAction";
import * as IDS from "../constants/data/BasicData";
import { clearFilter } from "../redux/actions/filterActions";
import {FormOutlined} from "@ant-design/icons";
import TwoFactorAuthForm from "../components/forms/TwoFactorAuthForm";

const AccountInfoScreen = () => {

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();

  const getUserInfo = () => {
    setLoading(true);
    dispatch(fetchLoggedInUser())
      .then((result) => {
        setUserDetails(result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(clearFilter());
    getUserInfo();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">My Account </h1>
        </div>
        <div className="sub-wrapper-div">
          <div style={{ marginLeft: 15 }}>
            <h3 style={{ fontSize: 30, marginBottom: 30 }}>
              {get(userDetails, "Role.name")}
            </h3>
            <BasicInfoForm
              getUserInfo={getUserInfo}
              userDetails={userDetails}
            />
            <TwoFactorAuthForm
                getUserInfo={getUserInfo}
                userDetails={userDetails}
            />
            <PasswordForm />

          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AccountInfoScreen;
