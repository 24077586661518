import * as APIS from "../../constants/api";
import { message } from "antd";

export const fetchAllOrganization = (payload) => (dispatch, getState, api) => {
  const url = APIS.FETCH_ALL_ORGANIZATION;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

// fetch active organizations
export const fetchAllActiveOrganization =
  (payload) => (dispatch, getState, api) => {
    const url = APIS.FETCH_ALL_ACTIVE_ORGANISATION;
    return api
      .get(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateOrganization =
  (payload, id) => (dispatch, getState, api) => {
    const url = `${APIS.UPDATE_ORGANIZATION}/${id}`;
    return api
      .patch(url, payload)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };
export const fetchSingleOrganization = (id) => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_SINGLE_ORGANIZATION}/${id}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const createOrganization = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.CREATE_ORGANIZATION}`;
  return api
    .post(url, payload)
    .then((result) => {
      if (result.message) message.success(result.message);
    })
    .catch((error) => {
      throw error;
    });
};

export const checkServiceEndpoint = (payload) => (dispatch, getState, api) => {
  return api
    .post(`${APIS.CHECK_ORGANIZATION_STATUS}`, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
};
