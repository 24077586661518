import {Select, Form, Button, Col, Row, Checkbox} from "antd";
import * as VALIDATIONS from "../../constants/data/validations/batchManagementValidations/DataRectificationValidations";

import React from "react";
import { get, map } from "lodash";
const { Option } = Select;

const FirstCarousel = ({
  pendingBatchId,
  pendingBatchDetails,
  distributorCustomer,
}) => {
  const contentStyle = {
    height: "500px",
    color: "#fff",
    padding: "30px",
  };

  return (
    <div style={contentStyle}>
      <Row justify={"space-between"}>
        <Col >
          <div className="warning-text">
            <h3>
              <b>Missing Organization Assignment </b>
            </h3>
          </div>
          <h3 style={{ marginTop: 30 }}>
            <b>Distributor Customer </b> -{" "}
            {get(pendingBatchDetails, "data.customer_number")}{" "}
          </h3>
        </Col>
        <Col>
          <div>
            <h3>
              <b>Batch</b> - {pendingBatchId}
            </h3>
          </div>
        </Col>
      </Row>
      <Row justify={"space-around"} style={{marginTop:10}}>
        <Col>
          <div className="first-carousel-form-container">
            <div>
              <Form.Item
                label={
                  <h3 style={{ marginTop: 15 }}>
                    <b>Select Organization</b>
                  </h3>
                }
                name="organization_id"
                rules={VALIDATIONS.DISTRIBUTOR_CUSTOMER_VALIDATIONS}
              >
                <Select
                  style={{ width: 350 }}
                  size="large"
                  showSearch={true}
                  optionFilterProp="children"
                  allowClear
                >
                  {map(distributorCustomer, (at) => (
                    <Option key={get(at, "id")} value={get(at, "id")}>
                      {`${get(at, "name")}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="customer_distributor_update_flag"
                valuePropName="checked"
              >
                <Checkbox>Add Organization Distributor Record</Checkbox>
              </Form.Item>
            </div>
            <Row justify={"space-around"} style={{marginTop:10}}>
              <Col>
                <Form.Item>
                  <Button className="save-button" htmlType="submit" size="large">
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FirstCarousel;
