import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  Select,
} from "antd";
import assign from "lodash/assign";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import {
  fetchAllManufacturerList,
  fetchSingleManufacturer,
  updateManufacturer,
} from "../../redux/actions/manufacturerActions";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import map from "lodash/map";
import * as VALIDATIONS from "../../constants/data/validations/ManufacturerValidations";
import { filter } from "lodash";
import { getFormattedWebUrl, checkValidUrl } from "../../utilities/helper";
import SingleImageUplaod from "../common/imageUpload";

const { Option } = Select;
const { TextArea } = Input;

const EditManufacturerForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState({});
  const [manufacturerList, setManufacturerList] = useState([]);
  const [display, setDisplay] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState({
    file: null,
    src: null,
  });

  const idArr = location.search.split("");
  idArr.shift();
  const id = idArr.join("");

  const editManufacturer = (payload, id) => {
    setLoading(true);
    dispatch(updateManufacturer(payload, id, image))
      .then(() => {
        message.success(MESSAGES.EDIT_MANUFACTURER_MESSAGE);
        setLoading(false);
        navigate(ROUTES.MANUFACTURER_MANAGEMENT);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (values.website === null) {
      delete values["website"];
    } else {
      assign(values, {
        website: get(values, "website")
          ? getFormattedWebUrl(get(values, "website"))
          : null,
      });
    }
    if (values.note === null) {
      delete values["note"];
    }
    if (values.parent_manufacturer_id === undefined) {
      values["parent_manufacturer_id"] = null;
    }
    delete values["id"];

    editManufacturer(values, id);
  };

  const getSingleManufacturer = (id) => {
    setLoading(true);
    dispatch(fetchSingleManufacturer(id))
      .then((result) => {
        setInitialDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAllCompactManufacturerList = () => {
    setLoading(true);
    dispatch(fetchAllManufacturerList())
      .then((result) => {
        setLoading(false);
        setManufacturerList(get(result, "data", []));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onselectManufacturer = (value, event) => {
    const filterList = filter(manufacturerList, (mt) => mt.id === value);
    if (get(filterList[0], "is_active", false) === false) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  useEffect(() => {
    getSingleManufacturer(id);
  }, [location]);

  useEffect(() => {
    setImage({
      file: null,
      src: get(initialDetails, "image_url"),
    });
    form.resetFields();
    if (get(initialDetails, "ParentManufacturer.is_active", false) === false) {
      setDisplay(true);
    }
    getAllCompactManufacturerList();
  }, [initialDetails]);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Manufacturer Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(ROUTES.MANUFACTURER_MANAGEMENT)}
            />
          </div>
          <h2 className="sub-title arrow-sub-title-alingment">
            Edit Manufacturer
          </h2>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={initialDetails}
        >
          <Row gutter={16}>
            <Col xl={12} xs={12}>
              <Row gutter={16}>
                <Col xl={12} xs={10}>
                  <Form.Item name="id" label="ID">
                    <Input className="id-input" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="default_code"
                    label="Default Code"
                    rules={VALIDATIONS.CODE_VALIDATIONS}
                  >
                    <Input size="large" className="capitalise" />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="website"
                    label="Website"
                    rules={[
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" style={{ marginTop: 3 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Form.Item
                    name="parent_manufacturer_id"
                    label="Parent Manufacturer"
                  >
                    <Select
                      size="large"
                      showSearch={true}
                      optionFilterProp="children"
                      allowClear
                      onSelect={onselectManufacturer}
                      onClear={() => setDisplay(false)}
                    >
                      {map(manufacturerList, (at) => (
                        <Option key={get(at, "id", 0)} value={get(at, "id", 0)}>
                          {`${get(at, "name", "")}  ${
                            get(at, "is_active", "") ? "(Active)" : "(Inactive)"
                          }`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {display && (
                    <p className="select-validation">
                      {get(initialDetails, "ParentManufacturer.is_active") ===
                      false
                        ? `The selected Parent Manufacturer is inactive`
                        : ""}
                    </p>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Form.Item name="note" label="Note">
                    <TextArea maxLength={1000} rows={3} size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={12} xs={10}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch checked={false} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="manufacturer-form-button-container">
                <Form.Item label="">
                  <Button
                    className="save-manufacturer-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    SAVE
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={() => navigate(ROUTES.MANUFACTURER_MANAGEMENT)}
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
            <Col></Col>
            <Form.Item label="Image">
              <SingleImageUplaod
                setLoading={setLoading}
                setImage={setImage}
                image={image}
                remove={true}
              />
            </Form.Item>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};
export default EditManufacturerForm;
