import { PlusOutlined } from "@ant-design/icons/lib/icons";
import {
  Button,
  Layout,
  Table,
  Modal,
  Spin,
  message,
  Form,
  Input,
  Select,
} from "antd";
import get from "lodash/get";
import map from "lodash/map";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllDistributorTables/distributerManufacturerTable";
import {
  fetchAllDistributorsManufacturers,
  fetchAllManufacturerList,
  fetchSingleDistributorsManufacturers,
  updateManufacturerMappingSwitch,
} from "../../redux/actions/allDistributorsAction/distributorsManufacturerActions";
import AddManufacturerMapping from "../forms/subDistributorForm/AddManufacturerMapping";
import * as MESSAGES from "../../constants/messages";
import * as IDS from "../../constants/data/BasicData";
import { ActiveList, totalCount } from "../../utilities/helper";
import { isEmpty } from "lodash";

const { Option } = Select;

function ManufacturerTableContainer({ id }) {
  const [modal, setModal] = useState(false);
  const [linkId, setlinkId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });
  const [listData, setListData] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [sortDir, setSortDir] = useState("");
  const [sort, setSort] = useState({});
  const [total, setTotal] = useState(0);
  const [isFilter, setIsFilter] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSucessView = () => {
    toggalModal();
    if (!isEmpty(isFilter)) {
      getDistributersManufacturersList(id, {
        ...isFilter,
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    } else {
      getDistributersManufacturersList(id, {
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    }
  };

  const getAllManufacturersList = () => {
    setLoading(true);
    dispatch(fetchAllManufacturerList())
      .then((result) => {
        setManufacturerList(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getDistributersManufacturersList = (id, payload) => {
    setLoading(true);
    dispatch(fetchAllDistributorsManufacturers(id, payload))
      .then((result) => {
        setListData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setSortDir(get(result, "sortDir", ""));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClickEdit = (record) => {
    getSingleDistributorsManufacturers(record);
  };

  const onChangeHandler = (record) => {
    setLoading(true);
    dispatch(updateManufacturerMappingSwitch(record))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.UPDATE_MAPPING_MESSAGE);
        if (!isEmpty(isFilter)) {
          getDistributersManufacturersList(id, {
            ...isFilter,
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        } else {
          getDistributersManufacturersList(id, {
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const getSingleDistributorsManufacturers = (payload) => {
    setLoading(true);
    dispatch(fetchSingleDistributorsManufacturers(payload))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
        setlinkId(get(result, "id", 0));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    for (var key in values) {
      if (
        get(values[key], "length", 0) === 0 &&
        key !== "manufacturer_active" &&
        key !== "manufacturer_id"
      ) {
        delete values[`${key}`];
      }
    }
    values["is_active"] = values["manufacturer_active"];
    delete values["manufacturer_active"];
    const payload = { ...sort, filters: values, offset: 0, limit: 10 };
    setIsFilter({ filters: values });
    getDistributersManufacturersList(id, { ...payload });
    setPaginationPage(1);
  };
  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const manufacturer_id = form.getFieldValue("manufacturer_id");
    const distributor_vendor_id = form.getFieldValue("distributor_vendor_id");
    const distributor_vendor_name = form.getFieldValue(
      "distributor_vendor_name"
    );
    const is_active = form.getFieldValue("manufacturer_active");

    if (
      manufacturer_id !== null ||
      distributor_vendor_id !== null ||
      distributor_vendor_name !== null ||
      is_active !== null
    ) {
      userListParams.filters = {
        manufacturer_id,
        distributor_vendor_id,
        distributor_vendor_name,
        is_active,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }
    setSort(userListParams);
    getDistributersManufacturersList(id, { ...userListParams });
  };

  useEffect(() => {
    getAllManufacturersList();
    form.setFieldsValue({ manufacturer_active: true });
    getDistributersManufacturersList(id, {
      offset: 0,
      limit: 10,
      sortDir: "DESC",
      sortBy: "distributor_vendor_name",
      filters: { is_active: true },
    });
    setSort({ sortDir: "DESC", sortBy: "distributor_vendor_name" });
    setIsFilter({ filters: { is_active: true } });
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Manufacturer</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={toggalModal}
            size="large"
          >
            ADD MAPPING
            <PlusOutlined />
          </Button>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <div>
            <p className="form-label">Manufacturer</p>
            <Form.Item name="manufacturer_id">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
                showSearch={true}
                optionFilterProp="children"
                allowClear
              >
                {map(manufacturerList, (at) => (
                  <Option key={get(at, "id")} value={get(at, "id")}>
                    {get(at, "name")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item name="distributor_vendor_id">
            <div>
              <p className="form-label">Vendor ID</p>
              <Input />
            </div>
          </Form.Item>
          <Form.Item name="distributor_vendor_name">
            <div>
              <p className="form-label">Vendor Name</p>
              <Input />
            </div>
          </Form.Item>

          <div>
            <p className="form-label">Mapping</p>
            <Form.Item name="manufacturer_active">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
              >
                {map(ActiveList, (at) => (
                  <Option key={get(at, "key")} value={get(at, "value")}>
                    {get(at, "name")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                form.setFieldsValue({ manufacturer_active: null });
                setIsFilter({});
                getDistributersManufacturersList(id, {
                  ...sort,
                  offset: 0,
                  limit: 10,
                  filters: {},
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>

      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={listData}
          columns={columns({ onClickEdit, onChangeHandler })}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
      <Modal
        width="42%"
        title={
          <h2>
            {get(editDetails, "visible")
              ? "Edit Manufacturer Mapping"
              : "Add Manufacturer Mapping"}
          </h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddManufacturerMapping
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          initialValues={get(editDetails, "data")}
          onSucessView={onSucessView}
          listData={listData}
          id={id}
          linkId={linkId}
        />
      </Modal>
    </Spin>
  );
}

export default ManufacturerTableContainer;
