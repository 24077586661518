import * as APIS from "../../constants/api";
import * as TYPES from "../../constants/actionTypes";

export const fetchLoggedInUser = () => (dispatch, getState, api) => {
  const url = APIS.FETCH_LOGGEDIN_USER;
  return api
    .get(url)
    .then((result) => {
      dispatch({
        type: TYPES.USER_DETAILS,
        payload: result,
      });
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateLoggedInUser = (payload) => (dispatch, getState, api) => {
  const url = APIS.UPDATE_LOGGEDIN_USER;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const updateNewPassword = (payload) => (dispatch, getState, api) => {
  const url = APIS.UPDATE_NEWPASSWORD;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const loadTwoFactorQr = (payload) => (dispatch, getState, api) => {
  const url = APIS.TWO_FA_QR_GENERATE;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
