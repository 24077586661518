import { persistStore } from "redux-persist";
// import { createStore, applyMiddleware, compose } from "redux";
// import middlewares from "./middlewares";
import reducers from "./persistReducer";

// const configureStore = () => {
//   const reactEnhancers = compose(applyMiddleware(...middlewares));
//   const store = createStore(reducers, reactEnhancers);
//   const persistor = persistStore(store);
//   return { store, persistor };
// };

// export default configureStore();

import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../reducers/authReducer";
import batchFilterReducer from "../reducers/batchFilterReducer";
import batchSorterReducer from "../reducers/batchSorterReducer";
import filterReducer from "../reducers/filterReducer";
import sorterReducer from "../reducers/sorterReducer";
import distributorFilterReducer from "../reducers/distributorFilterReducer";
import manufacturerFilterReducer from "../reducers/manufacturerFilterReducer";
import organizationFilterReducer from "../reducers/organizationFilterReducer";
import userFilterReducer from "../reducers/userFilterReducer";
import globalReducer from "../reducers/globalReducer";
import axiosInstance from "../../utilities/configureAxios";
// import rootReducer from "../reducers/index";

export const Store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: axiosInstance,
      },
      serializableCheck: false,
    }),
});

export const persistor = persistStore(Store);
