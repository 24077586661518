import { get } from "lodash";
import * as TYPES from "../../constants/actionTypes";

const initialState = {
  manufacturerSorter: null,
  distributorSorter: null,
  organizationSorter: null,
};

const sorterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MANUFACTURER_SORTER:
      return {
        ...state,
        manufacturerSorter: get(action, "payload", {}),
      };
    case TYPES.DISTRIBUTOR_SORTER:
      return {
        ...state,
        distributorSorter: get(action, "payload", {}),
      };

    case TYPES.ORGANIZATION_SORTER:
      return {
        ...state,
        organizationSorter: get(action, "payload", {}),
      };

    default:
      return state;
  }
};

export default sorterReducer;
