import { Spin, Form, Row, Col, Input, Button, message, Select } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations/subOrganizationValidation/OrganizationContactValidation";
import map from "lodash/map";
import {
  createOrganizationsContact,
  updateOrganizationsContact,
} from "../../redux/actions/allOrganizationsActions/organizationContactActions";
import { checkValidMobileNumber } from "../../utilities/helper";

const { Option } = Select;
const AddOrganizationContactForm = ({
  toggalModal,
  initialValues,
  edit,
  onSucessView,
  id,
  contactType,
  getContactType,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const Obj = {
    name: "OrganizationContactName",
    email: "OrganizationContactEmail",
  };

  const onFinish = (value) => {
    for (var key in Obj) {
      value[`${key}`] = value[Obj[key]];
      delete value[Obj[key]];
    }
    if (edit) {
      const payload = {
        ...value,
        did: id,
        cid: get(initialValues, "id", 0),
      };
      editOrganizationsContact(payload);
    } else {
      const payload = { ...value, did: id };
      AddOrganizationsContact(payload);
    }
  };

  const AddOrganizationsContact = (payload) => {
    setLoading(true);
    dispatch(createOrganizationsContact(payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.CREATE_CONTACT);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editOrganizationsContact = (payload) => {
    setLoading(true);
    dispatch(updateOrganizationsContact(payload))
      .then(() => {
        message.success(MESSAGES.EDIT_CONTACT);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getContactType();
  }, []);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                OrganizationContactName: get(initialValues, "name", ""),
                OrganizationContactEmail: get(initialValues, "email", ""),
              }
            : {
                is_active: false,
              }
        }
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="OrganizationContactName"
                  label="Name"
                  rules={VALIDATIONS.ORGANIZATION_CONTACT_NAME_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="OrganizationContactEmail"
                  label="Email"
                  rules={VALIDATIONS.ORGANIZATION_CONTACT_EMAIL_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xl={24} xs={24}>
                <Row gutter={16}>
                  <Col xl={12} xs={12}>
                    <Form.Item
                      name="phone"
                      label="Phone Number"
                      rules={[
                        ...VALIDATIONS.ORGANIZATION_CONTACT_PHONE_VALIDATIONS,
                        {
                          validator: checkValidMobileNumber,
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={12}>
                    <Form.Item
                      name="org_contact_type_id"
                      label="Contact Type"
                      rules={VALIDATIONS.ORGANIZATION_CONTACT_TYPE_VALIDATIONS}
                    >
                      <Select
                        size="large"
                        showSearch={true}
                        optionFilterProp="children"
                      >
                        {map(contactType, (rt) => (
                          <Option key={get(rt, "id")} value={get(rt, "id")}>
                            {get(rt, "name")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddOrganizationContactForm;
