export const DISTRIBUTOR_CUSTOMER_ID_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Distributor Customer ID",
  },
  {
    min: 3,
    message: "Distributor Customer ID must contain at least 3 characters",
  },
  {
    max: 60,
    message: "Distributor Customer ID must be less than 60 characters",
  },
];

export const DISTRIBUTOR_CUSTOMER_NAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please Input Distributor Customer Name",
  },
  {
    min: 3,
    message: "Distributor Customer Name must contain at least 3 characters",
  },
  {
    max: 50,
    message: "Distributor Customer Name must be less than 50 characters",
  },
];

export const DISTRIBUTOR_ORGANISATION_VALIDATIONS = [
  {
    required: true,
    message: "Please Select Organization",
  },
];

export const ORGANISATION_NOTE_VALIDATIONS = [
  {
    max: 255,
    message: "Note must be less than 255 characters",
  },
];
