import { FormOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useRef, useState } from "react";
import get from "lodash/get";
import {
  loadTwoFactorQr,
  updateLoggedInUser,
} from "../../redux/actions/accountInfoAction";
import * as MESSAGES from "../../constants/messages";
import { useDispatch } from "react-redux";
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from "../../constants/data/validations";
import editIcon from "../../assets/svg/editIcon.svg";
import * as IDS from "../../constants/data/BasicData";
const { Title } = Typography;
function TwoFactorAuthForm({ userDetails, getUserInfo }) {
  const [editTwoFactorInfo, toggleEditTwoFactorInfo] = useState(false);
  const [totpQr, setTotpQr] = useState(null);
  const [editDataActionLoading, setEditDataActionLoading] = useState(false);
  const twoFactorFormRef = useRef();
  const dispatch = useDispatch();
  const editLoggedInUser = (payload) => {
    if (payload.multi_factor_auth_type === userDetails.multi_factor_auth_type) {
      getUserInfo();
      setEditDataActionLoading(false);
      toggleEditTwoFactorInfo(false);
      setTotpQr(null);
      return;
    }
    setEditDataActionLoading(true);
    dispatch(updateLoggedInUser(payload))
      .then((result) => {
        message.success(MESSAGES.USER_UPDATE);
        getUserInfo();
        setEditDataActionLoading(false);
        toggleEditTwoFactorInfo(false);
        setTotpQr(null);
      })
      .catch((error) => {
        setEditDataActionLoading(false);
        loadQR(payload.multi_factor_auth_type);
      });
  };
  const loadQR = (type) => {
    if (type !== "TOTP") {
      return;
    }
    setEditDataActionLoading(true);
    dispatch(loadTwoFactorQr())
      .then((result) => {
        setTotpQr(result.data);
        setEditDataActionLoading(false);
      })
      .catch((error) => error);
  };
  return (
    <>
      <Form
        layout="vertical"
        key={userDetails.id}
        initialValues={{ ...userDetails }}
        onFinish={editLoggedInUser}
        ref={twoFactorFormRef}
      >
        <div className="account-info-heading">
          <b style={{ marginRight: 10 }}>Two Factor Authentication</b>
          <div onClick={() => toggleEditTwoFactorInfo(!editTwoFactorInfo)}>
            <img src={editIcon} alt="" />
          </div>
        </div>
        <Divider style={{ marginTop: 6 }} />
        <Row>
          <Col span={12}>
            <Form.Item
              label={"Multi Factor Auth"}
              name={"multi_factor_auth_type"}
            >
              <Select
                disabled={!editTwoFactorInfo}
                onChange={(value) => loadQR(value)}
              >
                <Select.Option value={"NONE"}>No 2FA</Select.Option>
                <Select.Option value={"OTP"}>OTP</Select.Option>
                {get(userDetails, "Role.id", 0) ===
                IDS.SUPER_ADMIN_ID ? null : (
                  <Select.Option value={"TOTP"}>TOTP</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {editTwoFactorInfo && (
          <Row>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                className="edit-user-button"
                size="large"
              >
                UPDATE
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
      <Modal
        open={totpQr}
        key={get(totpQr, "totp_dataURL")}
        onCancel={() => {
          setTotpQr(null);
          toggleEditTwoFactorInfo(false);
          twoFactorFormRef.current.setFieldsValue({
            multi_factor_auth_type: userDetails.multi_factor_auth_type,
          });
        }}
        footer={null}
      >
        <Row justify={"space-around"}>
          <Col>
            <Title level={5} align={"center"}>
              Scan the QR Code with Goggle Authenticator or Microsoft
              Authenticator to enable Two Factor Authentication
            </Title>
          </Col>
          <Col>
            <Image src={get(totpQr, "totp_dataURL")} preview={false} />
            <Form
              layout={"vertical"}
              onFinish={(values) =>
                editLoggedInUser({
                  ...values,
                  multi_factor_auth_type: "TOTP",
                })
              }
            >
              <Form.Item
                name={"code"}
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                  {
                    min: 6,
                    max: 6,
                    message: `"Code" must be 6 character long!`,
                  },
                ]}
                label={"Code"}
              >
                <Input disabled={editDataActionLoading} />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="edit-user-button"
                  size="large"
                >
                  UPDATE
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default TwoFactorAuthForm;
const TWO_FA_TYPE_LIST = [
  { label: "No 2FA", value: "NONE" },
  { label: "OTP", value: "OTP" },
  {
    label: "TOTP",
    value: "TOTP",
  },
];
