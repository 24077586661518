import get from "lodash/get";
import * as TYPES from "../../constants/actionTypes";
import { distributerFilter } from "../../constants/filterTypes";

const initialState = { ...distributerFilter };

const distributorFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.DISTRIBUTOR_FILTER:
      return {
        ...state,
        ...get(action, "payload", {}),
      };

    case TYPES.CLEAR_DISTRIBUTOR_FILTER:
      return {
        ...distributerFilter,
      };

    default:
      return state;
  }
};

export default distributorFilterReducer;
