import { Switch, Button, Avatar, Space } from "antd";
import editIcon from "../../assets/svg/editIcon.svg";
import get from "lodash/get";
import isNull from 'lodash/isNull'

export const columns = ({ onClickEdit, onChangeHandler, manufacturerData }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    
    width: 120,
    render: (text, record) => (
        <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 120,
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(manufacturerData, 'sortBy') === "id" ?(get(manufacturerData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter:true,
    sortOrder : get(manufacturerData, 'sortBy') === "name" ?(get(manufacturerData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value, record)=> (
      <Space>
        {!isNull(get(record, 'image_url')) ? <Avatar size="large" src={get(record, 'image_url')} /> : 
        <Avatar style={{ backgroundColor: '#FAA500'}} size="large" > {value.charAt(0)}</Avatar> }
        {value || '-'}
      </Space>
    ),
    ellipsis: true,
  },
  {
    title: "Default Code",
    dataIndex: "default_code",
    key: "default_code",
    sorter:true,
    sortOrder : get(manufacturerData, 'sortBy') === "default_code" ?(get(manufacturerData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
  },

  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
    
    width: 120,
    render: (text, record) => (
      <>
        <Switch
          defaultChecked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },

];
