import { Typography, Upload, message } from "antd";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// import { IMAGE_UPLOAD } from "../constants/apis";
// import { removeMedia } from "../redux/actions/mediaAction";

const { Text } = Typography;

const SingleImageUplaod = (props) => {
  const { setImage,loading, image, remove } = props;
  const [demoKey, setDemoKey] = useState(null);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const getImageUrl = (file) => {
    var reader = new FileReader();
    reader.onload = function (e) {
        setImage({
            file: file,
            src: e.target.result,
        })
    };
    reader.readAsDataURL(file);
   
  };
  console.log(demoKey);
  return (
    <>
      <Upload
        className="single-image-upload"
        listType="picture-card"
        showUploadList={false}
        action={(file) => {
          getImageUrl(file);
        }}
        beforeUpload={beforeUpload}
      >
        {get(image,'src') ? (
          <img width={100} height={100} src={get(image,'src')} alt="" />
        ) : (
          <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>

      {
        remove && get(image, 'src') && (
          <Text
            className="cursor-pointer"
            underline
            onClick={() => {
              setImage({
                file: "",
                src: null,
              })
            }}
          >
            Remove
          </Text>
        )
      }
    </>
  );
};

export default SingleImageUplaod;
