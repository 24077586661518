export const getKey = (pathname) => {
  switch (pathname) {
    case "/batch-management":
      return "1";
    case "/edit-pending-batch":
      return "1";
    case "/edit-batch-form":
      return "1";
    case "/edit-sent-batch":
      return "1";
    case "/sent-batch-form":
      return "1";
    case "/edit-archived-batch":
      return "1";
    case "/archived-batch-form":
      return "1";
    case "/manufacturer-management":
      return "2";
    case "/add-manufacturer":
      return "2";
    case "/edit-manufacturer":
      return "2";
    case "/organization-management":
      return "3";
    case "/add-organization":
      return "3";
    case "/edit-organization":
      return "3";
    case "/users":
      return "4";
    case "/distributor-management":
      return "5";
    case "/add-distributor":
      return "5";
    case "/edit-distributor":
      return "5";
    case "/system-configuration":
      return "6";
    default:
      return "0";
  }
};
