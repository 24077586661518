import { Table, Form, Spin } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as IDS from "../constants/data/BasicData";
import { columns } from "../constants/data/AllBatchesTables/editSentBatchTable";
import { fetchSentBatchItems } from "../redux/actions/allBatchActions/batchEditPendingActions";
import { useNavigate, useLocation } from "react-router-dom";
import arrowLeft from "../assets/svg/arrowLeft.svg";
import * as ROUTES from "../constants/routes";
import EditSentBatchFilterForm from "../components/forms/batchForms/batchManagementFilterForms/EditSentBatchFilterForm";
import { editSentBatchFilterDetails } from "../redux/actions/batchFilterActions";
import { editSentBatchSorterDetails } from "../redux/actions/batchSorterActions";
import moment from "moment";
import { setBatchStatus, totalCount } from "../utilities/helper";

const EditSentBatchScreen = () => {
  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("");
  const [status, setStatus] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const editSentBatchFilter = useSelector((state) =>
    get(state, "batchFilter.editSentBatchFilter", [])
  );

  const idArr = location.search.split("");
  idArr.shift();
  const id = idArr.join("");

  const onClickEdit = (record) => {
    const batchId = get(record, "batch_id", "");
    const batchRecordNo = get(record, "batch_record_no", 0);
    navigate({
      pathname: ROUTES.EDIT_SENT_BATCH_ITEM,
      search: `${batchId}+${batchRecordNo}`,
    });
  };

  const getSentBatchItems = (id, payload) => {
    setLoading(true);
    dispatch(fetchSentBatchItems(id, payload))
      .then((result) => {
        setStatus(get(result, "Batch.status", ""));
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setList(get(result, "data", []));
        setTotal(get(result, "total", 0));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let pendingBatchListParams = { offset, limit };

    const batch_record_no = form.getFieldValue("batch_record_no");
    const vendor_number = form.getFieldValue("vendor_number");
    const create_date = form.getFieldValue("create_date");
    const effective_date = form.getFieldValue("effective_date");

    if (
      batch_record_no !== null ||
      vendor_number !== null ||
      create_date !== null ||
      effective_date !== null
    ) {
      pendingBatchListParams.filters = {
        batch_record_no,
        vendor_number,
        create_date: create_date
          ? moment(create_date).format("YYYY-MM-DD")
          : undefined,
        effective_date: effective_date
          ? moment(effective_date).format("YYYY-MM-DD")
          : undefined,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      pendingBatchListParams.sortDir = get(sortDirection, "sortDir", "");
      pendingBatchListParams.sortBy = get(sorter, "field", "");
    }

    dispatch(editSentBatchSorterDetails(pendingBatchListParams));

    getSentBatchItems(id, pendingBatchListParams);
  };

  useEffect(() => {
    dispatch(
      editSentBatchSorterDetails({
        sortBy: "batch_record_no",
        sortDir: "DESC",
      })
    );
    if (editSentBatchFilter) {
      form.setFieldsValue(editSentBatchFilter);
      dispatch(
        editSentBatchFilterDetails({
          filters: { ...editSentBatchFilter },
        })
      );
      getSentBatchItems(id, {
        offset: 0,
        limit: 10,
        sortBy: "batch_record_no",
        sortDir: "DESC",
        filters: editSentBatchFilter,
      });
    } else {
      dispatch(editSentBatchFilterDetails({ filters: {} }));
      getSentBatchItems(id, {
        offset: 0,
        limit: 10,
        sortBy: "batch_record_no",
        sortDir: "DESC",
        filters: {},
      });
    }
  }, []);
  return (
    <div className="main-wrapper-div">
      <div>
        <h1 className="title">Batch Management </h1>
      </div>
      <div className="form-sub-heading">
        <div className="arrow-img">
          <img
            src={arrowLeft}
            alt=""
            onClick={() => navigate(ROUTES.BATCH_MANAGEMENT)}
          />
        </div>
        <h2
          className="sub-title arrow-sub-title-alingment"
          style={{ marginBottom: 30 }}
        >
          {`Batch - ${id}`}
        </h2>
        <div className="edit-batch-tag">{setBatchStatus(status)}</div>
      </div>
      <EditSentBatchFilterForm
        form={form}
        id={id}
        getSentBatchItems={getSentBatchItems}
        setPaginationPage={setPaginationPage}
      />

      <div className="user-table" style={{ marginTop: -16 }}>
        <Table
          showSorterTooltip={false}
          loading={loading}
          size="small"
          rowKey="Id"
          dataSource={list}
          columns={columns({ onClickEdit, currencyCode })}
          onChange={handleChange}
          scroll={{ x: 1800, y: 400 }}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [5, 8, 10],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
    </div>
  );
};

export default EditSentBatchScreen;
