import get from "lodash/get";
import * as TYPES from "../../constants/actionTypes";
import * as APIS from "../../constants/api";
import { VERIFY_TOTP } from "../../constants/api";

export const login = (credentials) => (dispatch, getState, api) =>
  api
    .post(APIS.LOGIN, credentials)
    .then((result) => {
      if (get(result, "data.multi_factor_auth_type") === "NONE") {
        api.defaults.headers.common.authorization = `Bearer ${get(
          result,
          "data.token"
        )}`;
        dispatch({
          type: TYPES.LOGIN,
          payload: result,
        });
      }
      return result;
    })
    .catch((err) => {
      throw err;
    });

export const loginWithOtp = (credentials) => (dispatch, getState, api) =>
  api
    .post(APIS.VERIFY_OTP, credentials)
    .then((result) => {
      api.defaults.headers.common.authorization = `Bearer ${get(
        result,
        "data.token"
      )}`;
      dispatch({
        type: TYPES.LOGIN,
        payload: result,
      });
      return result;
    })
    .catch((err) => {
      throw err;
    });

export const loginWithTotp = (credentials) => (dispatch, getState, api) =>
  api
    .post(APIS.VERIFY_TOTP, credentials)
    .then((result) => {
      api.defaults.headers.common.authorization = `Bearer ${get(
        result,
        "data.token"
      )}`;
      dispatch({
        type: TYPES.LOGIN,
        payload: result,
      });
      return result;
    })
    .catch((err) => {
      throw err;
    });

export const logout = () => (dispatch, getState, api) => {
  api
    .post(APIS.LOGOUT)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const emailSubmit = (email) => (dispatch, getState, api) =>
  api
    .post(APIS.FORGOT_PASSWORD_EMAIL_SUBMIT, email)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });

export const newPasswordSubmit = (payload) => (dispatch, getState, api) =>
  api
    .post(APIS.RESET_PASSWORD, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });

export const loggedInUserDetails = () => (dispatch, getState, api) => {
  api
    .get(APIS.LOGGEDIN_USER)
    .then((result) => {
      dispatch({
        type: TYPES.USER_DETAILS,
        payload: result,
      });
    })
    .catch((error) => {
      throw error;
    });
};
