import { Button, Switch } from "antd";
import moment from "moment";
import editIcon from "../../assets/svg/editIcon.svg";
import { formatedDate } from "../../utilities/helper";
import get from "lodash/get";

export const columns = ({ onClickEdit, onChangeHandler, organizationData }) => [
  {
    title: "Actions",
    dataIndex: "",
    key: 2,
    
    width: 120,
    render: (text, record) => (
        <Button type="text" onClick={() => onClickEdit(record)}     size={"small"}>
          <img src={editIcon} alt="" />
        </Button>
    ),
  },
  {
    title: "BLS Customer ID",
    dataIndex: "id",
    key: "id",
    sorter:true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder : get(organizationData, 'sortBy') === "id" ?(get(organizationData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
    width: 160,
  },
  {
    title: "Organization Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    sorter:true,
    sortOrder : get(organizationData, 'sortBy') === "name" ?(get(organizationData, 'sortDir')==="DESC" ? "descend":"ascend") : false,
    render:(value)=> value || '-',
  },
  {
    title: "Instance Endpoint",
    dataIndex: "portal_backend_url",
    key: "portal_backend_url",
    ellipsis: true,
    render: (text) => (
      <span
        onClick={() => window.open(text)}
        style={{ color: "orange", cursor: "pointer" }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Expires At",
    dataIndex: "expires_at",
    key: "expire_at",
    
    width: 140,
    render: (date) => formatedDate(date),
  },
  {
    title: "Active",
    dataIndex: "is_active",
    key: "is_active",
    
    width: 120,
    render: (text, record) => (
      <>
        <Switch
          checked={text}
          onChange={() => onChangeHandler(record)}
          size="small"
        />
      </>
    ),
  },

];
