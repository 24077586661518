export const DISTRIBUTOR_CUSTOMER_VALIDATIONS = [
  {
    required: true,
    message: "Please select an Organization",
  },
];
export const DISTRIBUTOR_VENDOR_VALIDATIONS = [
  {
    required: true,
    message: "Please select a Manufacturer",
  },
];
