import { PlusOutlined } from "@ant-design/icons/lib/icons";
import {
  Button,
  Layout,
  Table,
  Modal,
  Spin,
  Form,
  Input,
  Select,
  message,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllOrganizationTables/organizationSyncConnectorTables";
import * as IDS from "../../constants/data/BasicData";
import * as MESSAGES from "../../constants/messages";
import { isEmpty, map } from "lodash";
import {
  fetchAllOrganizationsSyncConnectors,
  fetchSingleOrganizationsSyncConnectors,
  updateOrganizationSyncConnectorMapping,
  fetchAllOrganizationParentSyncConnector,
} from "../../redux/actions/allOrganizationsActions/organizationSyncConnectorActions";
import AddOrganizationSyncConnectorForm from "../forms/AddOrganizationSyncConnectorForm";
import { fetchAllSyncConnectors } from "../../redux/actions/syncConnectorAction";
import { totalCount } from "../../utilities/helper";

const { Option } = Select;

function OrganizationSyncConnectorTableContainer({ ids }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parentSyncConnectorList, setParentSyncConnectorList] = useState(null);
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });
  const [listData, setListData] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sort, setSort] = useState({});
  const [total, setTotal] = useState(0);
  const [syncData, setSyncData] = useState([]);
  const [isFilter, setIsFilter] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onSucessView = () => {
    toggalModal();
    if (!isEmpty(isFilter)) {
      getOrganizationSyncConnectorList(ids, {
        ...isFilter,
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    } else {
      getOrganizationSyncConnectorList(ids, {
        offset: sort.offset,
        limit: sort.limit,
        sortBy: sort.sortBy,
        sortDir: sort.sortDir,
      });
    }
  };

  const getSyncConnectorList = () => {
    setLoading(true);
    dispatch(fetchAllSyncConnectors())
      .then((result) => {
        setSyncData(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getOrganizationSyncConnectorList = (id, payload) => {
    setLoading(true);
    dispatch(fetchAllOrganizationsSyncConnectors(id, payload))
      .then((result) => {
        setListData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getParentSyncConnectorList = () => {
    setLoading(true);
    dispatch(fetchAllOrganizationParentSyncConnector(ids))
      .then((result) => {
        setLoading(false);
        setParentSyncConnectorList(get(result, "data"));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickEdit = (record) => {
    getSingleOrganizationsSyncConnector(ids, record);
  };

  const onChangeHandler = (record) => {
    setLoading(true);
    dispatch(updateOrganizationSyncConnectorMapping(ids, record))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.UPDATE_MAPPING_MESSAGE);
        if (!isEmpty(isFilter)) {
          getOrganizationSyncConnectorList(ids, {
            ...isFilter,
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        } else {
          getOrganizationSyncConnectorList(ids, {
            offset: sort.offset,
            limit: sort.limit,
            sortBy: sort.sortBy,
            sortDir: sort.sortDir,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const getSingleOrganizationsSyncConnector = (ids, payload) => {
    setLoading(true);
    dispatch(fetchSingleOrganizationsSyncConnectors(ids, payload))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    for (var key in values) {
      if (
        get(values[key], "length", 0) === 0 &&
        key !== "sync_connector_type_id"
      ) {
        delete values[`${key}`];
      }
    }
    values["name"] = values["syncConnectorName"];
    delete values["syncConnectorName"];

    const payload = { ...sort, filters: values, offset: 0, limit: 10 };
    setIsFilter({ filters: values });
    getOrganizationSyncConnectorList(ids, { ...payload });
    setPaginationPage(1);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const name = form.getFieldValue("syncConnectorName");
    const sync_connector_type_id = form.getFieldValue("sync_connector_type_id");

    if (name !== null) {
      userListParams.filters = {
        name,
        sync_connector_type_id,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }
    setSort(userListParams);
    getOrganizationSyncConnectorList(ids, { ...userListParams });
  };

  useEffect(() => {
    getSyncConnectorList();
    getParentSyncConnectorList();
    getOrganizationSyncConnectorList(ids, {
      offset: 0,
      limit: 10,
      sortBy: "id",
      sortDir: "DESC",
    });
    setSort({ sortDir: "DESC", sortBy: "id" });
  }, []);
  return (
    <Spin spinning={loading}>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Sync Connector</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={toggalModal}
            size="large"
          >
            ADD SYNC CONNECTOR
            <PlusOutlined />
          </Button>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="syncConnectorName">
            <div>
              <p className="form-label">Name</p>
              <Input />
            </div>
          </Form.Item>
          <div>
            <p className="form-label">Sync Connector Type</p>
            <Form.Item name="sync_connector_type_id">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
              >
                {map(syncData, (at) => (
                  <Option key={get(at, "id", 0)} value={get(at, "id", 0)}>
                    {get(at, "name", "")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                setIsFilter({});
                getOrganizationSyncConnectorList(ids, {
                  ...sort,
                  filters: {},
                  offset: 0,
                  limit: 10,
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>
      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={listData}
          columns={columns({ onClickEdit, onChangeHandler })}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
      <Modal
        width="60%"
        title={
          <h2>
            {get(editDetails, "visible")
              ? "Edit Sync Connector"
              : "Add Sync Connector"}
          </h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddOrganizationSyncConnectorForm
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          initialValues={get(editDetails, "data")}
          onSucessView={onSucessView}
          id={ids}
          getSyncConnectorList={getSyncConnectorList}
          syncData={syncData}
          getParentSyncConnectorList={getParentSyncConnectorList}
          parentSyncConnectorList={parentSyncConnectorList}
        />
      </Modal>
    </Spin>
  );
}

export default OrganizationSyncConnectorTableContainer;
