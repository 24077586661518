import { Button, Form, Input, message, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newPasswordSubmit } from "../../redux/actions/authActions";
import BlackLeafMasterLogo from "../../assets/svg/BlackLeafMasterLogo.svg";
import get from "lodash/get";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations";
import * as ROUTES from "../../constants/routes";
import BeforeLoginLogo from "../../assets/images/BeforeLoginLogo.png";
import { useState } from "react";

const NewPasswordScreen = ({ resetPassword }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { id } = useParams();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const AuthToken = params.get("token");

  const onClickResetPassword = (payload) => {
    setLoading(true);
    dispatch(newPasswordSubmit(payload))
      .then((result) => {
        setLoading(false);
        message.success(MESSAGES.RESET_PASSWORD_MESSAGE);
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    navigate("/users");
  }

  const onFinish = (values) => {
    if (get(values, "newPassword") === get(values, "confirmNewPassword")) {
      const finalValue = {
        token: AuthToken,
        id: parseInt(id),
        new_password: get(values, "newPassword"),
      };
      onClickResetPassword(finalValue);
    } else {
      message.error(MESSAGES.PASSWORD_NOT_MATCH);
    }
  };
  //

  return (
    <Spin spinning={loading}>
      <div className="forgot-password-form-wrapper">
        <div className="forgot-password-form-container">
          <div className="logo-container">
            <img className="img-new-password" src={BeforeLoginLogo} alt="" />
          </div>
          <div className="heading-container">
            <h2 className="login-heading">
              {resetPassword ? "Reset Password ?" : "Forgot Password?"}
            </h2>
          </div>
          <Form
            name="normal login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={VALIDATIONS.PASSWORD_VALIDATION}
            >
              <Input
                className="newPassword-input"
                size="large"
                type="password"
              />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              label="Confirm New Password"
              rules={VALIDATIONS.PASSWORD_VALIDATION}
            >
              <Input
                className="newPassword-input"
                size="large"
                type="password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="continue-button"
                size="large"
                htmlType="submit"
              >
                RESET PASSWORD
              </Button>
            </Form.Item>
          </Form>
          <p className="cancel-link" onClick={() => navigate(ROUTES.LOGIN)}>
            Cancel
          </p>
        </div>
      </div>
    </Spin>
  );
};

export default NewPasswordScreen;
