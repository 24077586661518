import { get } from "lodash";
import * as TYPES from "../../constants/actionTypes";

const initialState = {
  filter: null,
  distributorFilter: null,
  organizationFilter: null,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.FILTER:
      return {
        ...state,
        filter: get(action, "payload.filters", {}),
      };

    case TYPES.ORGANIZATION_FILTER:
      return {
        ...state,
        organizationFilter: get(action, "payload.filters", {}),
      };
    case TYPES.CANCEL_FILTER:
      return {
        ...state,
        filter: null,
        distributorFilter: null,
        organizationFilter: null,
      };

    default:
      return state;
  }
};

export default filterReducer;
