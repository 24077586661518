import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Button,
  message,
  Select,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  roleTypeEnum,
  updateUser,
} from "../../redux/actions/manageUserActions";
import map from "lodash/map";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations";
import * as IDS from "../../constants/data/BasicData";
import filter from "lodash/filter";

const { Option } = Select;

const AddNewUserForm = ({ toggalModal, initialValues, edit, onSucessView }) => {
  const [loading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [form] = Form.useForm();
  const roleId = useSelector((state) => get(state, "auth.role_id"));

  const dispatch = useDispatch();

  const Obj = {
    is_active: "userActive",
    email: "userEmail",
    first_name: "firstName",
    last_name: "lastName",
    role_id: "roleId",
  };

  const onFinish = (value) => {
    for (var key in Obj) {
      value[`${key}`] = value[Obj[key]];
      delete value[Obj[key]];
    }

    if (edit) {
      if (isTouched) {
        if (get(value, "password").length > 0) {
          editUser({ ...value, id: get(initialValues, "id"), multi_factor_auth_type : value.multi_factor_auth_type === false ? "NONE": undefined });
        } else {
          delete value["password"];
          editUser({ ...value, id: get(initialValues, "id"), multi_factor_auth_type : value.multi_factor_auth_type === false ? "NONE": undefined });
        }
      } else {
        delete value["password"];
        editUser({ ...value, id: get(initialValues, "id"), multi_factor_auth_type : value.multi_factor_auth_type === false ? "NONE": undefined  });
      }
    } else {
      addUser(value);
    }
  };

  const filterRole = (result) => {
    const roleData = get(result, "data", []);
    if (roleId === IDS.ADMIN_ID) {
      const filteredRole = filter(
        roleData,
        (rd) => get(rd, "id", 0) === IDS.STANDERED_USER_ID
      );
      setRoleList(filteredRole);
    } else {
      setRoleList(get(result, "data", []));
    }
  };

  const getRoleType = () => {
    setLoading(false);
    dispatch(roleTypeEnum())
      .then((result) => {
        setLoading(false);
        filterRole(result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addUser = (payload) => {
    setLoading(true);
    dispatch(createUser(payload))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.ADD_NEW_USER_MESSAGE);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editUser = (payload) => {
    setLoading(true);
    dispatch(updateUser(payload, get(payload, "id")))
      .then(() => {
        message.success(MESSAGES.UPDATE_ROLE_MESSAGE);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoleType();
  }, []);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                multi_factor_auth_type: get(initialValues, 'multi_factor_auth_type') !== "NONE",
                firstName: get(initialValues, "first_name", ""),
                lastName: get(initialValues, "last_name", ""),
                roleId: get(initialValues, "role_id", 0),
                userEmail: get(initialValues, "email", ""),
              }
            : {
                require_reset_password: false,
                userActive: false,
              }
        }
      >
        {edit && (
          <Row gutter={16}>
            <Col xl={24} xs={24}>
              <Form.Item
                name="id"
                label="User ID"
                rules={[{ required: true, message: "Please Input UserId" }]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={VALIDATIONS.FIRSTNAME_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={VALIDATIONS.LASTNAME_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="userEmail"
                  label="Email"
                  rules={VALIDATIONS.EMAIL_VALIDATIONS}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={
                    !edit
                      ? VALIDATIONS.PASSWORD_VALIDATION
                      : VALIDATIONS.PASSWORD_VALIDATIONS_WITHOUT_REQUIRED
                  }
                >
                  <Input.Password
                    onChange={() => setIsTouched(true)}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="roleId"
                  label="User Type"
                  rules={[
                    { required: true, message: "Please Select User Type" },
                  ]}
                >
                  <Select size="large">
                    {map(roleList, (rt) => (
                      <Option key={get(rt, "id")} value={get(rt, "id")}>
                        {get(rt, "name")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} xs={12}>
                <Form.Item
                  name="require_reset_password"
                  label="Require Reset Password"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      edit
                        ? get(initialValues, "require_reset_password")
                        : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={12} xs={12}>
                <Form.Item name="userActive" label="Active">
                  <Switch
                    defaultChecked={
                      edit ? get(initialValues, "is_active") : false
                    }
                  />
                </Form.Item>
              </Col>
              {edit ? <Col span={12}>
                <Form.Item label='Two Factor Authentication' name='multi_factor_auth_type' valuePropName='checked'>
                  <Switch className="ant-switch-green" disabled={get(initialValues, 'multi_factor_auth_type') === "NONE" }/>
                </Form.Item>
              </Col> :null}
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD USER"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddNewUserForm;
