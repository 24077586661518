import get from "lodash/get";
import * as APIS from "../../constants/api";
import { getFormDataPayload } from "../../utilities/helper";

export const fetchAllSyncConnectors =
  (payload) => (dispatch, getState, api) => {
    const url = APIS.FETCH_ALL_SYNC_CONNECTORS;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const connectorsListTypeEnum = () => (dispatch, getState, api) => {
  const url = APIS.CONNECTOR_LIST_TYPE;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchAboutContent = () => (dispatch, getState, api) => {
  const url = APIS.ABOUT;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const createSyncConnector =
  (payload, image) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = APIS.CREATE_SYNC_CONNECTORS;
    return api
      .post(url, formData)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateSyncConnector =
  (payload, id, image) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = `${APIS.UPDATE_SYNC_CONNECTORS}/${id}`;
    return api
      .patch(url, formData)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateActiveSwitch = (payload) => (dispatch, getState, api) => {
  const url = `${APIS.UPDATE_SYNC_CONNECTOR_ACTIVE_SWITCH}/${get(
    payload,
    "id"
  )}`;
  return api
    .patch(url, { is_active: !get(payload, "is_active") })
    .then(() => {})
    .catch((error) => {
      throw error;
    });
};

export const fetchReleaseNotes = (payload) => (dispatch, getState, api) => {
  const url = APIS.FETCH_RELEASE_NOTES_LIST;

  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchSingleReleaseNote =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.FETCH_SINGLE_RELEASE_NOTE}/${get(payload, "id")}`;

    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const createReleaseNote = (payload) => (dispatch, getState, api) => {
  const url = APIS.CREATE_RELEASE_NOTE;

  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const updateReleaseNote =
  (finalPayload) => (dispatch, getState, api) => {
    const { payload, id } = finalPayload;
    const url = `${APIS.UPDATE_RELEASE_NOTE}/${id}`;

    return api
      .put(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const deleteReleseNote = (id) => (dispatch, getState, api) => {
  const url = `${APIS.UPDATE_RELEASE_NOTE}/${id}`;

  return api
    .delete(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
