export const ORGANIZATION_SYNC_CONNECTOR_NAME_VALIDATIONS = [
  {
    required: true,
    whitespace: true,
    message: "Please input Sync Connector Name",
  },
  {
    min: 3,
    message: "Sync Connector Name must contain at least 3 characters",
  },
  {
    max: 200,
    message: "Sync Connector Name must be less than 200 characters",
  },
];
export const ORGANIZATION_SYNC_CONNECTOR_TYPE_ID_VALIDATION = [
  {
    required: true,
    message: "Please input Sync Connector Type ID",
  },
];
export const ORGANIZATION_SYNC_CONNECTOR_DESCRIPTION_VALIDATIONS = [
  {
    max: 50,
    message: "Description must be less than 50 characters",
  },
];
