import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
  Spin,
  message,
  Typography,
  Tabs,
  Divider,
} from "antd";
import assign from "lodash/assign";
import map from "lodash/map";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations/DistributorValidations";
import {
  fetchSingleDistributor,
  updateDistributor,
} from "../../redux/actions/distributorActions";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import ManufacturerTableContainer from "../distributorTablesContainer/ManufacturerTableContainer";
import OrganizationTableContainer from "../distributorTablesContainer/OrganizationTableContainer";
import ContactTableContainer from "../distributorTablesContainer/ContactTableContainer";
import {
  getFormattedWebUrl,
  getFormattedFtpUrl,
  checkValidUrl,
  checkValidMobileNumber,
} from "../../utilities/helper";
import SingleImageUplaod from "../common/imageUpload";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const EditDistributorForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState({
    file: null,
    src: null,
  });

  const idArr = location.search.split("");
  idArr.shift();
  const id = idArr.join("");

  const editDistributor = (payload, id) => {
    setLoading(true);
    dispatch(updateDistributor(payload, id, image))
      .then(() => {
        message.success(MESSAGES.EDIT_DISTRIBUTOR_MESSAGE);
        setLoading(false);
        navigate(ROUTES.DISTRIBUTOR_MANAGEMENT);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getSingleDistributor = (id) => {
    setLoading(true);
    dispatch(fetchSingleDistributor(id))
      .then((result) => {
        setInitialDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    for (const property in value) {
      if (value[property] === null) {
        delete value[property];
      }
    }
    delete value["id"];
    assign(value, {
      url: get(value, "url") ? getFormattedWebUrl(get(value, "url")) : null,
      ftp_url: get(value, "ftp_url")
        ? getFormattedFtpUrl(get(value, "ftp_url"))
        : null,
    });

    let payload = {};
    map(value, (v, k) => {
      if (k !== "is_active" && (isNull(v) || isUndefined(v) || isEmpty(v)))
        payload[`${k}`] = "";
      else payload[`${k}`] = v;
    });

    editDistributor(payload, id);
  };

  useEffect(() => {
    getSingleDistributor(id);
  }, [location]);

  useEffect(() => {
    setImage({
      file: null,
      src: get(initialDetails, "image_url"),
    });
    form.resetFields();
  }, [initialDetails]);

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Distributor Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => navigate(ROUTES.DISTRIBUTOR_MANAGEMENT)}
            />
          </div>
          <h2 className="sub-title arrow-sub-title-alingment">
            Edit Distributor
          </h2>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={initialDetails}
        >
          <Row gutter={16}>
            <Col xl={20} xs={20}>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item name="id" label="ID">
                    <Input className="id-input" size="large" disabled />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="name"
                    label="Distributor Name"
                    rules={VALIDATIONS.NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="code"
                    label="Code"
                    rules={VALIDATIONS.CODE_VALIDATIONS}
                  >
                    <Input
                      size="large"
                      className="capitalise distributorInput non-required"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="ftp_url"
                    label="FTP URL"
                    rules={[
                      ...VALIDATIONS.FTP_URL_VALIDATIONS,
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>

                <Col xl={8} xs={8}>
                  <Form.Item
                    name="url"
                    label="URL"
                    rules={[
                      ...VALIDATIONS.URL_VALIDATIONS,
                      {
                        validator: checkValidUrl,
                      },
                    ]}
                  >
                    <Input size="large" className="non-required" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="is_active"
                    label="Active"
                    valuePropName="checked"
                  >
                    <Switch checked={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col>
                  <Form.Item label="Image">
                    <SingleImageUplaod
                      setLoading={setLoading}
                      setImage={setImage}
                      image={image}
                      remove={true}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={12}>
                  <Form.Item
                    name="comment"
                    label="Comment"
                    rules={VALIDATIONS.DISTRIBUTOR_COMMENT_VALIDATIONS}
                  >
                    <TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
              <div className="distributor-form-subheading">
                <Text type="secondary">Distributor Contact</Text>
              </div>
              <Row gutter={16}>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_name"
                    label="Contact Name"
                    rules={VALIDATIONS.CONTACT_NAME_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_phone"
                    label="Phone"
                    rules={[
                      ...VALIDATIONS.CONTACT_PHONE_VALIDATIONS,
                      {
                        validator: checkValidMobileNumber,
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xl={8} xs={8}>
                  <Form.Item
                    name="primary_contact_email"
                    label="Email"
                    rules={VALIDATIONS.CONTACT_EMAIL_VALIDATIONS}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="manufacturer-form-button-container form-positioning">
                <Form.Item label="">
                  <Button
                    className="save-manufacturer-button"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    SAVE
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button
                    className="cancel-btn"
                    type="primary"
                    size="large"
                    onClick={() => navigate(ROUTES.DISTRIBUTOR_MANAGEMENT)}
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <Divider style={{ marginTop: -10 }} />
      <div className="card-container distributer-tabs">
        <Tabs
          type="card"
          items={[
            {
              key: "1",
              label: <h1>Manufacturer</h1>,
              children: <ManufacturerTableContainer id={id} />,
            },
            {
              key: "3",
              label: <h1>Organization</h1>,
              children: <OrganizationTableContainer id={id} />,
            },
            {
              key: "4",
              label: <h1>Contact</h1>,
              children: <ContactTableContainer ids={id} />,
            },
          ]}
        ></Tabs>
      </div>
    </Spin>
  );
};
export default EditDistributorForm;
