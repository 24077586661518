import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewUserForm from "../components/forms/AddNewUserForm";
import { columns } from "../constants/data/userTable";
import {
  fetchAllUsers,
  fetchSingleUser,
  updateActiveSwitch,
} from "../redux/actions/manageUserActions";
import { hasAnyPermission } from "../utilities/helper.js";
import * as MESSAGES from "../constants/messages.js";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import UserFilterForm from "../components/forms/UserFilterForm";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../redux/actions/globalReducActions";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { TableDropdown } from "../components/common/TableDropDown.js";

const UserScreen = () => {
  const [loading, setLoading] = useState(false);
  const roleId = useSelector((state) => get(state, "auth.role_id"));
  const roleCode = useSelector((state) => get(state, "auth.role_code"));
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [form] = Form.useForm();
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });

  const dispatch = useDispatch();

  const authorities = useSelector((state) =>
    get(state, "auth.authorities", [])
  );
  const tablePaginationParams = useSelector(
    (state) => get(state, "global.globalPagesPagination")[PAGES.USERS]
  );
  const activeFilters = useSelector(
    (state) => get(state, "global.globalPagesFilterData")[PAGES.USERS]
  );
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(PAGES.USERS, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.USERS, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(globalPagesFilterData(PAGES.USERS, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.USERS, param));
    }
  };
  useEffect(() => {
    getUserList();
  }, [tablePaginationParams]);
  const getSingleUser = (id) => {
    setLoading(true);
    dispatch(fetchSingleUser(id))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClickEdit = (record) => {
    getSingleUser(get(record, "id", 0));
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const onSucessView = () => {
    toggalModal();
    getUserList();
  };

  const getUserList = () => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchAllUsers(params))
      .then((result) => {
        setUserData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onChangeHandler = (record) => {
    setLoading(true);
    dispatch(updateActiveSwitch(record))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.ACTIVE_UPDATE_MESSAGE);
        getUserList();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserList();
  }, []);
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };
  return (
    <div className="main-wrapper-div">
      <div>
        <h1 className="title">User Management </h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {hasAnyPermission(authorities, [
          "PERM_CREATE_ADMIN_USER",
          "PERM_CREATE_STANDARD_USER",
        ]) && (
          <Button className="add-btn" onClick={toggalModal} size="large">
            ADD NEW USER
            <PlusOutlined />
          </Button>
        )}
      </div>
      <UserFilterForm
        form={form}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />
      <div className="user-table">
        <Table
          pagination={false}
          showSorterTooltip={false}
          rowKey="id"
          size="small"
          scroll={{ y: "calc(100vh - 430px)" }}
          dataSource={get(userData, "data")}
          columns={columns({
            onClickEdit,
            onChangeHandler,
            userData,
            loggedInUser: {
              Role: {
                id: roleId,
                role_code: roleCode,
              },
            },
          })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          loading={loading}
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(userData, "offset", 0) + 1} to ${
                    get(userData, "data", []).length +
                    get(userData, "offset", "--")
                  } of ${get(userData, "total", "--")} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(userData, "offset", 0) -
                          get(userData, "limit", 0),
                      })
                    }
                    disabled={!get(userData, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(userData, "offset", 0) +
                          get(userData, "limit", 0),
                      })
                    }
                    disabled={
                      get(userData, "offset", 0) + get(userData, "limit", 0) >=
                      get(userData, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
        />
      </div>
      <Modal
        width="60%"
        title={
          <h2>{get(editDetails, "visible") ? "Edit User" : "Add New User"}</h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddNewUserForm
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          initialValues={get(editDetails, "data")}
          onSucessView={onSucessView}
        />
      </Modal>
    </div>
  );
};

export default UserScreen;
