import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Button,
  message,
  Select,
  Switch,
  Table,
} from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as MESSAGES from "../../constants/messages";
import * as VALIDATIONS from "../../constants/data/validations/subOrganizationValidation/OrganizationSyncConnectorValidations";
import { columns } from "../../constants/data/AllOrganizationTables/organizationSyncCoonectorAddFormTables";
import map from "lodash/map";
import {
  createOrganizationsSyncConnector,
  fetchOrganizationsSyncConnectorsServers,
  updateOrganizationsSyncConnector,
} from "../../redux/actions/allOrganizationsActions/organizationSyncConnectorActions";
import { filter } from "lodash";

const { Option } = Select;
const { TextArea } = Input;

const AddOrganizationSyncConnectorForm = ({
  toggalModal,
  initialValues,
  edit,
  onSucessView,
  id,
  getSyncConnectorList,
  syncData,
  getParentSyncConnectorList,
  parentSyncConnectorList,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [alreadySelected, setAlreadySelected] = useState([]);
  const [showCPNcanChange, setShowCPNcanChange] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const Obj = {
    is_active: "SyncConnectorIsActive",
    name: "SyncConnectorName",
    sync_connector_type_id: "SyncConnectorTypeId",
    parent_sync_connector_id: "ParentSyncConnectorId",
    cpn_can_change: "cpnCanChange",
  };

  const onFinish = (value) => {
    for (var key in Obj) {
      value[`${key}`] = value[Obj[key]];
      delete value[Obj[key]];
    }
    if (edit) {
      const payload = {
        ...value,
        did: id,
        cid: get(initialValues, "id", 0),
        active_server_ids: alreadySelected,
      };

      editOrganizationsSyncConnector(payload);
    } else {
      const payload = { ...value, did: id };
      AddOrganizationsSyncConnectors(payload);
    }
  };

  const AddOrganizationsSyncConnectors = (payload) => {
    setLoading(true);
    dispatch(createOrganizationsSyncConnector(payload))
      .then((result) => {
        setLoading(false);
        message.success(result.message);
        onSucessView();
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };

  const editOrganizationsSyncConnector = (payload) => {
    setLoading(true);
    dispatch(updateOrganizationsSyncConnector(payload))
      .then(() => {
        message.success(MESSAGES.EDIT_SYNC_CONNECTOR);
        setLoading(false);
        onSucessView();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getOrganizationsSyncConnectorServerList = (orgId, syncId) => {
    setLoading(true);
    dispatch(fetchOrganizationsSyncConnectorsServers(orgId, syncId))
      .then((result) => {
        const oldData = get(result, "data", []);
        const newData = filter(oldData, (el) => el.is_active === true);
        const selectedKeys = map(newData, (el) => el.id);
        setAlreadySelected(selectedKeys);
        setData(oldData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSyncConnectorList();
    getParentSyncConnectorList();
    getOrganizationsSyncConnectorServerList(id, get(initialValues, "id", 0));
    if (initialValues && initialValues.SyncConnectorType) {
      setShowCPNcanChange(initialValues.SyncConnectorType.cpn_can_change);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={
          edit
            ? {
                ...initialValues,
                SyncConnectorTypeId: get(
                  initialValues,
                  "sync_connector_type_id",
                  ""
                ),
                SyncConnectorName: get(initialValues, "name", ""),
                SyncConnectorIsActive: get(initialValues, "is_active", false),
                description: get(initialValues, "description", ""),
                ParentSyncConnectorId: get(
                  initialValues,
                  "parent_sync_connector_id",
                  0
                ),
                cpnCanChange: get(initialValues, "cpn_can_change", false),
              }
            : { SyncConnectorIsActive: false, cpnCanChange: false }
        }
      >
        <Row gutter={16}>
          <Col xl={24} xs={24}>
            <Row gutter={16}>
              {edit && (
                <Col xl={8} xs={8}>
                  <Form.Item name="id" label="ID">
                    <Input className="id-input" size="large" disabled />
                  </Form.Item>
                </Col>
              )}
              <Col xl={8} xs={8}>
                <Form.Item
                  name="SyncConnectorName"
                  label="Name"
                  rules={
                    VALIDATIONS.ORGANIZATION_SYNC_CONNECTOR_NAME_VALIDATIONS
                  }
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xl={8} xs={8}>
                <Form.Item
                  name="SyncConnectorTypeId"
                  label="Sync Connector Type"
                  rules={
                    VALIDATIONS.ORGANIZATION_SYNC_CONNECTOR_TYPE_ID_VALIDATION
                  }
                >
                  <Select
                    size="large"
                    showSearch={true}
                    optionFilterProp="children"
                    disabled={edit ? true : false}
                    onChange={(val, opt) => {
                      setShowCPNcanChange(opt.record.cpn_can_change);
                    }}
                  >
                    {map(syncData, (at) => (
                      <Option
                        key={get(at, "id", 0)}
                        value={get(at, "id", 0)}
                        record={at}
                      >
                        {get(at, "name", "")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={8} xs={8}>
                <Form.Item
                  label="Parent Sync Connector"
                  name="ParentSyncConnectorId"
                  rules={[
                    {
                      required: true,
                      message: "Please Select a Prent Sync Connector",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {map(parentSyncConnectorList, (parentSync) => (
                      <Option
                        key={get(parentSync, "id", 0)}
                        value={get(parentSync, "id", 0)}
                      >
                        {get(parentSync, "name", "")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={8} xs={8}>
                <Form.Item
                  name="SyncConnectorIsActive"
                  label="Active"
                  valuePropName="checked"
                >
                  <Switch checked={false} />
                </Form.Item>
              </Col>
              <Col xl={8} xs={8}>
                {showCPNcanChange && (
                  <Form.Item
                    name="cpnCanChange"
                    label="CPN Editable"
                    valuePropName="checked"
                  >
                    <Switch defaultChecked={false} />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={16} xs={16}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={
                    VALIDATIONS.ORGANIZATION_SYNC_CONNECTOR_DESCRIPTION_VALIDATIONS
                  }
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>

            {edit && (
              <div>
                <h3 style={{ fontWeight: 550, marginBottom: 20 }}>
                  Sync Connector Server Mappings
                </h3>
                <Table
                  showSorterTooltip={false}
                  size="small"
                  rowKey="id"
                  dataSource={data}
                  columns={columns()}
                  pagination={false}
                  scroll={{ y: 240 }}
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: alreadySelected,
                    onChange: (keys, selectedRows) => {
                      setAlreadySelected(keys);
                    },
                  }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: 30,
              }}
            >
              <Form.Item label="">
                <Button
                  htmlType="submit"
                  type="primary"
                  className={edit ? "edit-user-button" : "add-user-button"}
                  size="large"
                >
                  {edit ? "UPDATE" : "ADD"}
                </Button>
              </Form.Item>

              <Form.Item label="">
                <Button
                  className="cancel-btn"
                  onClick={toggalModal}
                  size="large"
                  type="primary"
                  style={{
                    height: 50,
                    width: 150,
                    marginLeft: 10,
                  }}
                >
                  CANCEL
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddOrganizationSyncConnectorForm;
