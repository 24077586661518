const PASSWORD_REGEX =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[]?[;,=!@#$%^&*()_-]).{12,}$";

export const NAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please Input Name" },
  {
    min: 3,
    message: "Organization Name must contain at least 3 characters",
  },
  {
    max: 200,
    message: "Organization Name must be less than 200 characters",
  },
];

export const ID_VALIDATIONS = [
  { required: true, message: "Please input a valid BLS Customer ID"}
];

export const PORTAL_BACKEND_URL_VALIDATIONS = [
  {
    type: "url",
    whitespace: true,
    message: "Please input a valid Url",
  },
  {
    max: 100,
    message: "Url must be less than 100 characters",
  },
];

export const EXPIRES_DATE_VALIDATIONS = [
  {
    required: true,
    message: "Please select Date",
  },
];

export const PRIMARY_USER_FIRST_NAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please Input First Name" },
  {
    min: 3,
    message: "First Name must contain at least 3 characters",
  },
  {
    max: 200,
    message: "First Name must be less than 200 characters",
  },
];

export const PRIMARY_USER_LAST_NAME_VALIDATIONS = [
  { required: true, whitespace: true, message: "Please Input Last Name" },
  {
    min: 3,
    message: "Last Name must contain at least 3 characters",
  },
  {
    max: 200,
    message: "Last Name must be less than 200 characters",
  },
];

export const PRIMARY_USER_EMAIL_VALIDATIONS = [
  {
    type: "email",
    required: true,
    message: "Please input a valid Email",
  },
  {
    min: 3,
    message: "Email must contain at least 3 characters",
  },
  {
    max: 200,
    message: "Email must be less than 200 characters",
  },
];

export const PASSWORD_VALIDATION = [
  { required: true, message: "Please input Password" },
  {
    min: 12,
    message: "Password does not meet minimum length of 12",
  },
  {
    pattern: new RegExp(PASSWORD_REGEX),
    message:
      "Password must contain at least 4 alphabets, 1 uppercase, 1 lowercase, 1 number, and 1 special character",
    validateTrigger: "onSubmit",
  },
];

export const PASSWORD_VALIDATION_WITHOUT_REQUIRED = [
  {
    min: 12,
    message: "Password does not meet minimum length of 12",
  },
  {
    pattern: new RegExp(PASSWORD_REGEX),
    message:
      "Password must contain at least 4 alphabets, 1 uppercase, 1 lowercase, 1 number, and 1 special character",
    validateTrigger: "onSubmit",
  },
];
