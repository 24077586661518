import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Layout, Table, Modal, message, Form, Select } from "antd";

import get from "lodash/get";
import map from "lodash/map";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { columns } from "../../constants/data/AllDistributorTables/distributorOrganizationTables";
import * as MESSAGES from "../../constants/messages";
import {
  fetchAllDistributorsOrganizations,
  updateOrganizationMappingSwitch,
} from "../../redux/actions/allDistributorsAction/distributorsOrganizationActions";
import AddOrganizationMappingForm from "../forms/subDistributorForm/AddOrganizationMappingForm";
import { ActiveList, totalCount } from "../../utilities/helper";
import * as IDS from "../../constants/data/BasicData";
import { isEmpty } from "lodash";
import { fetchSingleDistributorsOrganization } from "../../redux/actions/allDistributorsAction/distributorsOrganisationActions";

const { Option } = Select;

function OrganizationTableContainer({ id }) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sortDir, setSortDir] = useState("");
  const [total, setTotal] = useState(0);
  const [isFilter, setIsFilter] = useState({});
  const [editDetails, setEditDetails] = useState({
    visible: false,
    data: null,
    key: null,
  });
  const [organisationData, setOrganizationData] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSucessView = () => {
    toggalModal();
    if (!isEmpty(isFilter)) {
      getDistributersOrganizationsList({ ...isFilter, id });
    } else {
      getDistributersOrganizationsList({ id });
    }
  };

  const toggalModal = () => {
    if (modal) {
      setEditDetails({
        visible: false,
        data: null,
        key: null,
      });
    }
    setModal(!modal);
  };

  const onClickEdit = (record) => {
    getSingleDistributorsOrganization(record);
  };

  const getSingleDistributorsOrganization = (payload) => {
    setLoading(true);
    dispatch(fetchSingleDistributorsOrganization(payload))
      .then((result) => {
        setLoading(false);
        setModal(true);
        setEditDetails({
          visible: true,
          data: get(result, "data", []),
          key: null,
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getDistributersOrganizationsList = (payload) => {
    setLoading(true);
    dispatch(fetchAllDistributorsOrganizations(payload))
      .then((result) => {
        setOrganizationData(get(result, "data"));
        setTotal(get(result, "total", 0));
        setSortDir(get(result, "sortDir", ""));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onChangeHandler = (record) => {
    setLoading(true);
    dispatch(updateOrganizationMappingSwitch(record))
      .then(() => {
        setLoading(false);
        message.success(MESSAGES.UPDATE_MAPPING_MESSAGE);
        if (!isEmpty(isFilter)) {
          getDistributersOrganizationsList({ ...isFilter, id });
        } else {
          getDistributersOrganizationsList({ id });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    for (var key in values) {
      if (
        get(values[key], "length", 0) === 0 &&
        key !== "organisation_active"
      ) {
        delete values[`${key}`];
      }
    }
    values["is_active"] = values["organisation_active"];
    delete values["organisation_active"];
    const payload = { filters: values, offset: 0, limit: 10 };
    setIsFilter({ filters: values });
    getDistributersOrganizationsList({ ...payload, id });
    setPaginationPage(1);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      get(pagination, "current", "") * get(pagination, "pageSize", "") -
      get(pagination, "pageSize", "");
    const limit = get(pagination, "pageSize", "");

    let userListParams = { offset, limit };

    const is_active = form.getFieldValue("organisation_active");

    if (is_active !== null) {
      userListParams.filters = {
        is_active,
      };
    }

    if (sorter.hasOwnProperty("column") && sorter.order) {
      const sortDirection = {
        sortDir: sorter.order === "ascend" ? IDS.ASCENDING : IDS.DECENDING,
      };
      userListParams.sortDir = get(sortDirection, "sortDir", "");
      userListParams.sortBy = sorter.field;
    }

    getDistributersOrganizationsList({ ...userListParams, id });
  };

  useEffect(() => {
    form.setFieldsValue({ organisation_active: true });
    getDistributersOrganizationsList({
      id,
      offset: 0,
      limit: 10,
      filters: { is_active: true },
    });
    setIsFilter({ filters: { is_active: true } });
  }, []);
  return (
    <div>
      <Layout className="user-filter-form-wrapper" style={{ marginTop: -16 }}>
        <div>
          <h3 className="sub-title">Organization</h3>
        </div>
        <div className="add-connector-button-container">
          <Button
            className="add-connector-btn"
            onClick={toggalModal}
            size="large"
          >
            ADD MAPPING
            <PlusOutlined />
          </Button>
        </div>
      </Layout>
      <Layout className="filter-form">
        <Form form={form} layout="inline" onFinish={onFinish}>
          <div>
            <p className="form-label">Mapping</p>
            <Form.Item name="organisation_active">
              <Select
                className="filter-select"
                size="medium"
                style={{ width: 180 }}
              >
                {map(ActiveList, (at) => (
                  <Option key={get(at, "key")} value={get(at, "value")}>
                    {get(at, "name")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="cancel-btn"
              onClick={() => {
                form.resetFields();
                form.setFieldsValue({ organisation_active: null });
                setIsFilter({});
                getDistributersOrganizationsList({
                  id,
                  offset: 0,
                  limit: 10,
                  filters: {},
                });
                setPaginationPage(1);
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Layout>

      <div className="user-table">
        <Table
          size="small"
          rowKey="id"
          loading={loading}
          dataSource={organisationData}
          columns={columns({ onChangeHandler, onClickEdit })}
          onChange={handleChange}
          pagination={{
            current: paginationPage,
            total: total,
            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
            showSizeChanger: true,
            onChange: (pageNum) => setPaginationPage(pageNum),
          }}
        />
        {totalCount(total)}
      </div>
      <Modal
        width="42%"
        title={
          <h2>
            {get(editDetails, "visible")
              ? "Edit Organization Mapping"
              : "Add Organization Mapping"}
          </h2>
        }
        open={modal}
        onCancel={toggalModal}
        destroyOnClose
        footer={<></>}
      >
        <AddOrganizationMappingForm
          id={id}
          toggalModal={toggalModal}
          edit={get(editDetails, "visible")}
          onSucessView={onSucessView}
          initialValues={get(editDetails, "data")}
        />
      </Modal>
    </div>
  );
}

export default OrganizationTableContainer;
