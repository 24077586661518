import get from "lodash/get";
import * as APIS from "../../../constants/api";

export const fetchAllOrganizationsSyncConnectors =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${id}${APIS.FETCH_ALL_ORGANIZATIONS_SYNC_CONNECTORS}`;
    return api
      .post(url, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchOrganizationsSyncConnectorsServers =
  (orgId, syncId) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${orgId}/sync-connector/${syncId}/server/list`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchSingleOrganizationsSyncConnectors =
  (ids, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${ids}/sync-connector/${get(
      payload,
      "id",
      0
    )}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
export const createOrganizationsSyncConnector =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${get(payload, "did", 0)}${
      APIS.CREATE_ORGANIZATION_SYNC_CONNECTOR
    }`;
    return api.post(url, {
      name: get(payload, "name", ""),
      sync_connector_type_id: get(payload, "sync_connector_type_id", ""),
      is_active: get(payload, "is_active", false),
      description: get(payload, "description", ""),
      parent_sync_connector_id: get(payload, "parent_sync_connector_id"),
      cpn_can_change: get(payload, "cpn_can_change"),
    });
  };

export const updateOrganizationsSyncConnector =
  (payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${get(
      payload,
      "did",
      0
    )}/sync-connector/${get(payload, "cid", 0)}`;
    return api
      .patch(url, {
        name: get(payload, "name", ""),
        is_active: get(payload, "is_active", false),
        active_server_ids: get(payload, "active_server_ids", []),
        description: get(payload, "description", ""),
        parent_sync_connector_id: get(payload, "parent_sync_connector_id"),
        cpn_can_change: get(payload, "cpn_can_change"),
      })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const updateOrganizationSyncConnectorMapping =
  (id, payload) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${id}/sync-connector/${get(
      payload,
      "id",
      0
    )}`;
    return api
      .patch(url, { is_active: !get(payload, "is_active") })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const fetchAllOrganizationParentSyncConnector =
  (id) => (dispatch, getState, api) => {
    const url = `${APIS.BASE_ORGANIZATION}/${id}${APIS.FETCH_ALL_ORGANIZATION_PARENT_SYNC_CONNECTORS}`;
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
