import { Spin, Row, Col, Form, Input, Button, message, Typography } from "antd";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../../assets/svg/arrowLeft.svg";
import * as ROUTES from "../../../constants/routes";
import {
  fetchBatchItemDetails,
  updateBatchItemDetails,
} from "../../../redux/actions/allBatchActions/batchEditPendingActions";
import * as VALIDATIONS from "../../../constants/data/validations/batchManagementValidations/EditBatchFormValidations";
import * as MESSAGES from "../../../constants/messages";
import blankImageLogo from "../../../assets/images/blankImageLogo.jpg";
import { DeleteOutlined } from "@ant-design/icons/lib/icons";

const { Text } = Typography;

const EditBatchItemForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState([]);
  const [image, setImage] = useState({});
  const [disableInput, setDisableInput] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const idArr = location.search.split("");
  idArr.shift();
  const newArray = idArr.join("").split("+");
  const batchId = newArray[0];
  const batchRecordNumber = newArray[1];

  const getBatchItemDetails = (batchId, batchRecordNo) => {
    setLoading(true);
    dispatch(fetchBatchItemDetails(batchId, batchRecordNo))
      .then((result) => {
        setCurrencyCode(get(result, "Batch.currency_code", ""));
        setInitialDetails(get(result, "data", []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const editBatchItemDetails = (payload, batchId, batchRecordNumber, image) => {
    setLoading(true);
    dispatch(updateBatchItemDetails(payload, batchId, batchRecordNumber, image))
      .then((result) => {
        setLoading(false);
        message.success(MESSAGES.UPDATE_PENDING_BATCH_MESSAGES);
        navigate(`${ROUTES.EDIT_PENDING_BATCHES}?${batchId}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onChange = (e) => {
    setImageError(false);
    let file = e.target.files[0];
    if (file.name) {
      setDisableInput(true);
    }
    const fileArray = file.name.split(".");
    const extention = fileArray[1].toLowerCase();

    if (extention === "png" || extention === "jpg" || extention === "jpeg") {
      setImage(file);
    } else {
      setImage(file);
      setImageError(true);
    }
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  useEffect(() => {
    getBatchItemDetails(batchId, batchRecordNumber);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialDetails]);

  const onFinish = (value) => {
    for (let k in value) {
      if (
        k === "batch_record_no" ||
        k === "vendor_number" ||
        k === "vendor_name" ||
        k === "vendor_part_num" ||
        k === "Manufacturer"
      ) {
        delete value[k];
      }
    }
    if (value["item_image_url"].length === 0) {
      delete value["item_image_url"];
    }
    for (var key in value) {
      if (value[key] === null) {
        value[key] = "";
      }
    }

    const payload = { ...value };
    editBatchItemDetails(payload, batchId, batchRecordNumber, image);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-wrapper-div">
        <div>
          <h1 className="title">Batch Management </h1>
        </div>
        <div className="form-sub-heading">
          <div className="arrow-img">
            <img
              src={arrowLeft}
              alt=""
              onClick={() =>
                navigate(`${ROUTES.EDIT_PENDING_BATCHES}?${batchId}`)
              }
            />
          </div>
          <h2
            className="sub-title arrow-sub-title-alingment"
            style={{ marginBottom: 30 }}
          >
            Edit Batch Item
          </h2>
        </div>

        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onFinish}
          initialValues={{
            ...initialDetails,
            price: `${get(initialDetails, "price", "")}`,
            Manufacturer: get(initialDetails, "Manufacturer.name", ""),
            item_image_url: "",
          }}
        >
          {}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginLeft: 20 }}>
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <div>
                    <img
                      height={200}
                      width={200}
                      src={
                        get(initialDetails, "item_image_url", "")
                          ? get(initialDetails, "item_image_url", "")
                          : blankImageLogo
                      }
                      alt=""
                    />
                  </div>
                  <Row gutter={16}>
                    <Col xl={24} xs={24}>
                      <label
                        for="file-upload"
                        className={
                          disableClick ? "disbaled-label" : "custom-file-upload"
                        }
                      >
                        <h3 className={disableClick ? "header" : ""}>
                          Upload Image
                        </h3>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="file-upload"
                          name="item_image"
                          onChange={onChange}
                          onClick={onInputClick}
                          accept="image/*"
                          disabled={disableClick}
                        />
                      </label>
                      {get(initialDetails, "item_image_url", "") && (
                        <Text
                          className="cursor-pointer"
                          underline
                          style={{ marginLeft: 14 }}
                          onClick={() => {
                            setInitialDetails({
                              ...initialDetails,
                              item_image_url: null,
                            });
                            setImage("");
                          }}
                        >
                          Remove
                        </Text>
                      )}
                    </Col>
                  </Row>
                  {image.name && (
                    <div className="image-details">
                      <p>{image.name}</p>{" "}
                      <DeleteOutlined
                        className="deleteOutlined"
                        onClick={() => {
                          setImage({});
                          setDisableClick(false);
                          setDisableInput(false);
                          setImageError(false);
                        }}
                      />
                    </div>
                  )}
                  <div style={{ maxWidth: 300 }}>
                    {imageError ? (
                      <p className="warning-text">
                        Please upload the image with extention JPEG, JPG or PNG
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <h3>or</h3>
                  <Row gutter={16}>
                    <Col xl={24} xs={12}>
                      <Form.Item
                        name="item_image_url"
                        rules={VALIDATIONS.BATCH_ITEM_IMAGE_URL_VALIDATIONS}
                      >
                        <Input
                          size="large"
                          placeholder="Add public url for item image"
                          disabled={disableInput}
                          onChange={(e) => {
                            const x = e.target.value;
                            if (x.length > 0) {
                              setDisableClick(true);
                            } else {
                              setDisableClick(false);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div
                    style={{ marginTop: 450 }}
                    className="manufacturer-form-button-container form-positioning"
                  >
                    <Form.Item label="">
                      <Button
                        className="save-manufacturer-button"
                        htmlType="submit"
                        type="primary"
                        size="large"
                        disabled={imageError}
                      >
                        SAVE
                      </Button>
                    </Form.Item>
                    <Form.Item label="">
                      <Button
                        className="cancel-btn"
                        type="primary"
                        size="large"
                        onClick={() =>
                          navigate(`${ROUTES.EDIT_PENDING_BATCHES}?${batchId}`)
                        }
                      >
                        CANCEL
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="batch-item-input">
              <Row gutter={16}>
                <Col xl={24} xs={24}>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="batch_record_no"
                        label="Batch Record No."
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="vendor_number" label="Vendor Number">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="vendor_name" label="Vendor Name">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="vendor_part_num"
                        label="Vendor Part Number"
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item name="description" label="Description">
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item name="Manufacturer" label="Manufacturer">
                        <Input size="large" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="price"
                        label={`Price (in ${currencyCode})`}
                        rules={VALIDATIONS.BATCH_ITEM_PRICE_VALIDATIONS}
                      >
                        <Input size="large" className={"right-aligned"} />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="color"
                        label="Color"
                        rules={VALIDATIONS.BATCH_COLOR_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="upc_code"
                        label="UPC"
                        rules={VALIDATIONS.BATCH_ITEM_UPC_CODE_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="uom"
                        label="UOM"
                        rules={VALIDATIONS.BATCH_ITEM_UOM_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="category_name"
                        label="Category Name"
                        rules={VALIDATIONS.BATCH_ITEM_CATEGORY_NAME_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="category_code"
                        label="Category Code"
                        rules={VALIDATIONS.BATCH_ITEM_CATAGORY_CODE_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="subcategory_name"
                        label="Sub Category Name"
                        rules={
                          VALIDATIONS.BATCH_ITEM_SUBCATEGORY_NAME_VALIDATIONS
                        }
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="subcategory_code"
                        label="Sub Category Code"
                        rules={
                          VALIDATIONS.BATCH_ITEM_SUBCATEGORY_CODE_VALIDATIONS
                        }
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xl={12} xs={12}>
                      <Form.Item
                        name="cutsheet_url"
                        label="Cutsheet URL"
                        rules={VALIDATIONS.BATCH_CUTSHEET_URL_VALIDATIONS}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default EditBatchItemForm;
