import { EyeOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import { addDollar, formatedDate } from '../../../utilities/helper';

export const columns = ({ onClickEdit, currencyCode }) => [
  {
    title: 'Actions',
    dataIndex: '',
    key: 2,
    
    width: 70,
    render: (text, record) => (
        <Button
            type="text"
            onClick={() => {
              onClickEdit(record);
            }}
        >
          <EyeOutlined style={{ fontSize: 20, color: '#b1b1b1' }} />
        </Button>
    )
  },
  {
    title: 'Batch Record No.',
    dataIndex: 'batch_record_no',
    key: 'batch_record_no',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'descend',
    width: 120
  },
  {
    title: 'Item No.',
    dataIndex: 'item_num',
    key: 'item_num',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Vendor No.',
    dataIndex: 'vendor_number',
    key: 'vendor_number',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 120
  },
  {
    title: 'Vendor Name',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 160
  },
  {
    title: 'Vendor Part No.',
    dataIndex: 'vendor_part_num',
    key: 'vendor_number',
    ellipsis: true,
    width: 180
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    width: 280
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (text) => addDollar(text, currencyCode),
    width: 90
  },
  {
    title: 'Category',
    dataIndex: 'category_name',
    key: 'category_name',
    render: (text) => (text ? text : '-'),
    width: 200
  },
  {
    title: 'Sub Category',
    dataIndex: 'subcategory_name',
    key: 'subcategory_name',
    render: (text) => (text ? text : '-'),
    width: 200
  },
  {
    title: 'Publish Date',
    dataIndex: 'create_date',
    key: 'create_date',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (date) => formatedDate(date),
    width: 120
  },
  {
    title: 'Effective Date',
    dataIndex: 'effective_date',
    key: 'effective_date',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (date) => formatedDate(date),
    width: 120
  },

];
