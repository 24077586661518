import * as APIS from "../../constants/api";
import { getFormDataPayload } from "../../utilities/helper";

export const fetchAllManufacturer = (payload) => (dispatch, getState, api) => {
  const url = APIS.FETCH_ALL_MANUFACTURER;
  return api
    .post(url, payload)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchSingleManufacturer = (id) => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_SINGLE_MANUFACTURER}/${id}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const createManufacturer =
  (payload, image) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = APIS.CREATE_MANUFACTURER;
    return api
      .post(url, formData)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateManufacturer =
  (payload, id, image) => (dispatch, getState, api) => {
    const formData = getFormDataPayload(image, payload);
    const url = `${APIS.UPDATE_MANUFACTURER}/${id}`;
    return api
      .patch(url, formData)
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  };

export const fetchAllManufacturerList = () => (dispatch, getState, api) => {
  const url = `${APIS.FETCH_ALL_MANUFACTURER_LIST}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};

export const fetchActiveManufacturerList = () => (dispatch, getState, api) => {
  const url = `${APIS.ACTIVE_MANUFACTURER_LIST}`;
  return api
    .get(url)
    .then((result) => result)
    .catch((error) => {
      throw error;
    });
};
