import LoginForm from "../components/forms/LoginForm";

const LoginScreen = ({ setResetPassword }) => {
  return (
    <>
      <div className="login-form-wrapper">
        <LoginForm setResetPassword={setResetPassword} />
      </div>
    </>
  );
};
export default LoginScreen;
