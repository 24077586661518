import {
  Button,
  Col,
  Form,
  message,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { columns } from "../constants/data/distributerTable";
import * as MESSAGES from "../constants/messages";
import * as ROUTES from "../constants/routes";
import DistributorFilterForm from "../components/forms/DistributorFilterForm";
import {
  fetchAllDistributors,
  updateDistributor,
} from "../redux/actions/distributorActions";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from "../constants/pages";
import {
  globalPagesFilterData,
  globalPagesPagination,
} from "../redux/actions/globalReducActions";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { TableDropdown } from "../components/common/TableDropDown";

function DistributorScreen() {
  const [loading, setLoading] = useState(false);
  const [distributorData, setDistributorData] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const tablePaginationParams = useSelector(
    (state) => get(state, "global.globalPagesPagination")[PAGES.DISTRIBUTOR]
  );
  const activeFilters = useSelector(
    (state) => get(state, "global.globalPagesFilterData")[PAGES.DISTRIBUTOR]
  );
  const setTablePaginationParams = (param) => {
    if (param && typeof param == "function") {
      dispatch(
        globalPagesPagination(PAGES.DISTRIBUTOR, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.DISTRIBUTOR, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == "function") {
      dispatch(globalPagesFilterData(PAGES.DISTRIBUTOR, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.DISTRIBUTOR, param));
    }
  };
  useEffect(() => {
    getDistributorList();
  }, [tablePaginationParams]);
  const onClickEdit = (record) => {
    const id = get(record, "id", 0);
    navigate({
      pathname: ROUTES.EDIT_DISTRIBUTOR,
      search: `${id}`,
    });
  };

  const onChangeHandler = (record) => {
    const payload = { is_active: !get(record, "is_active", false) };
    toggleActive(payload, get(record, "id", 0));
  };

  const getDistributorList = () => {
    setLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    dispatch(fetchAllDistributors(params))
      .then((result) => {
        setDistributorData(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === "ascend" ? "ASC" : "DESC") : undefined,
      };
    });
  };

  useEffect(() => {
    getDistributorList();
  }, []);

  const toggleActive = (payload, id) => {
    setLoading(true);
    dispatch(updateDistributor(payload, id))
      .then(() => {
        message.success(MESSAGES.ACTIVE_UPDATE_MESSAGE);
        setLoading(false);
        getDistributorList();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div className="main-wrapper-div drawer">
      <div>
        <h1 className="title">Distributor Management </h1>
      </div>
      <div className="add-button-container">
        <Button
          className="main-button"
          size="large"
          onClick={() => {
            navigate(ROUTES.ADD_DISTRIBUTOR);
          }}
        >
          ADD NEW DISTRIBUTOR
          <PlusOutlined />
        </Button>
      </div>
      <DistributorFilterForm
        form={form}
        setActiveFilters={setActiveFilters}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
      />
      <div className="user-table">
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="id"
          dataSource={get(distributorData, "data")}
          columns={columns({ onChangeHandler, onClickEdit, distributorData })}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          loading={loading}
          footer={() => (
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <TableDropdown
                    itemsArr={DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT}
                    itemOnClick={setTablePaginationParams}
                    tablePaginationParams={tablePaginationParams}
                  />
                  <Typography>{`${get(distributorData, "offset", 0) + 1} to ${
                    get(distributorData, "data", []).length +
                    get(distributorData, "offset", "--")
                  } of ${get(
                    distributorData,
                    "total",
                    "--"
                  )} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type="ghost"
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(distributorData, "offset", 0) -
                          get(distributorData, "limit", 0),
                      })
                    }
                    disabled={!get(distributorData, "offset", 0)}
                  />
                  <Button
                    type="ghost"
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          get(distributorData, "offset", 0) +
                          get(distributorData, "limit", 0),
                      })
                    }
                    disabled={
                      get(distributorData, "offset", 0) +
                        get(distributorData, "limit", 0) >=
                      get(distributorData, "total", 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          scroll={{ y: "calc(100vh - 430px)" }}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default DistributorScreen;
