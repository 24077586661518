import {Button, DatePicker, Form, Input, InputNumber, Layout, Select, Space} from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import {batchStatus} from '../../../../utilities/helper';
import {getBatchFilterPayload} from '../../../../utilities/batchManagement';
import moment from "moment";

const { Option } = Select;

const ArchivedBatchFilterForm = ({
  form,
  distributorList,
                                   setActiveFilters,tablePaginationParams,activeFilters,setTablePaginationParams
}) => {

  return (
    <Layout className="filter-form" style={{ marginTop: -16 }}>
      <Form
          form={form}
          layout="inline"
          onFinish= {() => setTablePaginationParams({
            ...tablePaginationParams,
            offset: 0,
            filters: { ...activeFilters },
          })}
      >
        <Space>
          <div>
            <p className="form-label">Batch ID</p>
            <InputNumber value={activeFilters.id}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, id: e ? e : undefined};
            })}/>
          </div>
          <div>
            <p className="form-label">Distributor</p>
            <Select
                className="filter-select"
                size="medium"
                showSearch={true}
                optionFilterProp="children"
                allowClear
                value={activeFilters.distributor_id}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, distributor_id: e ? e : undefined};
            })}
            >
              {map(distributorList, (at) => (
                  <Option key={get(at, 'id', 0)} value={get(at, 'id', 0)}>
                    {get(at, 'name', '')}
                  </Option>
              ))}
            </Select>
          </div>

          <div className="datepicker">
            <p className="form-label">Publish Date</p>
            <DatePicker placeholder={false} size="medium" value={activeFilters.create_date ? moment(activeFilters.create_date,'YYYY-MM-DD') : null}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, create_date: e ? moment(e).format('YYYY-MM-DD') : undefined};
            })}/>
          </div>
          <div className="datepicker">
            <p className="form-label">Effective Date</p>
            <DatePicker size="medium" placeholder={false} value={activeFilters.effective_date ? moment(activeFilters.effective_date,'YYYY-MM-DD') : null}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, effective_date: e ? moment(e).format('YYYY-MM-DD') : undefined};
            })}/>
          </div>
          <div>
            <p className="form-label">Batch Status</p>
            <Select
                className="filter-select"
                size="medium"
                showSearch={true}
                optionFilterProp="children"
                allowClear
                value={activeFilters.status}  onChange={(e) => setActiveFilters(prev => {
              return { ...prev, status: e ? e : undefined};
            })}
            >
              {map(batchStatus, (at) => (
                  <Option key={get(at, 'status')} value={get(at, 'code')}>
                    {get(at, 'status')}
                  </Option>
              ))}
            </Select>
          </div>

          <Form.Item>
            <Button htmlType="submit" className="add-btn">
              Apply
            </Button>
          </Form.Item>
          <Form.Item>
            <Button className="cancel-btn" onClick={() => {
              setActiveFilters({});
              setTablePaginationParams({ ...tablePaginationParams, filters: {} });
            }}>
              Clear
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Layout>
  );
};

export default ArchivedBatchFilterForm;
